import {
  Box,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon3Dots, IconDel } from 'src/assets/icons';
import { AppText } from 'src/components';
import { useDisconnect } from 'src/hooks/useDisconnect';

const MenuSetting = () => {
  const { t } = useTranslation();
  const { disconnect } = useDisconnect();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const initialFocusRef = useRef<HTMLDivElement>(null);

  useOutsideClick({
    ref: initialFocusRef,
    handler(e) {
      onClose();
    },
  });

  return (
    <Popover
      placement="bottom-end"
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      gutter={0}
    >
      <Box ref={initialFocusRef}>
        <PopoverTrigger>
          <Box
            sx={{
              cursor: 'pointer',
              userSelect: 'none',
              height: ['fit-content'],
              borderRadius: '5px',

              _hover: {
                bg: 'Mid-Dark Blue',
              },
            }}
          >
            <Icon3Dots />
          </Box>
        </PopoverTrigger>
        <PopoverContent
          sx={{
            color: 'white',
            border: 'none',
            background: 'Mid-Dark Blue',
            w: '216px',
          }}
        >
          <PopoverBody
            sx={{
              padding: '20px',
            }}
          >
            <AppText
              sx={{
                fontSize: '16px',
                lineHeight: '22.4px',
                fontWeight: 700,
                mb: '24px',
              }}
              textTransform={'uppercase'}
            >
              {t('Settings')}
            </AppText>

            <Box display={'flex'} flexDirection={'column'} gap="16px">
              <Box
                cursor={'pointer'}
                display={'flex'}
                alignItems={'center'}
                gap={'8px'}
                onClick={() => {
                  disconnect();
                  onClose();
                }}
              >
                <IconDel />
                <AppText variant={'Text/Regular/Bold'}>
                  {t('Disconnect Wallet')}
                </AppText>
              </Box>
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Box>
    </Popover>
  );
};

export default MenuSetting;
