import React, { Dispatch, SetStateAction } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Text,
  Flex,
} from '@chakra-ui/react';
import { AppButton } from 'src/components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Paths } from 'src/utils/common';

interface PopupConfirmProps {
  isOpen: boolean;
  isEdit?: boolean;
  codeId?: number | string | null;
  setIsOpenPopupBack: Dispatch<SetStateAction<boolean>>;
}

const PopupConfirm: React.FC<PopupConfirmProps> = ({
  isOpen,
  isEdit,
  codeId,
  setIsOpenPopupBack,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onClosePopup = () => {
    isEdit
      ? navigate(`${Paths.ADMIN_CODES_DETAIL}?id=${codeId}`)
      : navigate(Paths.ADMIN_CODES_MANAGEMENT);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpenPopupBack(false)}
      isCentered={true}
      motionPreset="slideInBottom"
    >
      <ModalContent
        backgroundColor="#FFFFFF"
        borderRadius="16px"
        padding="32px"
        gap="24px"
        position="relative"
        maxWidth="500px"
      >
        <ModalHeader
          textAlign="center"
          fontFamily="'Exo 2'"
          fontWeight="700"
          fontSize={24}
          lineHeight="30px"
          color="#222325"
          padding={0}
          width="420px"
        >
          <Text>
            {t(
              'Unsaved changes will be lost. Are you sure you want to continue?',
            )}
          </Text>
        </ModalHeader>
        <ModalFooter padding={0}>
          <Flex width="100%" gap="24px">
            <AppButton height="48px" width="100%" onClick={onClosePopup}>
              <Text
                textAlign="center"
                fontFamily="'Exo 2'"
                fontWeight="700"
                fontSize={18}
                lineHeight="22px"
                textTransform="uppercase"
              >
                {t('Yes')}
              </Text>
            </AppButton>
            <AppButton
              height="48px"
              width="100%"
              onClick={() => setIsOpenPopupBack(false)}
            >
              <Text
                textAlign="center"
                fontFamily="'Exo 2'"
                fontWeight="700"
                fontSize={18}
                lineHeight="22px"
                textTransform="uppercase"
              >
                {isEdit ? t('Back to edit') : t('Back to Create')}
              </Text>
            </AppButton>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PopupConfirm;
