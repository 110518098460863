import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Text,
} from '@chakra-ui/react';
import { AppButton } from 'src/components';
import { useTranslation } from 'react-i18next';

interface LoginSuccessfullyProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const LoginSuccessfully: React.FC<LoginSuccessfullyProps> = ({
  isOpen,
  setIsOpen,
}) => {
  const { t } = useTranslation();

  const onClosePopup = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClosePopup}
      isCentered={true}
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent
        backgroundColor="#FFFFFF"
        borderRadius="16px"
        padding="32px"
        gap="24px"
        position="relative"
        maxWidth="500px"
      >
        <ModalHeader
          textAlign="center"
          fontFamily="'Exo 2'"
          fontWeight="700"
          fontSize={24}
          lineHeight="30px"
          color="#222325"
          padding={0}
        >
          <Text>{t('Your request is queued. Please allow')} </Text>
          <Text>{t('several minutes for processing. Your')} </Text>
          <Text>{t('points balance will update shortly after.')}</Text>
        </ModalHeader>

        <ModalFooter padding={0}>
          <AppButton height="48px" width="100%" onClick={onClosePopup}>
            <Text
              textAlign="center"
              fontFamily="'Exo 2'"
              fontWeight="700"
              fontSize={18}
              lineHeight="22px"
              textTransform="uppercase"
            >
              {t('Done')}
            </Text>
          </AppButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default LoginSuccessfully;
