import { Box, Flex, Modal, ModalOverlay, Spinner } from '@chakra-ui/react';
import { useSetAtom } from 'jotai';
import { FC, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProviderContext } from 'src/WalletProvider';
import { logoutAtom, msgErrorAtom } from 'src/store/auth';
import 'src/styles/layout/VoidLayout.scss';
import { isMetaMaskInstalled } from 'src/utils/helpers/etc';
import { CHAIN_ID_SUPPORT } from 'src/utils/utils';
import { useConnect, useDisconnect } from 'wagmi';
import Header from './Header';
import useWallet from 'src/hooks/useWallet';
import { DevTools } from 'jotai-devtools';
import 'jotai-devtools/styles.css';
import { useUser } from '@clerk/clerk-react';

interface IVoiLayout {
  children?: any;
  isAdmin?: boolean;
  noHeader?: boolean;
}

const VoidLayout: FC<IVoiLayout> = ({
  children,
  isAdmin,
  noHeader = false,
}) => {
  const { disconnect } = useDisconnect();
  const navigate = useNavigate();
  const isInstallMetamask = isMetaMaskInstalled();
  const user = useUser();

  const msgError = useSetAtom(msgErrorAtom);
  const doLogout = useSetAtom(logoutAtom);
  const { chainId } = useWallet();
  const { metamaskProvider: provider } = useContext(ProviderContext);

  const { connectors } = useConnect();

  const metaMaskConnector = connectors.find(
    (connector) => connector.id === 'io.metamask',
  );

  const disconnectAccountChanged = async () => {
    // disconnect();
    doLogout();
    // navigate(Paths.LOGIN);
  };

  const disconnectListener = async () => {
    disconnect();
    doLogout();
    msgError(false);
    // navigate(Paths.LOGIN);
  };

  const isChainSupported =
    chainId === CHAIN_ID_SUPPORT.ETH_MAINNET ||
    chainId === CHAIN_ID_SUPPORT.ETH_SEPOLIA ||
    chainId === CHAIN_ID_SUPPORT.ARBITRUM ||
    chainId === CHAIN_ID_SUPPORT.ARBITRUM_SEPOLIA;

  const _renderModalOverlay = () => {
    if (isChainSupported || noHeader || !chainId) {
      return null;
    }
    return (
      <Modal isOpen isCentered onClose={() => {}}>
        <ModalOverlay
          style={{
            top: '108px',
            zIndex: 10,
          }}
          bg="rgba(0, 0, 0, 0.4)"
        />
      </Modal>
    );
  };

  // useEffect(() => {
  //   provider?.on('accountsChanged', disconnectAccountChanged);
  //   provider?.on('disconnect', disconnectListener);
  //   return () => {
  //     provider?.removeListener('accountsChanged', disconnectAccountChanged);
  //     provider?.removeListener('disconnect', disconnectListener);
  //   };
  // }, []);

  useEffect(() => {
    // if (isInstallMetamask && metaMaskConnector) return;
    // doLogout();
    // msgError(false);
    // navigate(Paths.LOGIN);
  }, []);

  if (!user.isLoaded)
    return (
      <Flex
        width="100vw"
        height="100vh"
        alignItems="center"
        justifyContent="center"
        backgroundColor="#000000"
      >
        <Spinner color="#FFFFFF" height="50px" width="50px" />
      </Flex>
    );

  if (!user.isSignedIn) navigate('/sign-in');

  return (
    <Box>
      {!process.env.NODE_ENV ||
        (process.env.NODE_ENV === 'development' && <DevTools />)}
      {!noHeader && (
        <Header
          isAdmin={isAdmin}
          chainId={chainId}
          isChainSupported={isChainSupported}
        />
      )}
      <Box className={`${isAdmin ? '' : 'background main'} `} id="main">
        {children}
      </Box>
      {_renderModalOverlay()}
    </Box>
  );
};

export default VoidLayout;
