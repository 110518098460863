import { useAtomValue } from 'jotai';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import io, { Socket } from 'socket.io-client';
import { accessTokenAtom } from 'src/store/auth';
import { SocketEvent } from 'src/utils/constants/socket-type';

type UnregisterFunc = () => void;
type CallbackFunc = (...args: any[]) => any;

interface SocketIOInterface {
  socket: Socket | null;
  status: ConnectionStatus;
  error?: any;
  registerListener: (
    forEvent: SocketEvent,
    cb: CallbackFunc,
  ) => UnregisterFunc | undefined;
  unregisterListener: (forEvent: SocketEvent, cb: CallbackFunc) => void;
}

export const IoContext = createContext<SocketIOInterface>({
  socket: null,
  status: 'disconnected',
  error: undefined,
  registerListener: () => () => {},
  unregisterListener: () => {},
});

export type ConnectionStatus = 'connecting' | 'connected' | 'disconnected';

export const useSocket = () => useContext(IoContext)!;

export const SocketProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const token = useAtomValue(accessTokenAtom);
  const [status, setStatus] = useState<ConnectionStatus>('disconnected');
  const [error, setError] = useState<any>();
  const [socket, setSocket] = useState<Socket | null>(null);

  const registerListener = useCallback(
    (forEvent: SocketEvent, callback: any) => {
      if (!socket) return;
      socket.on(forEvent, callback);

      return () => {
        socket.off(forEvent, callback);
      };
    },
    [socket],
  );

  const unregisterListener = useCallback(
    (forEvent: SocketEvent, callback?: (...args: any[]) => any) => {
      if (!socket) return;
      socket.off(forEvent, callback);
    },
    [socket],
  );

  useEffect(() => {
    const query = token ? { token } : {};
    const iSocket = io(process.env.REACT_APP_SOCKET_URL!, {
      forceNew: true,
      autoConnect: false,
      query,
    });

    iSocket
      .connect()
      .on('connect_error', (error) => {
        setError(error);
      })
      .on('disconnect', () => {
        setStatus('disconnected');
      })
      .on('connect', () => {
        setStatus('connected');
      });

    setSocket(iSocket);

    return () => {
      iSocket.disconnect();
    };
  }, [token]);

  return (
    <IoContext.Provider
      value={{ socket, error, status, registerListener, unregisterListener }}
    >
      {children}
    </IoContext.Provider>
  );
};
