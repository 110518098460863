import React, { forwardRef } from 'react';
import { Button, ButtonProps, StyleProps } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

export interface AppButtonProps extends ButtonProps {
  variant?: keyof typeof appButtonStyles.variants;

  error?: boolean;
}

const AppButton = forwardRef<HTMLButtonElement, AppButtonProps>(
  (props, ref) => {
    const { variant = 'brand', error = false, children, ...rest } = props;
    const buttonStyle = error
      ? { border: '1px solid #FF4C3F', background: 'white' }
      : {};
    return (
      <Button {...rest} variant={variant} ref={ref} style={buttonStyle}>
        {children}
      </Button>
    );
  },
);

export const AppButtonLarge = (props: AppButtonProps) => {
  const { children, ...rest } = props;
  return (
    <AppButton size={'lg'} px={6} {...rest}>
      {children}
    </AppButton>
  );
};

export default AppButton;

export const appButtonStyles = {
  baseStyle: {
    borderRadius: '6px',
    boxShadow: '45px 76px 113px 7px rgba(112, 144, 176, 0.08)',
    transition: '.25s all ease',
    boxSizing: 'border-box',
    fontWeight: 500,
    _focus: {
      boxShadow: 'none',
    },
    _active: {
      boxShadow: 'none',
    },
  },
  variants: {
    //#region New theme
    chartreuseL: {
      background: 'Color Brand/Chartreuse',
      color: 'Text/primary',
      minHeight: '48px',

      borderRadius: '1000px',
      padding: '12px 24px',
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 700,

      _hover: {
        background: 'Color Brand/Chartreuse!',
      },
    },
    chartreuse: {
      background: 'Color Brand/Chartreuse',
      color: 'Text/primary',
      height: '34px',

      borderRadius: '1000px',
      padding: '8px 20px',
      fontSize: '12px',
      lineHeight: '18px',
      fontWeight: 700,

      _hover: {
        background: 'Color Brand/Chartreuse!',
      },
    },
    chartreuseS: {
      background: 'Color Brand/Chartreuse',
      color: 'Text/primary',
      fontFamily: 'Poppins',
      height: '20px',

      borderRadius: '1000px',
      padding: '4px',
      fontSize: '8px',
      lineHeight: '12px',
      fontWeight: 700,

      _hover: {
        background: 'Color Brand/Chartreuse!',
      },
    },
    //#endregion New theme

    outline: (props: StyleProps) => ({
      borderRadius: '6px',
      bg: 'none',
      color: '#1B75FF',
      fontSize: '16px',
      borderWidth: '1px',
      borderColor: '#1B75FF',
      _hover: {
        bg: mode('main.100', 'main.100')(props),
        color: 'white',
        _disabled: {
          bg: 'none',
          color: 'main.100',
        },
      },
      _disabled: {
        color: mode('rgba(0, 2, 36, 0.5)', 'rgba(0, 2, 36, 0.5)')(props),
        bg: mode('rgba(0, 2, 36, 0.1)', 'rgba(0, 2, 36, 0.1)')(props),
      },
    }),
    brand: (props: StyleProps) => ({
      bg: '#041336',
      borderRadius: '40px',
      color: 'white',
      fontSize: '18px',
      fontWeight: '700',
      fontFamily: '"Exo 2"',
      height: '42px',
      borderWidth: '1px',
      borderColor: '#222325',
      _hover: {
        bg: 'white',
        color: '#222325',
        borderWidth: '1px',
        borderColor: '#222325',
        _disabled: {
          bg: mode('rgba(0, 2, 36, 0.1)', 'rgba(0, 2, 36, 0.1)')(props),
          color: 'rgba(0, 2, 36, 0.5)',
          borderColor: 'rgba(0, 2, 36, 0.5)',
        },
      },
      _disabled: {
        opacity: 1,
        color: '#A7A9AB',
        bg: '#F4F4F4',
        borderColor: '#A7A9AB',
      },
    }),
    gradient: (props: StyleProps) => ({
      bg: '#041336',
      borderRadius: '40px',
      color: 'white',
      fontSize: '18px',
      fontWeight: '700',
      fontFamily: '"Exo 2"',
      height: '42px',
      _hover: {
        bgGradient: 'linear(to-r, #3182FF, #2CE1FF)',
        color: '#FFFFFF',
        _disabled: {
          bg: mode('rgba(0, 2, 36, 0.1)', 'rgba(0, 2, 36, 0.1)')(props),
          color: 'rgba(0, 2, 36, 0.5)',
          borderColor: 'rgba(0, 2, 36, 0.5)',
        },

        svg: {
          rect: {
            fill: '#000000',
          },
          path: {
            stroke: '#FFFFFF',
          },
        },
      },
      _disabled: {
        opacity: 1,
        color: '#A7A9AB',
        bg: '#F4F4F4',
      },
    }),
    swap: () => ({
      bg: '#3182FF',
      borderRadius: '12px',
      color: 'white',
      fontSize: '16px',
      fontWeight: '600',
      fontFamily: 'Poppins',
      _hover: {
        opacity: 0.8,
      },
      _disabled: {
        opacity: 1,
        color: '#A7A9AB',
        bg: '#F4F4F4',
      },
    }),
    stake: (props: StyleProps) => ({
      bg: '#041336',
      borderRadius: '40px',
      color: 'white',
      fontSize: '18px',
      fontWeight: '700',
      fontFamily: '"Exo 2"',
      height: '42px',
      _hover: {
        bg: 'white',
        color: '#222325',
        borderWidth: '1px',
        borderColor: '#222325',
        _disabled: {
          bg: mode('rgba(0, 2, 36, 0.1)', 'rgba(0, 2, 36, 0.1)')(props),
          color: 'rgba(0, 2, 36, 0.5)',
          borderColor: 'rgba(0, 2, 36, 0.5)',
        },
      },
      _disabled: {
        opacity: 1,
        color: '#A7A9AB',
        bg: '#F4F4F4',
        borderWidth: '1px',
        borderColor: 'rgba(0, 2, 36, 0.5)',
      },
    }),
    withdraw: (props: StyleProps) => ({
      bg: '#3182FF',
      borderRadius: '40px',
      color: 'white',
      fontSize: '14px',
      fontWeight: '700',
      fontFamily: '"Exo 2"',
      height: '30px',
      _hover: {
        bg: 'white',
        color: '#222325',
        borderWidth: '1px',
        borderColor: '#222325',
        _disabled: {
          bg: mode('rgba(0, 2, 36, 0.1)', 'rgba(0, 2, 36, 0.1)')(props),
          color: 'rgba(0, 2, 36, 0.5)',
          borderColor: 'rgba(0, 2, 36, 0.5)',
        },
      },
      _disabled: {
        opacity: 1,
        color: '#A7A9AB',
        bg: '#F4F4F4',
      },
    }),
    unstake: (props: StyleProps) => ({
      bg: 'rgba(255, 76, 63, 0.12)',
      borderRadius: '40px',
      color: '#FF4C3F',
      fontSize: '14px',
      fontWeight: '700',
      fontFamily: '"Exo 2"',
      _hover: {
        bg: 'white',
        color: '#222325',
        borderWidth: '1px',
        borderColor: '#222325',
        _disabled: {
          bg: mode('rgba(0, 2, 36, 0.1)', 'rgba(0, 2, 36, 0.1)')(props),
          color: 'rgba(0, 2, 36, 0.5)',
          borderColor: 'rgba(0, 2, 36, 0.5)',
        },
      },
      _disabled: {
        opacity: 1,
        color: '#A7A9AB',
        bg: '#F4F4F4',
      },
    }),
    claim: (props: StyleProps) => ({
      bg: '#D8F500',
      borderRadius: '40px',
      color: '#041336',
      fontSize: '14px',
      fontWeight: '700',
      fontFamily: '"Exo 2"',
      height: '30px',
      borderWidth: '1px',
      borderColor: '#D8F500',
      _hover: {
        bg: 'white',
        color: '#222325',
        borderWidth: '1px',
        borderColor: '#222325',
        _disabled: {
          bg: mode('rgba(0, 2, 36, 0.1)', 'rgba(0, 2, 36, 0.1)')(props),
          color: 'rgba(0, 2, 36, 0.5)',
          borderColor: 'rgba(0, 2, 36, 0.5)',
        },
      },
      _disabled: {
        opacity: 1,
        color: '#A7A9AB',
        bg: '#F4F4F4',
        borderColor: '#F4F4F4',
      },
    }),
    connectWallet: (props: StyleProps) => ({
      borderRadius: '12px',
      bg: 'rgba(244, 244, 244, 1)',
      color: 'black',
      fontSize: '18px',
      _hover: {
        bg: mode('line.100', 'line.100')(props),
        _disabled: {
          bg: mode('rgba(0, 2, 36, 0.1)', 'rgba(0, 2, 36, 0.1)')(props),
        },
      },
      _disabled: {
        bg: 'rgba(244, 244, 244, 1)',
        opacity: 0.8,
      },
    }),
    cancel: (props: StyleProps) => ({
      borderRadius: '6px',
      bg: 'white.100',
      fontSize: '16px',
      fontWeight: 500,
      color: 'bg.100',
      borderWidth: '1px',
      borderColor: 'border.400',
      _hover: {
        bg: mode('line.100', 'line.100')(props),
        color: 'white',
        _disabled: {
          bg: 'none',
          color: 'white',
        },
      },
    }),
    primary: (props: StyleProps) => ({
      borderRadius: '50px',
      bg: '#D8F500',
      color: '#041336',
      fontSize: '18px',
      fontWeight: 700,
      _hover: {
        bg: 'white',
        color: '#222325',
        _disabled: {
          bg: mode('rgba(0, 2, 36, 0.1)', 'rgba(0, 2, 36, 0.1)')(props),
          color: 'rgba(0, 2, 36, 0.5)',
        },
      },
      _disabled: {
        opacity: 1,
        color: '#A7A9AB',
        bg: '#F4F4F4',
      },
    }),
    active: (props: StyleProps) => ({
      bg: 'linear-gradient(268.85deg, rgba(34, 108, 255, 0.12) 22.48%, rgba(16, 132, 255, 0.12) 83.59%)',
      color: 'white',
      borderColor: '#1B75FF',
      _disabled: {
        color: 'rgba(0, 2, 36, 0.5)',
        bg: mode('rgba(0, 2, 36, 0.1)', 'rgba(0, 2, 36, 0.1)')(props),
      },
    }),
  },
};
