import { Flex } from '@chakra-ui/react';
import _ from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  IconCodeManagement,
  IconGift,
  IconInvestors,
  IconStakeManagement,
  IconTokenomics,
} from 'src/assets/icons';
import { AppLink } from 'src/components';
import 'src/styles/layout/Header.scss';
import { Paths } from 'src/utils/common';

const listItems: {
  name: string;
  path: string | string[];
  icon: React.ReactNode;
}[] = [
  // {
  //   name: 'Investors',
  //   path: Paths.ADMIN_INVESTOR,
  //   icon: <IconInvestors />,
  // },
  // {
  //   name: 'Tokenomics',
  //   path: Paths.ADMIN_TOKENOMIC,
  //   icon: <IconTokenomics />,
  // },
  // {
  //   name: 'Stake Management',
  //   path: [Paths.ADMIN_STAKE_MANAGEMENT, Paths.ADMIN_STAKE_MANAGEMENT_DETAIL],
  //   icon: <IconStakeManagement />,
  // },
  {
    name: 'Give Away History',
    path: [Paths.ADMIN_GIFT_AWAY_HISTORY],
    icon: <IconGift />,
  },
  {
    name: 'Code Management',
    path: [
      Paths.ADMIN_CODES_MANAGEMENT,
      Paths.ADMIN_CODES_NEW,
      Paths.ADMIN_CODES_DETAIL,
      Paths.ADMIN_CODES_EDIT,
    ],
    icon: <IconCodeManagement />,
  },
];

const SidebarAdmin: FC = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const isActiveMenu = (path: string | string[]) => {
    if (_.isArray(path)) {
      return path.includes(location.pathname);
    }

    return path === location.pathname;
  };

  return (
    <Flex className="sidebar">
      {_.map(listItems, (item, index) => {
        return (
          <AppLink
            key={index}
            to={_.isArray(item.path) ? item.path[0] : item.path}
            className={`sidebar-item ${
              isActiveMenu(item.path) ? 'active' : ''
            }`}
          >
            <Flex className="sidebar-item__icon">{item.icon}</Flex>
            <Flex className="sidebar-item__name">{t(item.name)}</Flex>
          </AppLink>
        );
      })}
    </Flex>
  );
};

export default SidebarAdmin;
