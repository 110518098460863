import { Box, Image, Stack, StackProps } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  IconAdmin,
  IconExchange,
  IconHome,
  IconStake,
  IconTravel,
  IconWallet,
  VoyLogoNew,
} from 'src/assets/icons';
import { useMedia } from 'src/hooks/useMedia';
import { Paths, ROLE } from 'src/utils/common';
import Footer from './Footer';
import MenuItem from './MenuItem';
import { UserInfo } from './UserInfo';
import { useUser } from '@clerk/clerk-react';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import { authAtom } from 'src/store/auth';
import { userAtom } from 'src/store/user';

const SideBar = ({ ...props }: StackProps & PropsWithChildren) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isDesktop, isMobile } = useMedia();
  const user = useUser();
  const { role_account } = useAtomValue(authAtom);
  const userInfo = useAtomValue(userAtom);

  return (
    <Stack
      {...props}
      spacing={0}
      direction={'column'}
      sx={{
        minWidth: '255px',
        width: '255px',
        borderRight: '1px',
        borderStyle: 'solid',
        borderColor: 'Mid-Dark Blue',
        ...props.sx,
      }}
    >
      {/* Logo */}
      {isMobile ? (
        <Box sx={{ p: '16px 0 32px 20px' }}>
          <Image
            src={'/images/VoyLogoNewMobile.png'}
            height="27px"
            cursor={'pointer'}
            onClick={() => navigate(Paths.HOME)}
          />
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '114.43px',
          }}
        >
          <VoyLogoNew
            width={120}
            cursor={'pointer'}
            onClick={() => navigate(Paths.HOME)}
          />
        </Box>
      )}
      {/* User info */}
      {isDesktop ? (
        <UserInfo user={user} pointBalance={userInfo?.pointBalance} />
      ) : (
        <UserInfo.Mobile user={user} pointBalance={userInfo?.pointBalance} />
      )}
      {/* Menu */}
      <Box
        sx={{
          flexGrow: '1',
        }}
      >
        {[
          {
            name: t('Home'),
            Icon: <IconHome />,
            active: true,
            comingSoon: false,
          },
          { name: t('Book Travel'), Icon: <IconTravel /> },
          { name: t('Loyalty Exchange'), Icon: <IconExchange /> },
          { name: t('Wallet'), Icon: <IconWallet /> },
          { name: t('Stake'), Icon: <IconStake /> },
          {
            name: t('Admin'),
            Icon: <IconAdmin />,
            to: Paths.ADMIN_CODES_MANAGEMENT,
            comingSoon: false,
          },
        ].map(({ name, Icon, active, comingSoon, to }) =>
          name === t('Admin') && role_account !== ROLE.ADMIN ? null : (
            <MenuItem
              name={name}
              Icon={Icon}
              active={active}
              comingSoon={comingSoon}
              onClick={() => navigate(to ?? '')}
              key={name}
            />
          ),
        )}
      </Box>
      {/* Footer */}
      <Footer />
    </Stack>
  );
};

export default SideBar;
