import {
  Box,
  BoxProps,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Image,
  useDisclosure,
} from '@chakra-ui/react';
import { useRef } from 'react';
import SideBar from './SideBar';
import { useNavigate } from 'react-router-dom';
import { Paths } from 'src/utils/common';
import { useSwipeable } from 'react-swipeable';
import SelectLang from './SelectLang';

const HeaderMobile = ({ ...props }: BoxProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const handleSwipeable = useSwipeable({
    onSwipedLeft: (eventData) => {
      onClose();
    },
  });

  return (
    <Box {...props}>
      <Box
        sx={{
          position: 'fixed',
          display: 'flex',
          justifyContent: ['space-between'],
          alignItems: 'center',
          zIndex: 1,
          //
          background: 'Deep Blue',
          w: '100%',
          p: '18.5px 20px',
          borderBottom: '1px solid red',
          borderColor: 'Color Brand/Cetacean Blue',
        }}
      >
        <Image
          src={'/images/VoyLogoNewMobile.png'}
          height="27px"
          cursor={'pointer'}
          onClick={() => navigate(Paths.HOME)}
        />
        <Box display="flex" gap="10px">
          <SelectLang />
          <Box cursor={'pointer'} ref={btnRef} onClick={onOpen}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 6H20V8H4V6ZM4 11H20V13H4V11ZM4 16H20V18H4V16Z"
                fill="white"
              />
            </svg>
          </Box>
        </Box>
      </Box>
      <Box height={'64px'} w="100%"></Box>
      {/* Menu */}
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent {...handleSwipeable}>
          <DrawerBody
            sx={{
              p: 0,
              width: '338px',
              bg: 'Deep Blue',
              color: 'white',
            }}
          >
            <SideBar
              sx={{
                width: '100%',
                minHeight: '100dvh',
              }}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default HeaderMobile;
