import { useEffect } from 'react';

export const useTermsAndPrivacy = ({
  style,
}: {
  style?: Partial<CSSStyleDeclaration>;
}) => {
  useEffect(() => {
    const addTermAndConditional = () => {
      const buttonSubmit = document.querySelector('.custom-form-button');

      if (buttonSubmit) {
        // Create a new p element
        const newElement = document.createElement('p');
        newElement.style.fontSize = '13px';
        newElement.style.textAlign = 'left';
        style?.marginBottom &&
          (newElement.style.marginBottom = style?.marginBottom);

        // Create a new a (link) element
        const linkTerm = document.createElement('a');
        linkTerm.target = '_blank'; // Opens link in a new tab
        linkTerm.rel = 'noopener noreferrer'; // Security measure
        linkTerm.style.color = '#dbf616';
        linkTerm.style.textDecoration = 'underline';
        const linkPrivacy = linkTerm.cloneNode(true) as HTMLAnchorElement;
        // Terms
        linkTerm.href = 'https://www.voytravel.com/terms-and-conditions';
        linkTerm.textContent = 'Terms & Conditions';
        // Privacy
        linkPrivacy.textContent = 'Privacy Policy';
        linkPrivacy.href = 'https://www.voytravel.com/privacy-policy';

        // Append the link to the p element
        const by = document.createTextNode(
          'By signing in or registering, you are deemed to have agreed to the Voy Travel ',
        );
        const and = document.createTextNode(' and ');
        const dot = document.createTextNode('.');
        newElement.appendChild(by);
        newElement.appendChild(linkTerm);
        newElement.appendChild(and);
        newElement.appendChild(linkPrivacy);
        newElement.appendChild(dot);

        // before the p element to the parent div
        buttonSubmit?.before(newElement);

        clearInterval(itv);
      }
    };

    const itv = setInterval(addTermAndConditional, 100);

    return () => clearInterval(itv);
  }, [style?.marginBottom]);
};
