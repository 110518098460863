import { cssVar, extendTheme, ThemeConfig } from '@chakra-ui/react';
import { appTextStyles } from 'src/components';
import { appAccordionStyle } from 'src/components/AppAccordion';
import { appButtonStyles } from 'src/components/AppButton';
import { cardStyles } from 'src/components/AppCard';
import { appInputStyles } from 'src/components/AppInput';
import { appLinkStyles } from 'src/components/AppLink';
import { appSwitchStyles } from 'src/components/AppSwitch';
import { appTextareaStyles } from 'src/components/AppTextarea';
import { appTooltipStyles } from 'src/components/AppTooltip';
import { breakpointV2, globalStyles } from 'src/themes/styles';

// Add your color mode config
const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

export default extendTheme({
  breakpoints: {
    base: '0em',
    sm: breakpointV2,
    md: breakpointV2,
  },
  config,
  styles: globalStyles.styles,
  colors: globalStyles.colors,
  shadows: {
    outline: 'none',
  },
  components: {
    Button: appButtonStyles,
    Input: appInputStyles,
    Text: appTextStyles,
    Textarea: appTextareaStyles,
    Switch: appSwitchStyles,
    Card: cardStyles,
    Link: appLinkStyles,
    Accordion: appAccordionStyle,
    Skeleton: {
      baseStyle: {
        [cssVar('skeleton-start-color').variable]: '#E5E6E9',
        [cssVar('skeleton-end-color').variable]: '#BFC2C9',
        opacity: '1 !important',
      },
    },
    SkeletonCircle: {
      baseStyle: {
        [cssVar('skeleton-start-color').variable]: '#E5E6E9',
        [cssVar('skeleton-end-color').variable]: '#BFC2C9',
        opacity: '1 !important',
      },
    },
    Tooltip: appTooltipStyles,
  },
});
