import { Box, BoxProps } from '@chakra-ui/react';
import { t } from 'i18next';
import { PropsWithChildren, ReactElement } from 'react';
import { AppButton, AppText } from 'src/components';

const MenuItem = ({
  name,
  Icon,
  children,
  active = false,
  comingSoon = true,
  ...props
}: {
  Icon: ReactElement;
  name: string;
  comingSoon?: boolean;
  active?: boolean;
  to?: string;
} & BoxProps &
  PropsWithChildren) => {
  return (
    <Box
      sx={{
        padding: ['16px 20px', '8px 16px'],
      }}
    >
      <Box
        {...props}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '8px',
          borderRadius: '5px',
          cursor: comingSoon ? 'default' : 'pointer',

          ...(active
            ? {
                backgroundColor: 'Background color/tertiary',
                color: 'white',
                span: {
                  color: 'white',
                },
              }
            : {
                color: 'Text Secondary',
                span: {
                  color: 'Text Secondary',
                },
              }),

          ...props.sx,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '12px',
          }}
        >
          {Icon}
          <AppText variant="Text/Small/Normal">{name}</AppText>
        </Box>
        {comingSoon && (
          <AppButton variant="chartreuseS">{t('Coming Soon')}</AppButton>
        )}
      </Box>
    </Box>
  );
};

export default MenuItem;
