import {
  Box,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react';
import { changeLanguage } from 'i18next';
import _ from 'lodash';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { iconLanguages, languages } from 'src/i18n';

const SelectLang = () => {
  const { t, i18n } = useTranslation();
  const initialFocusRef = useRef<HTMLDivElement>(null);
  const { onOpen, onClose, isOpen } = useDisclosure();

  const currentLang = languages[i18n.language as keyof typeof languages];

  useOutsideClick({
    ref: initialFocusRef,
    handler() {
      onClose();
    },
  });

  return (
    <Popover
      placement="top-end"
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      gutter={16}
    >
      <Box ref={initialFocusRef}>
        {/* Icon choose lang */}
        <PopoverTrigger>
          <span
            style={{
              cursor: 'pointer',
              userSelect: 'none',
            }}
          >
            {iconLanguages[i18n.language as keyof typeof languages]}
          </span>
        </PopoverTrigger>
        {/* Menu */}
        <PopoverContent
          sx={{
            border: 'none',
            background: 'Color Brand/Cetacean Blue',
          }}
        >
          <PopoverBody
            sx={{
              padding: '20px',
            }}
          >
            <Text
              sx={{
                fontSize: '16px',
                lineHeight: '22.4px',
                fontWeight: 700,
                mb: '24px',
              }}
              textTransform={'uppercase'}
            >
              {t('Select language')}
            </Text>

            <Box display={'flex'} flexDirection={'column'} gap="16px">
              {_.map(Object.keys(languages), (key) => {
                const languageName = languages[key as keyof typeof languages];
                const isSelected = currentLang === languageName;
                return (
                  <Box
                    key={key}
                    cursor={'pointer'}
                    display={'flex'}
                    gap={'16px'}
                    onClick={() => {
                      changeLanguage(key as keyof typeof languages);
                      onClose();
                    }}
                    opacity={isSelected ? '1' : 0.4}
                  >
                    {iconLanguages[key as keyof typeof languages]}
                    <Text
                      variant={'Text/Regular/Bold'}
                      color={isSelected ? '#D8F500' : '#FFFFFF'}
                    >
                      {t(languageName)}
                    </Text>
                  </Box>
                );
              })}
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Box>
    </Popover>
  );
};

export default SelectLang;
