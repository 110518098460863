import { RequestParams } from 'src/components/AppDataTable';
import { request } from 'src/utils/request';
import GiftAway from 'src/utils/types/gift-away';

export const listGiftAwayHistory = async (params: RequestParams) => {
  const { search, page, limit } = params;
  const response = await request.get<GiftAway.GiftAwayResponse>(
    '/admin/rewards',
    {
      params: {
        ...params,
        keyword: search ? search : undefined,
      },
    },
  );
  return {
    page,
    limit,
    list: response.data.list,
    count: response.data.count,
  };
};
