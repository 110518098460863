import { Box, Container, Grid, Spinner, Stack } from '@chakra-ui/react';
import { PropsWithChildren, useEffect } from 'react';
import SideBar from './SideBar';
import HeaderMobile from './HeaderMobile';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useUser } from '@clerk/clerk-react';
import { useMedia } from 'src/hooks/useMedia';
import { breakpointV2 } from 'src/themes/styles';
import { useAtomValue } from 'jotai';
import { loadingAtom } from 'src/store/auth';
import i18n, { languages } from 'src/i18n';

export const LayoutV2 = ({ children }: PropsWithChildren) => {
  const user = useUser();
  const navigate = useNavigate();
  const { isDesktop } = useMedia();
  const loading = useAtomValue(loadingAtom);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    // promoCode
    const promoCode = searchParams.get('promoCode');
    if (!!promoCode) localStorage.setItem('promoCode', promoCode);

    // local i18n
    const local = searchParams.get('local');
    if (!local || !Object.keys(languages).includes(local)) return;

    i18n.changeLanguage(local);
  }, [searchParams]);

  if (!user.user && user.isLoaded) {
    navigate('/sign-in');
  }

  if (!user.isLoaded || loading)
    return (
      <Grid
        width="100vw"
        height="100vh"
        placeContent="center"
        background="#020B20"
      >
        <Spinner height="40px" width="40px" color="#D8F500" />
      </Grid>
    );

  return (
    <Box
      sx={{
        background: '#020B20',
        color: 'white',
        letterSpacing: '0',
        minH: '100dvh', // Fix for android

        // Mobile hide scroll
        ['@media screen and (max-width: ' + breakpointV2 + ')']: {
          '*::-webkit-scrollbar': {
            display: 'none',
          },
        },

        // PC: override style
        '*::-webkit-scrollbar': {
          background: '#020B20!important',
        },
        '*::-webkit-scrollbar-thumb': {
          //   background: 'Color Brand/Cetacean Blue!important',
        },
      }}
    >
      {isDesktop ? (
        <Stack
          sx={{
            height: '100dvh',
          }}
          spacing={0}
          direction={'row'}
        >
          <SideBar
            sx={{
              display: ['none', 'flex'],
            }}
          />
          <LayoutContent>{children}</LayoutContent>
        </Stack>
      ) : (
        <LayoutContent>{children}</LayoutContent>
      )}
    </Box>
  );
};

const LayoutContent = ({ children }: PropsWithChildren) => (
  <Box
    sx={{
      overflow: 'auto',
      width: '100%',
    }}
  >
    <HeaderMobile display={['block', 'none']} />
    <Container
      sx={{
        width: ['auto', '1184px'],
        minWidth: ['auto', '1184px'],
        maxWidth: ['450px', 'auto'],
        p: ['0 20px', '0 54px'],
      }}
    >
      {children}
    </Container>
  </Box>
);
