import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  TableCellProps,
  Tbody,
  Td,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import { AppButton } from 'src/components';
import AwardWallet from 'src/utils/types/award-wallet';
import moment from 'moment';
import { LoyaltyAccountStatus, LoyaltyBalanceStatus } from 'src/utils/common';
import { useAtomValue } from 'jotai';
import { userAtom } from 'src/store/user';
import { formattedNumberWithDecimal } from 'src/utils/utils-format';
import { IconError } from 'src/assets/icons';
import { useTranslation } from 'react-i18next';

interface TableBodyProps {
  programBalances: AwardWallet.ProgramBalance[];
  loyaltyPrograms: AwardWallet.Program[];
  setSelectedItem: React.Dispatch<
    React.SetStateAction<AwardWallet.ProgramBalance | undefined>
  >;
}

const TableBody: React.FC<TableBodyProps> = ({
  programBalances = [],
  loyaltyPrograms = [],
  setSelectedItem,
}) => {
  const { t } = useTranslation();
  const user = useAtomValue(userAtom);

  const [dateNow, setDateNow] = useState(Date.now());

  useEffect(() => {
    const itv = setInterval(() => setDateNow(Date.now()), 1000);

    return () => clearInterval(itv);
  });

  const renderBalance = (programBalance: AwardWallet.ProgramBalance) => {
    if (programBalance.isUpdating === 1) {
      return 0;
    }

    if (programBalance.status === LoyaltyBalanceStatus.MATCHED) {
      return formattedNumberWithDecimal(programBalance.balance, 6);
    }

    switch (programBalance.loyaltyAccountStatus) {
      case LoyaltyAccountStatus.SUCCESS:
      case LoyaltyAccountStatus.MATCHED:
        return formattedNumberWithDecimal(programBalance.balance, 6);
      default:
        return 0;
    }
  };

  const renderLevel = (
    programBalance: AwardWallet.ProgramBalance,
    program?: AwardWallet.Program,
  ) => {
    if (programBalance.isUpdating === 1) {
      return '-';
    }

    if (programBalance.status === LoyaltyBalanceStatus.MATCHED) {
      return program?.provider === 'Manual' ? '-' : programBalance.level ?? '-';
    }

    switch (programBalance.loyaltyAccountStatus) {
      case LoyaltyAccountStatus.SUCCESS:
      case LoyaltyAccountStatus.MATCHED:
        return program?.provider === 'Manual'
          ? '-'
          : programBalance.level ?? '-';
      default:
        return '-';
    }
  };

  const renderExpiredAt = (
    programBalance: AwardWallet.ProgramBalance,
    program?: AwardWallet.Program,
  ) => {
    if (programBalance.isUpdating === 1) {
      return '-';
    }

    if (programBalance.status === LoyaltyBalanceStatus.MATCHED) {
      return program?.provider === 'Manual'
        ? '-'
        : !!programBalance.expiresBalanceAt
        ? moment(programBalance.expiresBalanceAt).format('MMM DD, YYYY')
        : '-';
    }

    switch (programBalance.loyaltyAccountStatus) {
      case LoyaltyAccountStatus.SUCCESS:
      case LoyaltyAccountStatus.MATCHED:
        return program?.provider === 'Manual'
          ? '-'
          : !!programBalance.expiresBalanceAt
          ? moment(programBalance.expiresBalanceAt).format('MMM DD, YYYY')
          : '-';
      default:
        return '-';
    }
  };

  const renderUpdatedAt = (
    programBalance: AwardWallet.ProgramBalance,
    program?: AwardWallet.Program,
  ) => {
    if (programBalance.isUpdating === 1) {
      return '-';
    }

    if (programBalance.status === LoyaltyBalanceStatus.MATCHED) {
      return program?.provider === 'Manual'
        ? '-'
        : moment(programBalance.updatedAt).format('MMM DD, YYYY');
    }

    switch (programBalance.loyaltyAccountStatus) {
      case LoyaltyAccountStatus.SUCCESS:
      case LoyaltyAccountStatus.MATCHED:
        return program?.provider === 'Manual'
          ? '-'
          : moment(programBalance.updatedAt).format('MMM DD, YYYY');
      default:
        return '-';
    }
  };

  return user && programBalances.length > 0 ? (
    <Tbody>
      {programBalances.map(
        (programBalance: AwardWallet.ProgramBalance, idx: number) => {
          const bgColor = idx % 2 === 1 ? '#F4F4F4' : '#FFFFFF';
          const program = loyaltyPrograms.find(
            (lp) => lp.id === programBalance.loyaltyProgramId,
          );

          const isExpired =
            !!programBalance.expiresBalanceAt &&
            new Date(programBalance.expiresBalanceAt).getTime() < dateNow;

          const isDisabled =
            programBalance.isUpdating === 1 ||
            programBalance.status === LoyaltyBalanceStatus.NOT_AVAILABLE ||
            programBalance.status === LoyaltyBalanceStatus.MATCHED ||
            (!programBalance.loyaltyAccountStatus && !!programBalance.code) ||
            (programBalance.loyaltyAccountStatus &&
              programBalance.loyaltyAccountStatus !==
                LoyaltyAccountStatus.SUCCESS) ||
            isExpired;

          const buttonText = () => {
            if (programBalance.isUpdating === 1) {
              return t('updating');
            }
            switch (programBalance.loyaltyAccountStatus) {
              case LoyaltyAccountStatus.PENDING:
                return t('Connecting');
              case LoyaltyAccountStatus.FAILURE:
                return t('not available');
              case LoyaltyAccountStatus.MATCHED:
                return t('Matched');
              case LoyaltyAccountStatus.SUCCESS:
                if (
                  isExpired ||
                  programBalance.status === LoyaltyBalanceStatus.NOT_AVAILABLE
                ) {
                  return t('not available');
                }
                if (programBalance.status === LoyaltyBalanceStatus.MATCHED) {
                  return t('Matched');
                }
                return t('Match Now');
              default:
                if (
                  programBalance.status === LoyaltyBalanceStatus.NOT_AVAILABLE
                ) {
                  return t('not available');
                }
                if (!programBalance.status) {
                  return t('Match Now');
                }
                if (programBalance.status === LoyaltyBalanceStatus.MATCHED) {
                  return t('Matched');
                }
                return t('Match Now');
            }
          };

          return (
            <Tr key={programBalance.id}>
              <CustomTd
                bgColor={bgColor}
                isFirst={true}
                maxWidth="300px"
                width="300px"
              >
                {program?.name}
              </CustomTd>
              <CustomTd bgColor={bgColor}>
                {renderBalance(programBalance)}
              </CustomTd>
              <CustomTd bgColor={bgColor}>
                {renderLevel(programBalance, program)}
              </CustomTd>
              <CustomTd
                bgColor={bgColor}
                color={
                  isExpired &&
                  programBalance.loyaltyAccountStatus !==
                    LoyaltyAccountStatus.FAILURE
                    ? '#FF4C3F'
                    : '#041336'
                }
                width="150px"
                minWidth="150px"
              >
                {renderExpiredAt(programBalance, program)}
              </CustomTd>
              <CustomTd bgColor={bgColor} maxWidth="180px" width="180px">
                {renderUpdatedAt(programBalance, program)}
              </CustomTd>
              <CustomTd width="142px" bgColor={bgColor} isLast={true}>
                <Flex
                  justifyContent="center"
                  justifyItems="center"
                  gap="6px"
                  width="100%"
                >
                  <Box width="14px" />
                  <AppButton
                    size="small"
                    width="150px"
                    fontFamily="Poppins"
                    fontWeight="700"
                    fontSize="16px"
                    lineHeight="20px"
                    textTransform="uppercase"
                    isDisabled={isDisabled}
                    onClick={() => setSelectedItem(programBalance)}
                  >
                    {buttonText()}
                  </AppButton>
                  {programBalance.loyaltyAccountStatus ===
                    LoyaltyAccountStatus.FAILURE &&
                  programBalance.isUpdating === 0 ? (
                    <Tooltip
                      fontSize="16px"
                      lineHeight="22px"
                      fontFamily="Poppins"
                      fontWeight="400"
                      color="#222325"
                      padding="12px 16px"
                      maxWidth="360px!important"
                      placement="start-start"
                      label={t(
                        'An error has occurred. Please connect to your Loyalty Program account again.',
                      )}
                    >
                      <Flex alignItems="center">
                        <IconError />
                      </Flex>
                    </Tooltip>
                  ) : (
                    <Box width="14px" />
                  )}
                </Flex>
              </CustomTd>
            </Tr>
          );
        },
      )}
    </Tbody>
  ) : (
    <Tbody>
      <Tr>
        <CustomTd>-</CustomTd>
        <CustomTd>0</CustomTd>
        <CustomTd>-</CustomTd>
        <CustomTd>-</CustomTd>
        <CustomTd>-</CustomTd>
        <CustomTd width="142px">
          <Flex justifyContent="center" width="100%">
            <AppButton
              size="small"
              width="142px"
              fontFamily="Poppins"
              fontWeight="700"
              fontSize="16px"
              lineHeight="20px"
              textTransform="uppercase"
              color="#041336"
              isDisabled
            >
              {t('Match Now')}
            </AppButton>
          </Flex>
        </CustomTd>
      </Tr>
    </Tbody>
  );
};

const CustomTd: React.FC<
  React.PropsWithChildren<{
    bgColor?: string;
    isLast?: boolean;
    isFirst?: boolean;
  }> &
    TableCellProps
> = ({ children, bgColor, color, isFirst, isLast, ...rest }) => {
  return (
    <Td
      fontFamily="Poppins"
      fontWeight="400"
      fontSize="16px"
      lineHeight="22px"
      textTransform="none"
      color={color ? color : '#041336'}
      border="none"
      padding={0}
      {...rest}
    >
      <Flex
        backgroundColor={bgColor}
        verticalAlign="bottom"
        padding="13px 24px"
        marginTop="16px"
        height="68px"
        alignItems="center"
        borderTopLeftRadius={isFirst ? '10px' : 'none'}
        borderBottomLeftRadius={isFirst ? '10px' : 'none'}
        borderTopRightRadius={isLast ? '10px' : 'none'}
        borderBottomRightRadius={isLast ? '10px' : 'none'}
        whiteSpace="wrap"
      >
        {children}
      </Flex>
    </Td>
  );
};

export default TableBody;
