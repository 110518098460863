import { Flex, Table, TableContainer } from '@chakra-ui/react';
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import TableFooter from './TableFooter';
import AwardWallet from 'src/utils/types/award-wallet';
import { useEffect, useState } from 'react';
import MatchLoyaltyPoints from '../popup/match-loyalty-points';

interface LoyaltyProgramTableProps {
  loyaltyPrograms: AwardWallet.Program[];
  loyaltyBalances: AwardWallet.ProgramBalance[];
  count: number;
  page: number;
  isLoading: boolean;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  getLoyaltyBalances: () => void;
}

export const PER_PAGE = 5;

const LoyaltyProgramTable: React.FC<LoyaltyProgramTableProps> = ({
  loyaltyPrograms,
  loyaltyBalances,
  count,
  page,
  isLoading,
  setPage,
  getLoyaltyBalances,
}) => {
  const [totalPage, setTotalPage] = useState<number>();
  const [selectedItem, setSelectedItem] =
    useState<AwardWallet.ProgramBalance>();

  useEffect(() => {
    setTotalPage(Math.ceil(count / PER_PAGE));
  }, [count]);

  useEffect(() => {
    if (totalPage && totalPage < page) {
      setPage(totalPage);
      return;
    }
  }, [page, setPage, totalPage]);

  return (
    <Flex marginTop="30px">
      <TableContainer
        width="100%"
        backgroundColor="#FFFFFF"
        borderRadius="16px"
        boxShadow="0 4px 180px rgba(53, 9, 72, 0.1)"
        padding="12px 20px 24px 20px"
      >
        <Table variant="simple">
          <TableHeader
            getLoyaltyBalances={getLoyaltyBalances}
            disabled={loyaltyBalances.length === 0}
          />
          <TableBody
            programBalances={loyaltyBalances}
            loyaltyPrograms={loyaltyPrograms}
            setSelectedItem={setSelectedItem}
          />
          <TableFooter
            page={page}
            totalPage={totalPage}
            setPage={setPage}
            isLoading={isLoading}
          />
        </Table>
      </TableContainer>
      <MatchLoyaltyPoints
        isOpen={!!selectedItem}
        onClose={() => setSelectedItem(undefined)}
        loyaltyBalance={selectedItem}
        loyaltyPrograms={loyaltyPrograms}
        getLoyaltyBalances={getLoyaltyBalances}
      />
    </Flex>
  );
};

export default LoyaltyProgramTable;
