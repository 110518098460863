import { Box, Flex, Text } from '@chakra-ui/react';
import _ from 'lodash';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Form from './Form';
import { useNavigate } from 'react-router-dom';
import { Paths, TYPE_CODE } from 'src/utils/common';
import Code from 'src/utils/types/codes';
import PopupConfirm from './PopupConfirm';

const DEFAULT_FORM_VALUE: Code.Info = {
  name: '',
  startTime: '',
  endTime: '',
  amount: '',
  redirectUrl: '',
  messageSuccess: [
    { lang: 'en', message: '' },
    { lang: 'jp', message: '' },
  ],
  code: '',
  redeemLimit: '',
  status: false,
  type: TYPE_CODE.PROMO_CODE,
};

const AdminCodesNewPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isChanged = useRef(false);
  const [isOpenPopupBack, setIsOpenPopupBack] = useState(false);

  return (
    <Box padding="24px" overflow="auto" fontFamily="Poppins" width="572px">
      <Flex
        flexDirection="row"
        alignItems="center"
        marginBottom="24px"
        gap="16px"
        width="fit-content"
      >
        <Box
          __css={{ 'svg path': { stroke: '#393D49' } }}
          cursor="pointer"
          onClick={() =>
            isChanged.current
              ? setIsOpenPopupBack(true)
              : navigate(Paths.ADMIN_CODES_MANAGEMENT)
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="40"
            viewBox="0 0 16 40"
            fill="none"
          >
            <path
              d="M10 30L2.44444 22L10 14"
              stroke="#393D49"
              strokeWidth="2.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Box>
        <Text
          fontFamily="'Exo 2'"
          fontWeight="700"
          fontSize="32px"
          lineHeight="40px"
          color="#041336"
        >
          {t('Code Creation')}
        </Text>
      </Flex>

      <Form code={DEFAULT_FORM_VALUE} isChanged={isChanged} />

      <PopupConfirm
        isOpen={isOpenPopupBack}
        setIsOpenPopupBack={setIsOpenPopupBack}
      />
    </Box>
  );
};
export default AdminCodesNewPage;
