import { HStack, VStack } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IconBackpack,
  IconCopyNew,
  IconMetamaskNew,
  IconWalletConnect,
} from 'src/assets/icons';
import { AppText } from 'src/components';
import AppTooltip from 'src/components/AppTooltip';
import { useAccount } from 'wagmi';

const ContentConnected = () => {
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);
  const account = useAccount();

  useEffect(() => {
    if (!copied) return;

    const timeout = setTimeout(() => setCopied(false), 1000);

    return () => clearTimeout(timeout);
  }, [copied]);

  const walletIcon = useMemo(() => {
    if (!account || !account.connector) return;

    if (
      account.connector?.id === 'io.metamask' ||
      account.connector?.id === 'io.metamask.mobile'
    ) {
      return <IconMetamaskNew width="72px" height="72px" />;
    } else if (account.connector?.id === 'app.backpack') {
      return <IconBackpack width="72px" height="72px" />;
    } else {
      return <IconWalletConnect width="72px" height="72px" />;
    }
  }, [account]);

  const handleOnBtnClicked = () => {
    if (!account?.address) return;
    navigator.clipboard.writeText(account.address).then(() => setCopied(true));
  };

  if (!account.address) return null;

  return (
    <VStack
      sx={{
        alignItems: 'center',
        h: '100%',
        justifyContent: 'space-between',
      }}
    >
      <VStack
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          gap: '10px',
          flexGrow: 1,
        }}
      >
        {walletIcon}
        <AppText
          sx={{
            fontFamily: '"Exo 2"',
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: 600,
            color: 'Color Brand/Chartreuse',
            textAlign: 'center',
          }}
          textTransform="uppercase"
        >
          {t('wallet Connected')}
        </AppText>
      </VStack>
      <HStack
        sx={{
          justifyContent: 'center',
          gap: '8px',
          color: 'Mid-Dark Blue',
          background: '#8BA6CF',
          w: '154px',
          maxH: '24px',
          borderRadius: '1000px',
          position: 'relative',
        }}
      >
        {account.address && (
          <AppText variant="Text/Regular/Normal">
            {`${account.address.slice(0, 5)}...${account.address.slice(
              account.address.length - 4,
            )}`}
          </AppText>
        )}
        <AppTooltip isOpen={copied} placement="top" label={t('Copied!')}>
          <IconCopyNew cursor="pointer" onClick={handleOnBtnClicked} />
        </AppTooltip>
      </HStack>
    </VStack>
  );
};

export default ContentConnected;
