import { useAtomValue, useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { userAtom, getUserAtom } from 'src/store/user';

type ReturnType = {
  balancePoint?: number;
  getUser: (address?: string | undefined) => Promise<void>;
};

const usePointsBalance = (): ReturnType => {
  const user = useAtomValue(userAtom);
  const getUser = useSetAtom(getUserAtom);

  useEffect(() => {
    getUser();

    const interval = setInterval(() => getUser(), 60000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    balancePoint:
      user?.pointBalance === undefined ? undefined : +user?.pointBalance,
    getUser,
  };
};

export default usePointsBalance;
