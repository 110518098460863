import { Flex, Link, Text } from '@chakra-ui/react';
import React, { useMemo, useState } from 'react';
import InputFrom from './InputFrom';
import InputTo from './InputTo';
import { IconChecked, IconPurchase, IconUnchecked } from 'src/assets/icons';
import AwardWallet from 'src/utils/types/award-wallet';
import { AppButton } from 'src/components';
import { Paths } from 'src/utils/common';
import { loyaltyBalanceMatch } from 'src/api/voy-wallet';
import { toastSuccess } from 'src/utils/utils-notify';
import usePointsBalance from 'src/hooks/usePointsBalance';
import { useAtomValue } from 'jotai';
import { addressAtom } from 'src/store/auth';
import { useTranslation } from 'react-i18next';

interface BodyProps {
  loyaltyBalance: AwardWallet.ProgramBalance;
  loyaltyProgram?: AwardWallet.Program;
  getLoyaltyBalances: () => void;
  onClose: () => void;
}

const Body: React.FC<BodyProps> = ({
  loyaltyBalance,
  loyaltyProgram,
  getLoyaltyBalances,
  onClose,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [isAccept, setIsAccept] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOver100k, setIsOver100k] = useState(false);
  const [error, setError] = useState('');
  const { getUser } = usePointsBalance();
  const address = useAtomValue(addressAtom);
  const { t } = useTranslation();

  const outputValue = useMemo(() => {
    if (
      !loyaltyProgram?.exchangeRate ||
      !Number(loyaltyProgram?.exchangeRate)
    ) {
      return '0';
    }
    return (
      Number(loyaltyProgram?.exchangeRate) * Number(inputValue)
    ).toString();
  }, [inputValue, loyaltyProgram?.exchangeRate]);

  const isDisabled = useMemo(() => {
    return !!error || !isAccept;
  }, [error, isAccept]);

  const handleOnClick = () => {
    if (+outputValue > 100_000) {
      setIsOver100k(true);
      return;
    }
    setIsLoading(true);

    loyaltyBalanceMatch(loyaltyBalance.id, inputValue)
      .then(() => {
        getLoyaltyBalances();
        getUser(address);
        toastSuccess({ message: t('You have matched successfully!') });
        onClose();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Flex flexDirection="column" alignItems="center" gap="16px">
      <InputFrom
        value={inputValue}
        setInputValue={setInputValue}
        loyaltyBalance={loyaltyBalance}
        setIsOver100k={setIsOver100k}
        error={error}
        setError={setError}
      />
      <IconPurchase />
      <InputTo value={outputValue} isOver100k={isOver100k} />
      <Flex
        alignSelf="flex-start"
        alignItems="center"
        gap="10px"
        height="24px"
        width="100%"
        marginTop="8px"
        userSelect="none"
      >
        <Flex onClick={() => setIsAccept(!isAccept)} cursor="pointer">
          {isAccept ? (
            <IconChecked width="20px" height="20px" />
          ) : (
            <IconUnchecked width="20px" height="20px" />
          )}
        </Flex>
        <Text
          whiteSpace="nowrap"
          fontFamily="'Poppins'"
          fontWeight="400"
          fontSize={14}
          lineHeight="20px"
        >
          {t('I agree to Voy Travel’s')}{' '}
          <Link
            href={Paths.TERM_AND_CONDITIONAL}
            color="#3182FF!important"
            textDecoration="underline"
            target="_blank"
            _hover={{ textDecoration: 'underline' }}
          >
            {t('Terms and Conditions')}
          </Link>
        </Text>
      </Flex>
      <AppButton
        height="48px"
        width="100%"
        marginTop="-4px"
        isDisabled={isDisabled}
        isLoading={isLoading}
        onClick={handleOnClick}
      >
        <Text
          textAlign="center"
          fontFamily="'Exo 2'"
          fontWeight="700"
          fontSize={18}
          lineHeight="22px"
          textTransform="uppercase"
        >
          {t('Match Now')}
        </Text>
      </AppButton>
    </Flex>
  );
};

export default Body;
