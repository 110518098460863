export const getMetamaskProvider = () => {
  let provider;
  if ((window as any)?.ethereum?.providers) {
    provider = (window as any)?.ethereum?.providers?.find(
      (item: any) => item?.isMetaMask === true,
    );
  } else {
    provider = (window as any)?.ethereum;
  }
  return provider;
};

export const getBackpackProvider = () => {
  const provider = (window as any)?.ethereum?.providers?.find(
    (item: any) => item?.isBackpack === true,
  );

  return provider;
};
