import { Box } from '@chakra-ui/react';
import { useAtom, useSetAtom } from 'jotai';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connectWallet } from 'src/api/login';
import { IconWallet } from 'src/assets/icons';
import { AppText } from 'src/components';
import { RequestParams } from 'src/components/AppDataTableNew';
import { useAccWeb } from 'src/hooks/useAccWeb';
import { useDisconnect } from 'src/hooks/useDisconnect';
import { addressAtom, signedAtom } from 'src/store/auth';
import { getUserAtom } from 'src/store/user';
import { toastError, toastSuccess } from 'src/utils/utils-notify';
import { useConnect, useSignMessage } from 'wagmi';
import VoyCard from '../VoyCard';
import ButtonConnect from './ButtonConnect';
import ContentConnected from './ContentConnected';
import MenuSetting from './MenuSetting';
import { WALLET_TYPE } from 'src/utils/common';
import { isEmpty } from 'lodash';

const ConnectWallet = ({
  setParams,
}: {
  setParams: React.Dispatch<React.SetStateAction<RequestParams>>;
}) => {
  const { t } = useTranslation();
  const { isConnectedWeb, isConnected } = useAccWeb();
  const { connectors, connectAsync } = useConnect();
  const { address } = useAccWeb();
  const { disconnect } = useDisconnect();
  const { signMessageAsync } = useSignMessage();
  const setAddress = useSetAtom(addressAtom);
  const [signed, setSigned] = useAtom(signedAtom);
  const getUser = useSetAtom(getUserAtom);
  const addressOld = useRef<string>();
  const [loading, setLoading] = useState(false);

  const connectSuccess = useCallback(
    (account: `0x${string}`) => {
      setSigned(true);
      setAddress(account);
      toastSuccess({ message: t('Connected success') });
      //
      getUser();
      setParams((prev) => ({ ...prev }));
    },
    [getUser, setAddress, setParams, setSigned, t],
  );

  const signMessage = useCallback(
    async (account: `0x${string}`) => {
      // Sign message
      const message =
        (await signMessageAsync({ message: t('Connect to Voy') }).catch(() => {
          toastError({ message: t('User did not sign to connect') });
        })) || '';

      // Call api
      await connectWallet({
        signature: message,
        address: account,
      })
        .then(() => {
          connectSuccess(account);
        })
        .catch(() => {
          disconnect();
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [connectSuccess, disconnect, signMessageAsync, t],
  );

  const handleConnectWallet = async (type: WALLET_TYPE) => {
    const connector = connectors.find((c) => c.name === type);
    if (!connector) return;

    setLoading(true);

    connectAsync({ connector })
      .then(async (res) => {
        const accounts = res.accounts;
        const account = accounts[0];

        // Check signed
        if (type === 'Backpack' && signed) {
          // Not re-sign with Backpack signed before
          connectSuccess(account);
        } else {
          signMessage(account);
        }
      })
      .catch((e) => {
        if (
          e instanceof Error &&
          e.name === 'ConnectorAlreadyConnectedError' &&
          address
        ) {
          signMessage(address);
        } else {
          toastError({ message: t('User rejected') });
          setLoading(false);
        }
      });
  };

  // User switch address wallet
  useEffect(() => {
    if (address === addressOld.current || !signed) return;

    if (isEmpty(addressOld.current) || isEmpty(address)) {
      addressOld.current = address;
    } else {
      console.log('>>> switch address');
      addressOld.current = undefined;
      setSigned(false);
      disconnect();
    }
  }, [address, disconnect, setSigned, signed]);

  // TODO: fix for bug of backpack
  useEffect(() => {
    if (!isConnected && signed) {
      setSigned(false);
    }
  }, [isConnected, setSigned, signed]);

  if (isConnectedWeb)
    return (
      <VoyCard
        icon={
          <Box transform={['scale(1.5)', 'none']}>
            <IconWallet />
          </Box>
        }
        menuLeft={<MenuSetting />}
        body={<ContentConnected />}
      />
    );

  return (
    <VoyCard
      icon={
        <Box transform={['scale(1.5)', 'none']}>
          <IconWallet />
        </Box>
      }
      title={t('Connect your wallet')}
      description={
        <Trans>
          <b>
            Link your crypto wallet to earn
            <AppText
              as={'b'}
              sx={{
                fontFamily: 'Poppins',
                fontSize: '14px',
                lineHeight: '21px',
                fontWeight: 700,
                color: 'Color Brand/Chartreuse',
              }}
            >
              $100
            </AppText>
            free Travel Credits.
          </b>
          You'll soon be able to stake assets and earn even more your rewards.
        </Trans>
      }
      footer={
        <Box
          sx={{
            display: 'flex',
            flexDir: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <ButtonConnect
              type="MetaMask"
              onClick={handleConnectWallet}
              isLoading={loading}
            />
          </Box>
          <ButtonConnect
            type="WalletConnect"
            onClick={handleConnectWallet}
            isLoading={loading}
          />
          <ButtonConnect
            type="Backpack"
            onClick={handleConnectWallet}
            isLoading={loading}
          />
        </Box>
      }
    />
  );
};

export default ConnectWallet;
