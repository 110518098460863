import { createContext, ReactNode } from 'react';
import { getBackpackProvider, getMetamaskProvider } from './connectors';
import { useAccountEffect } from 'wagmi';
import { useSetAtom } from 'jotai';
import { signedAtom } from './store/auth';

export const ProviderContext = createContext<{
  metamaskProvider?: any;
  backpackProvider?: any;
}>({
  metamaskProvider: undefined,
  backpackProvider: undefined,
});

const WalletProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const metamaskProvider = getMetamaskProvider();
  const backpackProvider = getBackpackProvider();
  const setSigned = useSetAtom(signedAtom);

  useAccountEffect({
    onDisconnect() {
      // Fix/607: Reset signed
      setSigned(false);
    },
  });

  return (
    <ProviderContext.Provider value={{ metamaskProvider, backpackProvider }}>
      {children}
    </ProviderContext.Provider>
  );
};

export default WalletProvider;
