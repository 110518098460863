import { Flex } from '@chakra-ui/react';
import { FC, ReactElement } from 'react';
import 'src/styles/layout/AdminLayout.scss';
import SidebarAdmin from './SidebarAdmin';
import VoidLayout from './VoidLayout';

interface IAdminLayout {
  children: ReactElement;
}

const AdminLayout: FC<IAdminLayout> = ({ children }) => {
  return (
    <VoidLayout isAdmin>
      <Flex className="admin-layout">
        <SidebarAdmin />
        <Flex className="admin-layout__content">{children}</Flex>
      </Flex>
    </VoidLayout>
  );
};

export default AdminLayout;
