import {
  Box,
  Button,
  GridItem,
  HStack,
  Link,
  SimpleGrid,
} from '@chakra-ui/react';
import { useAtomValue } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { getUserZealy, postUserZealy } from 'src/api/user-zealy';
import { IconDollar, IconPush, IconReload } from 'src/assets/icons';
import { AppText } from 'src/components';
import { RequestParams } from 'src/components/AppDataTableNew';
import usePointsBalance from 'src/hooks/usePointsBalance';
import { accessTokenAtom } from 'src/store/auth';
import { COMMON_ERROR_MESSAGE } from 'src/utils/constants/constants';
import { number2KMBT } from 'src/utils/utils-format';
import { sleep } from 'src/utils/utils-helper';
import { toastError } from 'src/utils/utils-notify';
import ConnectWallet from './connect-wallet';
import VoyCard from './VoyCard';

const AboutVoy: React.FC<{
  setParams: React.Dispatch<React.SetStateAction<RequestParams>>;
}> = ({ setParams }) => {
  const { t } = useTranslation();
  const [loadingUpdate, setLoadingUpload] = useState(false);
  const [amount, setAmount] = useState(0);
  const accessToken = useAtomValue(accessTokenAtom);
  const { getUser } = usePointsBalance();

  const getBalanceTravel = useCallback(async () => {
    try {
      const res = await getUserZealy();
      if (res?.point) {
        setAmount(Number(res.point));
        return Number(res.point);
      } else {
        setAmount(0);
        return 0;
      }
    } catch (error) {
    } finally {
    }
  }, []);

  useEffect(() => {
    if (accessToken) getBalanceTravel();
  }, [getBalanceTravel, accessToken]);

  const handleReload = async () => {
    if (loadingUpdate) return;

    setLoadingUpload(true);

    try {
      await postUserZealy();
      await sleep(1_300);
      //   Refresh
      const newAmount = await getBalanceTravel();
      if (newAmount !== amount) {
        // Refresh history, balance user
        setParams((prev) => ({ ...prev }));
        getUser();
      }
    } catch (error) {
      toastError({ message: t(COMMON_ERROR_MESSAGE) });
    } finally {
      setLoadingUpload(false);
    }
  };

  return (
    <GridItem
      area={'card'}
      sx={{
        overflow: ['auto', 'unset'],
        margin: ['0 -20px', '0'],
        padding: ['0 20px', '0'],
      }}
    >
      <SimpleGrid spacing={['16px', '20px']} columns={3} w={['896px', 'auto']}>
        <ConnectWallet setParams={setParams} />
        <VoyCard
          icon={
            <Box transform={['scale(1.5)', 'none']}>
              <IconDollar />
            </Box>
          }
          title={t('Want more credits?')}
          description={
            <Box>
              <Trans values={{ amount: '$250' }}>
                <b>Complete quests</b> to earn up to
                <AppText
                  as={'b'}
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: '14px',
                    lineHeight: '21px',
                    fontWeight: 700,
                    color: 'Color Brand/Chartreuse',
                  }}
                >
                  {`{{amount}}`}
                </AppText>
                in additional travel credits and boost your rewards balance.
              </Trans>
              <HStack mt="16px" alignItems="center" gap="4px">
                <Box maxW="163px">
                  <Trans values={{ amount: '$' + number2KMBT(amount) }}>
                    You’ve earned
                    <AppText
                      as={'b'}
                      sx={{
                        fontFamily: 'Poppins',
                        fontSize: '14px',
                        lineHeight: '21px',
                        fontWeight: 700,
                        color: 'Color Brand/Chartreuse',
                      }}
                    >
                      {`{{amount}}`}
                    </AppText>
                    Travel Credits from quests!
                  </Trans>
                </Box>
                <IconReload
                  cursor={loadingUpdate ? 'not-allowed' : 'pointer'}
                  onClick={handleReload}
                  style={{
                    animation: loadingUpdate
                      ? `spin 2s linear infinite`
                      : 'none',
                  }}
                />
              </HStack>
            </Box>
          }
          footer={
            <Link
              href="https://zealy.io/cw/voytravelfi/questboard"
              target="_blank"
            >
              <Button
                w={'100%'}
                variant={'chartreuse'}
                textTransform={'uppercase'}
              >
                {t('Earn More Credits')}
              </Button>
            </Link>
          }
        />
        <VoyCard
          icon={
            <Box transform={['scale(1.5)', 'none']}>
              <IconPush />
            </Box>
          }
          title={t('Stay tuned!')}
          description={
            <Trans>
              Your travel credits will be redeemable for
              <b>discounts in amazing experiences</b> when we launch.
            </Trans>
          }
          footer={
            <Link
              href="https://twitter.com/intent/follow?screen_name=voytravelfi"
              target="_blank"
            >
              <Button
                w={'100%'}
                variant={'chartreuse'}
                textTransform={'uppercase'}
              >
                {t('Follow Us')}
              </Button>
            </Link>
          }
        />
      </SimpleGrid>
    </GridItem>
  );
};

export default AboutVoy;
