import React from 'react';
import { Flex, Table, TableContainer, Text } from '@chakra-ui/react';
import AwardWallet from 'src/utils/types/award-wallet';
import { useEffect, useState } from 'react';
import { matchHistories } from 'src/api/voy-wallet';
import TableHeader from './history-table/TableHeader';
import TableBody from './history-table/TableBody';
import { IconArrowLeft } from 'src/assets/icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Histories: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [matches, setMatches] = useState<AwardWallet.Match[]>([]);

  const getMatchHistories = () => {
    matchHistories().then((res) => setMatches(res.list));
  };

  useEffect(() => {
    getMatchHistories();
  }, []);

  return (
    <Flex flexDirection="column" width="100%">
      <Flex gap="12px" marginBottom="32px">
        <Flex cursor="pointer" onClick={() => navigate(-1)}>
          <IconArrowLeft width="22.5px" />
        </Flex>
        <Text
          fontFamily="'Exo 2'"
          fontWeight="700"
          fontSize="32px"
          lineHeight="50px"
          color="#FFFFFF"
          cursor="default"
        >
          {t('Matching History')}
        </Text>
      </Flex>
      <Flex marginTop="8px" width="100%">
        <TableContainer
          width="100%"
          backgroundColor="#FFFFFF"
          borderRadius="16px"
          boxShadow="0 4px 180px rgba(53, 9, 72, 0.1)"
          padding="12px 20px 24px 20px"
        >
          <Table variant="simple">
            <TableHeader />
            <TableBody matches={matches} />
          </Table>
        </TableContainer>
      </Flex>
    </Flex>
  );
};

export default Histories;
