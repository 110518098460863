import React from 'react';
import { Flex, Text, Tfoot, Th, Tr } from '@chakra-ui/react';
import { IconDoubleArrowRight } from 'src/assets/icons';
import { AppLink } from 'src/components';
import AppPagination from 'src/components/AppPagination';
import { Paths } from 'src/utils/common';
import { useTranslation } from 'react-i18next';

interface TableFooterProps {
  page: number;
  totalPage?: number;
  isLoading: boolean;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

const TableFooter: React.FC<TableFooterProps> = ({
  page,
  totalPage,
  isLoading,
  setPage,
}) => {
  const { t } = useTranslation();

  return (
    <Tfoot>
      <Tr>
        <Th colSpan={6} borderColor="transparent" padding={0}>
          <Flex
            justifyContent="center"
            marginTop="7px"
            userSelect="none"
            pointerEvents={isLoading ? 'none' : 'auto'}
          >
            {!!totalPage && totalPage > 1 && (
              <AppPagination
                pageCount={totalPage}
                onPageChange={(page) => setPage(page.selected + 1)}
                forcePage={page - 1}
              />
            )}
          </Flex>
        </Th>
      </Tr>
      <Tr>
        <Th borderColor="transparent" />
        <Th borderColor="transparent" />
        <Th borderColor="transparent" />
        <Th borderColor="transparent" />
        <Th
          colSpan={2}
          borderColor="transparent"
          paddingTop="16px"
          paddingRight="0"
        >
          <Flex gap="8px" alignItems="center" justifyContent="flex-end">
            <AppLink
              to={Paths.MATCH_HISTORIES}
              display="flex"
              alignItems="center"
              gap="8px"
            >
              <Text
                fontFamily="Poppins"
                fontWeight="500"
                fontSize="14px"
                lineHeight="20px"
                color="#43ADF9"
                textTransform="none"
                textDecoration="underline"
              >
                {t('Go to match history')}
              </Text>
              <IconDoubleArrowRight />
            </AppLink>
          </Flex>
        </Th>
      </Tr>
    </Tfoot>
  );
};

export default TableFooter;
