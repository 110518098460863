export enum SocketEvent {
  VOY_PRICE_UPDATED = 'VOY_PRICE_UPDATED',
  ETH_PRICE_UPDATED = 'ETH_PRICE_UPDATED',
  TX_SUCCESS = 'txSuccess',
  LOYALTY_TIMEOUT_ERROR = 'TIMEOUT_ERROR',
  LOYALTY_UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  LOYALTY_EMAIL_OR_PASSWORD_WRONG = 'EMAIL_OR_PASSWORD_WRONG',
  LOYALTY_CONNECT_SUCCESS = 'CONNECT_SUCCESS',
  LOYALTY_LOCKED_DUE_TO_INVALID_LOGIN_ATTEMPS = 'LOCKED_DUE_TO_INVALID_LOGIN_ATTEMPS',
  LOYALTY_REQUIRE_ADDITIONAL_USER_ACTION = 'REQUIRE_ADDITIONAL_USER_ACTION',
  LOYALTY_PROVIDER_DISABLED = 'PROVIDER_DISABLED',
  LOYALTY_UPDATE_SUCCESS = 'UPDATE_SUCCESS',
  LOYALTY_UPDATE_FALURE = 'UPDATE_FALURE',
  LOYALTY_START_UPDATING = 'START_UPDATING',
}
