import { request } from 'src/utils/request';
import AwardWallet from 'src/utils/types/award-wallet';

export const generateLinkConnectAward = async (
  platform: string = 'desktop',
) => {
  const response = await request.get<string>(
    '/voy-wallet/generate-link-connect-award',
    { params: { platform } },
  );

  return response.data;
};

export const userConnectAward = async (code: string) => {
  const response = await request.get<string>(
    `/voy-wallet/user-connect-award/${code}`,
  );

  return response.data;
};

export const cancelLoginAward = async () => {
  const response = await request.post<string>('/voy-wallet/cancel-login-award');

  return response.data;
};

export const loginLoyaltyProgram = async (
  body: AwardWallet.LoginLoyaltyProgram,
) => {
  const response = await request.post<AwardWallet.LoginLoyaltyProgramResponse>(
    '/voy-wallet/loyalty-program/login',
    body,
  );

  return response.data;
};

export const fetchLoyaltyBalances = async (page: number, limit: number) => {
  const response = await request.get<AwardWallet.LoyaltyBalancesResponse>(
    '/voy-wallet/loyalty-balances',
    { params: { page, limit } },
  );

  return response.data;
};

export const fetchLoyaltyPrograms = async (keyword?: string) => {
  const response = await request.get<AwardWallet.Program[]>(
    '/voy-wallet/loyalty-programs',
    { params: { keyword } },
  );

  return response.data;
};

export const loyaltyBalanceMatch = async (id: number, balance: string) => {
  const response = await request.post<AwardWallet.Program[]>(
    `/voy-wallet/loyalty-balances/${id}/match`,
    { balance },
  );

  return response.data;
};

export const matchHistories = async () => {
  const response = await request.get<AwardWallet.MatchHistoriesResponse>(
    `/voy-wallet/match-histories`,
  );

  return response.data;
};

export const refreshBalance = async () => {
  const response = await request.post<boolean>(
    `/voy-wallet/loyalty-balances/refresh`,
  );

  return response.data;
};
