import { Box, Flex, Spinner } from '@chakra-ui/react';
import { FC, ReactElement, useEffect, useState } from 'react';
import VoidLayout from './VoidLayout';
import 'src/styles/layout/BasePage.scss';
import Footer from './Footer';

interface IBasePage {
  className?: string;
  children: ReactElement;
  onInitPage?: () => Promise<void>;
}

const BasePage: FC<IBasePage> = ({ onInitPage, className = '', children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onInit = async () => {
    try {
      setIsLoading(true);
      onInitPage && (await onInitPage());
      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onInit().then();
  }, []);

  return (
    <VoidLayout>
      <Flex
        justifyContent={'center'}
        className={`base-page-container ${className}`}
      >
        <Box className={`base-page-container__content`}>
          {isLoading ? (
            <Flex justifyContent={'center'}>
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
            </Flex>
          ) : (
            <Box width={'full'} className="base-page-container__content--full">
              {children}
            </Box>
          )}
        </Box>
      </Flex>
      <Footer />
    </VoidLayout>
  );
};

export default BasePage;
