import { Box, Image, Text } from '@chakra-ui/react';
import { useClerk, useUser } from '@clerk/clerk-react';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { IconLogoutNew } from 'src/assets/icons';
import { useDisconnect } from 'src/hooks/useDisconnect';
import { useMedia } from 'src/hooks/useMedia';
import { supportedLngs } from 'src/i18n';
import { loadingAtom, logoutAtom } from 'src/store/auth';
import MenuItem from './MenuItem';
import SelectLang from './SelectLang';

const Footer = () => {
  const user = useUser();
  const { t, i18n } = useTranslation();
  const { signOut } = useClerk();
  const doLogout = useSetAtom(logoutAtom);
  const setLoading = useSetAtom(loadingAtom);
  const { disconnectAsync } = useDisconnect();
  const { isDesktop } = useMedia();

  const handleLogout = async () => {
    setLoading(true);
    try {
      // Disconnect clerk
      // Disconnect wallet
      await Promise.all([signOut(), disconnectAsync()]);
      // Clear cookie, localStorage
      doLogout();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <MenuItem
        Icon={<IconLogoutNew />}
        name={t('Logout')}
        comingSoon={false}
        onClick={handleLogout}
      />
      <Box
        sx={{
          height: '56px',
          padding: '8.5px 16px',
          borderTop: '1px',
          borderStyle: 'solid',
          borderColor: 'Mid-Dark Blue',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {/* Avatar */}
        <Image
          src="/images/default-avatar.png"
          width="32px"
          height="32px"
          minWidth="32px"
          borderRadius="50%"
        />
        <Box
          sx={{
            flexGrow: 1,
            margin: '0 8px 0 12px',
          }}
        >
          <Text
            variant={'Text/Small/Medium'}
            sx={{
              display: '-webkit-box',
              WebkitLineClamp: 1,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '148px',
            }}
          >
            {i18n.language === supportedLngs[0]
              ? `${user.user?.firstName} ${user.user?.lastName}`
              : `${user.user?.lastName}　${user.user?.firstName}`}
          </Text>
          <Text
            variant={'Text/Small/Normal'}
            sx={{
              display: '-webkit-box',
              WebkitLineClamp: 1,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '148px',
            }}
          >
            {user.user?.primaryEmailAddress?.emailAddress}
          </Text>
        </Box>
        {/* Lang */}
        {isDesktop && <SelectLang />}
      </Box>
    </Box>
  );
};

export default Footer;
