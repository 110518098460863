import {
  Box,
  BoxProps,
  cssVar,
  Tooltip,
  TooltipProps,
  useBoolean,
} from '@chakra-ui/react';
import React, { forwardRef } from 'react';

export interface AppTooltipProps extends TooltipProps {
  variant?: keyof typeof appTooltipStyles.variants;
  containerProps?: BoxProps;
}

const AppTooltip = forwardRef<HTMLDivElement, AppTooltipProps>(
  ({ variant = 'chartreuse', children, containerProps, ...rest }, ref) => {
    const [flag, setFlag] = useBoolean();

    return (
      <Tooltip isOpen={flag} hasArrow variant={variant} ref={ref} {...rest}>
        <Box
          display="inline-block"
          onMouseEnter={setFlag.on}
          onMouseLeave={setFlag.off}
          {...containerProps}
        >
          <React.Fragment>{children}</React.Fragment>
        </Box>
      </Tooltip>
    );
  },
);

export default AppTooltip;

export const appTooltipStyles = {
  baseStyle: {
    color: '#000224',
    background: 'white',
    padding: '8px 10px',
    borderRadius: '6px',
    [cssVar('popper-arrow-bg').variable]: 'white',
  },
  variants: {
    chartreuse: {
      color: 'Text/primary',
      fontSize: '12px',
      lineHeight: '18px',
      fontWeight: 400,
      fontFamily: '"Poppins"',
      background: 'Color Brand/Chartreuse',
      [cssVar('popper-arrow-bg').variable]: '#D8F500',
      borderRadius: '8px',
    },
  },
};
