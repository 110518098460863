import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { signedAtom } from 'src/store/auth';
import { UseAccountReturnType, useAccount as useAccountWagmi } from 'wagmi';

export type UseAccountWebReturnType = UseAccountReturnType & {
  isConnectedWeb: boolean;
};

export function useAccWeb() {
  const useAcc = useAccountWagmi();
  const signed = useAtomValue(signedAtom);

  const isConnectedWeb: boolean = useMemo(
    () => useAcc.isConnected && Boolean(signed),
    [signed, useAcc.isConnected],
  );

  return useMemo(() => {
    return {
      ...useAcc,
      isConnectedWeb,
    };
  }, [isConnectedWeb, useAcc]);
}
