import { Box, Grid, useDisclosure } from '@chakra-ui/react';
import { useSession } from '@clerk/clerk-react';
import { useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { AppText } from 'src/components';
import { accessTokenAtom, doLoginAtom } from 'src/store/auth';
import HomeFooter from './HomeFooter';
import TableRewards from './TableRewards';
import YourTravelCredits from './YourTravelCredits';
import AboutVoy from './about-voy';
import { RequestParams } from 'src/components/AppDataTableNew';
import { getUserAtom } from 'src/store/user';
import { useSearchParams } from 'react-router-dom';
import { claimReward } from 'src/api/user';
import { ModalClaimedSuccess } from './ModalClaimedSuccess';
import i18n from 'src/i18n';
import Code from 'src/utils/types/codes';
import { ModalClaimedInvalid } from './ModalClaimedInvalid';

const LIMIT = 10;

const HomeV2: React.FC = () => {
  const { t } = useTranslation();
  const userSession = useSession();
  const doLogin = useSetAtom(doLoginAtom);
  const accessToken = useAtomValue(accessTokenAtom);
  const getUser = useSetAtom(getUserAtom);
  const [searchParams, setSearchParams] = useSearchParams();
  const [message, setMessage] = useState<Code.Message[]>([]);
  const [messageFailure, setMessageFailure] = useState<string>('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenFailure,
    onOpen: onOpenFailure,
    onClose: onCloseFailure,
  } = useDisclosure();
  const claimedCode = useRef(false);

  const handleClaimReward = (promoCode: string) => {
    claimedCode.current = true;
    claimReward(promoCode)
      .then((res) => {
        const message = res.messageSuccess;
        setMessage(message);
        // Re-fresh
        setParams((prev) => ({ ...prev }));
        getUser();
        onOpen();
      })
      .catch((err) => {
        if (err?.raw?.message === 'many_request_claim') return;
        if (err?.raw?.message === 'code_claimed') {
          setMessageFailure('QR Code Already Redeemed!');
        } else setMessageFailure('Invalid QR Code!');
        onOpenFailure();
      })
      .finally(() => {
        localStorage.removeItem('promoCode');
        searchParams.delete('promoCode');
        setSearchParams(searchParams);
      });
  };

  const [params, setParams] = useState<RequestParams>({
    page: 1,
    limit: LIMIT,
    orderBy: 'DESC',
  });

  useEffect(() => {
    if (!userSession.session || accessToken) return;

    userSession.session.getToken().then((res) => {
      if (!res) return;

      doLogin(res).then(() => getUser());
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSession.session]);

  useEffect(() => {
    const promoCode =
      searchParams.get('promoCode') || localStorage.getItem('promoCode');

    if (!promoCode || claimedCode.current || !accessToken) return;

    handleClaimReward(promoCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, accessToken]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <ModalClaimedSuccess
        message={
          message?.find(
            (lpm) => lpm.lang === (i18n.language === 'en' ? 'en' : 'jp'),
          )?.message
        }
        onClose={onClose}
        isOpen={isOpen}
      />
      <ModalClaimedInvalid
        message={messageFailure ? t(messageFailure) : ''}
        onClose={onCloseFailure}
        isOpen={isOpenFailure}
      />
      <Box m="32px 0">
        <AppText
          display={['block', 'none']}
          mb="12px"
          fontSize={'10px'}
          lineHeight={'15px'}
          fontWeight={400}
          fontFamily={'Poppins'}
        >
          {t('LAUNCHING SOON')}
        </AppText>
        <Box
          sx={{
            fontSize: ['40px', '80px'],
            lineHeight: ['48px', '56px'],
            fontWeight: 800,
            display: 'inline',
            textTransform: 'uppercase',
          }}
        >
          <Box color="Color Brand/Chartreuse">
            <Trans>
              Say Hello to
              <AppText
                as={'b'}
                sx={{
                  color: '#020B20',
                  textShadow:
                    '-1px -1px 0 #D8F500, 1px -1px 0 #D8F500, -1px 1px 0 #D8F500, 1px 1px 0 #D8F500',
                }}
              >
                travelfi
              </AppText>
            </Trans>
          </Box>
        </Box>
        <AppText
          display={['none', 'block']}
          mt="24px"
          variant={'Text/Large/Normal'}
        >
          {t('LAUNCHING SOON')}
        </AppText>
      </Box>
      {/* Welcome */}
      <Box m={['64px 0 48px', '64px 0 32px']}>
        <AppText
          sx={{
            fontSize: ['28px', '32px'],
            lineHeight: '33px',
            fontWeight: 600,
            textTransform: 'uppercase',
          }}
        >
          {t('Welcome to Voy!')}
        </AppText>
        <AppText mt="8px" variant={'Text/Medium/Normal'}>
          {t(
            "You're now part of the TravelFi revolution. Our platform will be launching soon, but here’s how you can get started:",
          )}
        </AppText>
      </Box>
      {/* Body */}
      <Grid
        templateAreas={[
          `"card"
            "nav"
            "table"`,
          `"card nav"
            "table nav"`,
        ]}
        gridTemplateRows={['auto auto auto', 'auto 1fr']}
        gridTemplateColumns={['100%', '748px 1fr']}
        gap="20px"
        flexGrow={1}
        mb={['48px', '32px']}
      >
        <AboutVoy setParams={setParams} />
        <YourTravelCredits setParams={setParams} />
        <TableRewards params={params} setParams={setParams} />
      </Grid>
      {/* Footer */}
      <HomeFooter />
    </Box>
  );
};

export default HomeV2;
