import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider } from 'react-router-dom';

import 'src/styles/global.scss';
import { WagmiProvider } from 'wagmi';
import './App.css';
import { config } from './config';
import { SocketProvider } from './hooks/socket';
import './i18n';
import RouterCustom from './routers/routes';
import themes from './themes';
import WalletProvider from './WalletProvider';
import 'moment/locale/ja';
import moment from 'moment-timezone';

moment.tz.setDefault('UTC');
const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <WagmiProvider config={config}>
        <WalletProvider>
          <ChakraProvider theme={themes}>
            <SocketProvider>
              <ColorModeScript initialColorMode="dark" />
              <RouterProvider router={RouterCustom()} />
            </SocketProvider>
          </ChakraProvider>
        </WalletProvider>
      </WagmiProvider>
    </QueryClientProvider>
  );
}

export default App;
