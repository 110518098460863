import { BrowserProvider, Contract } from 'ethers';
import { useContext, useEffect, useState } from 'react';
import { ProviderContext } from 'src/WalletProvider';
import { formatBigNumber } from 'src/utils/utils-format';
import contractToken from '../abi/contract_token.json';
import { getTokenAddress } from 'src/utils/utils-token';

type ReturnType = {
  chainId: number;
  getBalance: (address: string, symbol: string) => Promise<number | string>;
};

const useWallet = (): ReturnType => {
  const [chainId, setChainId] = useState<number>(NaN);

  const { metamaskProvider: provider } = useContext(ProviderContext);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      const getChainId = async () => {
        const network = await provider?.request({ method: 'eth_chainId' });
        setChainId(Number(network));
      };
      getChainId();
    }, 1000);

    return () => clearTimeout(timeOut);
  }, [provider]);

  useEffect(() => {
    const chainChanged = async (chainId: string) => {
      setChainId(Number(chainId));
    };

    if (provider) {
      provider.on('chainChanged', chainChanged);
    }

    return () => {
      provider?.removeListener('chainChanged', chainChanged);
    };
  }, [provider]);

  const getBalance = async (address: string, symbol: string) => {
    const providerContract = new BrowserProvider(provider);

    const token = getTokenAddress(chainId, symbol);

    if (!token) return 0;

    const readContract = new Contract(token, contractToken, providerContract);
    const balance = await readContract.balanceOf(address);
    return formatBigNumber(balance);
  };

  return {
    chainId,
    getBalance,
  };
};

export default useWallet;
