import { atom } from 'jotai';
import { focusAtom } from 'jotai-optics';
import _ from 'lodash';
import { loginClerk } from 'src/api/login';
import {
  ADDRESS_ACCOUNT_KEY,
  PERMISSON_KEY,
  ROLE_ACCOUNT_KEY,
  SIGNED_KEY,
  USER_JWT_KEY,
} from 'src/utils/constants/constants';
import { role, token } from 'src/utils/helpers/etc';
import { clientCookie } from 'src/utils/utils-helper';

export interface AuthAtomType {
  access_token?: string;
  role_account?: number;
  logged: boolean;
  msg_error?: boolean;
  address?: string;
  permission?: string;
  signed: boolean;
  loading: boolean;
}

export const getAuthInit = () => {
  const accessToken = token || '';
  const roleAccount = role ? +role : NaN;
  const address = clientCookie.get(ADDRESS_ACCOUNT_KEY) || '';
  const permission = clientCookie.get(PERMISSON_KEY) || '';
  const signed = clientCookie.get(SIGNED_KEY) === 'true';

  return {
    access_token: accessToken,
    role_account: roleAccount,
    logged: Boolean(accessToken),
    msg_error: false,
    address,
    permission,
    signed: signed,
    loading: false,
  };
};
export const authAtom = atom<AuthAtomType>(getAuthInit());

const _accessTokenAtom = focusAtom(authAtom, (opt) => opt.prop('access_token'));
const _roleAccountAtom = focusAtom(authAtom, (opt) => opt.prop('role_account'));
const _msgErrorAtom = focusAtom(authAtom, (opt) => opt.prop('msg_error'));
const _addressAtom = focusAtom(authAtom, (opt) => opt.prop('address'));
const _permissionAtom = focusAtom(authAtom, (opt) => opt.prop('permission'));
const _signedAtom = focusAtom(authAtom, (opt) => opt.prop('signed'));
const _loadingAtom = focusAtom(authAtom, (opt) => opt.prop('loading'));

export const loggedAtom = focusAtom(authAtom, (opt) => opt.prop('logged'));

export const doLoginAtom = atom(null, async (get, set, token: string) => {
  const res = await loginClerk(token);

  const permissions = res?.user?.permissions || [];
  const maxAge = res?.expiresIn ? +res?.expiresIn / 1000 : undefined;

  set(accessTokenAtom, res.access_token, { maxAge });
  set(roleAccountAtom, res.user.role, { maxAge });
  set(addressAtom, res.user.address, { maxAge });
  set(permissionAtom, _.join(permissions, ', '), { maxAge });
  return res;
});

export const logoutAtom = atom(null, (get, set) => {
  try {
    clientCookie.destroy(USER_JWT_KEY);
    clientCookie.destroy(ROLE_ACCOUNT_KEY);
    clientCookie.destroy(ADDRESS_ACCOUNT_KEY);
    clientCookie.destroy(PERMISSON_KEY);
    clientCookie.destroy(SIGNED_KEY);

    set(authAtom, (prev) => ({
      ...prev,
      access_token: undefined,
      role_account: undefined,
      address: undefined,
      signed: false,
      logged: false,
    }));
    return true;
  } catch (error) {
    return false;
  }
});

export const roleAccountAtom = atom(
  (get) => get(_roleAccountAtom),
  (get, set, role: number, opts?: any) => {
    set(_roleAccountAtom, role);
    return clientCookie.set(ROLE_ACCOUNT_KEY, role.toString(), opts);
  },
);

export const msgErrorAtom = atom(
  (get) => get(_msgErrorAtom),
  (get, set, msg: boolean) => {
    set(_msgErrorAtom, msg);
  },
);

export const addressAtom = atom(
  (get) => get(_addressAtom),
  (get, set, address: string, opts?: any) => {
    set(_addressAtom, address);
    return clientCookie.set(ADDRESS_ACCOUNT_KEY, address.toString(), opts);
  },
);

export const accessTokenAtom = atom(
  (get) => get(_accessTokenAtom),
  (get, set, token: string, otps?: any) => {
    set(_accessTokenAtom, token);

    return clientCookie.set(USER_JWT_KEY, token, otps);
  },
);

export const permissionAtom = atom(
  (get) => get(_permissionAtom),
  (get, set, permission: string, opts?: any) => {
    set(_permissionAtom, permission);
    return clientCookie.set(PERMISSON_KEY, permission, opts);
  },
);

export const signedAtom = atom(
  (get) => get(_signedAtom),
  (get, set, signed: boolean, opts?: any) => {
    set(_signedAtom, signed);
    return clientCookie.set(SIGNED_KEY, String(signed), opts);
  },
);

export const loadingAtom = atom(
  (get) => get(_loadingAtom),
  (get, set, loading: boolean) => {
    set(_loadingAtom, loading);
  },
);
