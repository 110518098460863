import React, { useMemo, useEffect, useRef, useState, ReactNode } from 'react';
import { Box, Flex, Input, LayoutProps, Spinner, Text } from '@chakra-ui/react';
import 'src/styles/components/AppSelect.scss';
import SimpleReactValidator from 'simple-react-validator';
import { useForceRender } from 'src/hooks/useForceRender';
import { ArrowDown } from 'src/assets/icons';

export interface IOption {
  value: string;
  label: string;
  icon?: string | JSX.Element;
}

interface ValidatorProps {
  validator: SimpleReactValidator;
  name: string;
  rule: string | Array<string | { [key: string]: unknown }>;
  options?: { [key: string]: unknown };
}
interface IAppSelectPops {
  options: IOption[];
  value: string;
  className?: string;
  width?: string;
  size?: 'small' | 'medium' | 'large';
  hiddenLabelDefault?: boolean;
  onChange: (value: string) => void;
  disabled?: boolean;
  isLoading?: boolean;
  zIndex?: number;
  customItem?: (optionItem: any) => ReactNode;
  sxWrapper?: LayoutProps;
  validate?: ValidatorProps;
  readOnly?: boolean;
  hiddenErrorText?: boolean;
  fontWeight?: string;
  emptyLabel?: string;
  searchAble?: boolean;
}

const AppSelectWithSearch: React.FC<IAppSelectPops> = ({
  options,
  value,
  onChange,
  width,
  size = 'small',
  hiddenLabelDefault = false,
  className,
  disabled,
  zIndex,
  customItem,
  sxWrapper,
  isLoading,
  hiddenErrorText = false,
  validate,
  readOnly,
  fontWeight = 500,
  emptyLabel = '--Select--',
  searchAble,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const forceRender = useForceRender();
  const ref = useRef<any>(null);

  const optionSelected = useMemo(
    () => options.find((item: IOption) => item.value === value),
    [value, options],
  );

  const [searchText, setSearchText] = useState(optionSelected?.label ?? '');

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current?.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const onBlur = () => {
    validate?.validator.showMessageFor(validate.name);
    forceRender();
  };

  const generateIcon = (icon: string | JSX.Element) => {
    if (typeof icon === 'string') {
      return <Box className={`${icon} icon`} />;
    }
    return <Box className="icon">{icon}</Box>;
  };

  const filteredOptions = useMemo(() => {
    if (!searchAble || !searchText || searchText === emptyLabel) return options;

    return options.filter((option) =>
      option.label.toLowerCase().includes(searchText.toLowerCase()),
    );
  }, [searchAble, searchText, emptyLabel, options]);

  return (
    <Box
      className={`app-select ${size} ${className}`}
      width={width}
      ref={ref}
      zIndex={zIndex}
      userSelect={'none'}
    >
      <Flex
        className={`app-select__btn-select ${
          disabled ? 'app-select__btn-select--disabled' : ''
        }`}
        onClick={() => {
          !disabled && setOpen(true);
        }}
        position="relative"
        {...sxWrapper}
      >
        {customItem && optionSelected ? (
          customItem(optionSelected)
        ) : (
          <React.Fragment>
            {searchAble ? (
              <Flex alignItems={'center'}>
                <Input
                  value={isFocus ? searchText : optionSelected?.label ?? ''}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                    setOpen(true);
                  }}
                  onFocus={() => {
                    setIsFocus(true);
                    setOpen(true);
                  }}
                  onBlur={() => setIsFocus(false)}
                  placeholder={emptyLabel}
                  border="none!important"
                  outline="none!important"
                  boxShadow="none!important"
                  position="absolute"
                  width="100%"
                  height="100%"
                  padding="0 40px 0 16px"
                  color="#0A0B0B"
                />
              </Flex>
            ) : (
              <Flex alignItems={'center'}>
                {optionSelected?.icon && generateIcon(optionSelected?.icon)}
                {hiddenLabelDefault ? (
                  <Text fontWeight={fontWeight}>
                    {optionSelected?.label ?? ''}
                  </Text>
                ) : (
                  <Text fontWeight={fontWeight}>
                    {optionSelected?.label ?? emptyLabel}
                  </Text>
                )}
              </Flex>
            )}
          </React.Fragment>
        )}
        <Box
          position="absolute"
          right="12px"
          top="50%"
          transform="translateY(-50%)"
          pointerEvents="none"
        >
          {isLoading ? <Spinner /> : <ArrowDown color="#3E4043" />}
        </Box>
      </Flex>

      {open && (
        <Box
          className={'app-select__menu'}
          boxShadow={'0px 10px 40px rgba(125, 143, 179, 0.2)'}
        >
          {searchText && filteredOptions.length === 0 ? (
            <Flex justifyContent="center">No results found</Flex>
          ) : (
            filteredOptions.map((option: IOption, index: number) => {
              return (
                <Flex
                  key={index}
                  className={`app-select__menu-item ${
                    value === option.value ? 'selected' : ''
                  }`}
                  onBlur={onBlur}
                  onClick={() => {
                    onChange(option.value);
                    setSearchText(option.label);
                    setOpen(false);
                  }}
                >
                  {option?.icon && generateIcon(option?.icon)}
                  {customItem ? (
                    customItem(option)
                  ) : (
                    <Text fontWeight={fontWeight}>{option.label}</Text>
                  )}
                </Flex>
              );
            })
          )}
        </Box>
      )}
      <Box>
        {!hiddenErrorText &&
          validate &&
          !readOnly &&
          validate.validator.message(
            validate.name,
            value ? value : ref ? (ref as any).current?.value : '',
            validate.rule,
            validate.options,
          )}
      </Box>
    </Box>
  );
};

export default AppSelectWithSearch;
