import { Box, Grid, Image } from '@chakra-ui/react';
import React from 'react';
import BasePage from '../layouts/BasePage';
import VestingBalance from '../vesting/VestingBalance';
import Histories from './Histories';

const MatchHistories: React.FC = () => {
  return (
    <BasePage>
      <Box maxWidth="1280px" width="100vw" padding="60px 20px 36px 20px">
        <Grid
          templateColumns="448px 1fr"
          width="100%"
          gap="30px"
          marginBottom="60px"
        >
          <VestingBalance />
          <Image
            src="/images/merchandising-content.png"
            alt="merchandising content"
            width="100%"
            height="100%"
            objectFit="cover"
            borderRadius="16px"
          />
        </Grid>
        <Histories />
      </Box>
    </BasePage>
  );
};

export default MatchHistories;
