import { useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { addressAtom } from 'src/store/auth';
import useWallet from './useWallet';
import { NAME_STAKE_COIN } from 'src/utils/common';
import { coinBalanceAtom } from 'src/store/user';

type ReturnType = {
  balanceCoin: number | string;
  isLoading: boolean;
  fetchBalance: () => void;
};

const useCoinBalance = (
  needCallFetch = true,
): ReturnType => {
  const address = useAtomValue(addressAtom);
  const setCoinBalance = useSetAtom(coinBalanceAtom);
  const coinBalance = useAtomValue(coinBalanceAtom);
  const { getBalance, chainId } = useWallet();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchBalance = async () => {
    try {
      if (!chainId) return;
      setIsLoading(true);
      const balance = await getBalance(
        address || '',
        NAME_STAKE_COIN.VOY,
      );
      setCoinBalance(balance);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (!needCallFetch) return;
    setCoinBalance('');
    const timeOut = setTimeout(() => {
      fetchBalance();
    }, 1000);

    return () => clearTimeout(timeOut);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId]);

  return {
    balanceCoin: coinBalance,
    isLoading,
    fetchBalance,
  };
};

export default useCoinBalance;
