import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  TableCellProps,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { IconRefresh } from 'src/assets/icons';
import { refreshBalance } from 'src/api/voy-wallet';
import { getUserAtom, userAtom } from 'src/store/user';
import { useAtomValue, useSetAtom } from 'jotai';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

interface HeaderProps {
  getLoyaltyBalances: () => void;
  disabled: boolean;
}

const CustomTh: React.FC<React.PropsWithChildren<{}> & TableCellProps> = ({
  children,
  ...rest
}) => {
  return (
    <Th
      fontFamily="Poppins"
      fontWeight="400"
      fontSize="14px"
      lineHeight="20px"
      textTransform="none"
      color="#7C7E82"
      borderColor="#D7DADC"
      padding="18px 24px"
      {...rest}
    >
      {children}
    </Th>
  );
};

const TableHeader: React.FC<HeaderProps> = ({
  getLoyaltyBalances,
  disabled,
}) => {
  const getUser = useSetAtom(getUserAtom);
  const user = useAtomValue(userAtom);
  const [remaining, setRemaining] = useState<string>();
  const { t } = useTranslation();

  const handleRefresh = () => {
    if (remaining || disabled) return;
    refreshBalance().then(() => {
      getUser();
      getLoyaltyBalances();
    });
  };

  useEffect(() => {
    const countTime = () => {
      if (!user?.refreshAwBalanceAt) {
        setRemaining(undefined);
        return;
      }
      const canRefreshAt =
        new Date(user.refreshAwBalanceAt).getTime() + 5 * 60 * 1000;

      if (canRefreshAt < Date.now()) {
        setRemaining(undefined);
        return;
      }

      const timeRemaining = moment.duration(
        moment(canRefreshAt).diff(moment()),
      );

      const minutes = String(Math.floor(timeRemaining.asMinutes())).padStart(
        2,
        '0',
      );
      const seconds = String(timeRemaining.seconds()).padStart(2, '0');
      setRemaining(`${minutes}:${seconds}`);
    };

    const itv = setInterval(countTime, 1000);

    return () => clearInterval(itv);
  }, [user?.refreshAwBalanceAt]);

  return (
    <Thead>
      <Tr>
        <CustomTh>{t('My Loyalty Program')}</CustomTh>
        <CustomTh>{t('Balance')}</CustomTh>
        <CustomTh>{t('Level')}</CustomTh>
        <CustomTh>{t('Expiration')}</CustomTh>
        <CustomTh>{t('Balance Updated')}</CustomTh>
        <CustomTh padding="20px">
          <Flex
            alignItems="center"
            gap="8px"
            color="#3182FF"
            fontFamily="'Exo 2'"
            fontWeight="700"
            textTransform="uppercase"
            width="183px!important"
            cursor={remaining || disabled ? 'default' : 'pointer'}
            userSelect="none"
            role="button"
            onClick={handleRefresh}
          >
            {remaining ? (
              <Text>
                {t('Refresh available in {{remaining}}', { remaining })}
              </Text>
            ) : (
              <React.Fragment>
                <Box minWidth="24px">
                  <IconRefresh />
                </Box>
                <Text>{t('Refresh All Balance')}</Text>
              </React.Fragment>
            )}
          </Flex>
        </CustomTh>
      </Tr>
    </Thead>
  );
};

export default TableHeader;
