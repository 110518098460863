import { RequestParams } from 'src/components/AppDataTable';
import { request } from 'src/utils/request';
import Code from 'src/utils/types/codes';

export const listCodes = async (params: RequestParams) => {
  const { state, status, search } = params;
  const response = await request.get<Code.CodesResponse>('/admin/codes', {
    params: {
      ...params,
      keyword: search ? search : undefined,
      state: state === 'all' || !state ? undefined : state,
      status: status === 'all' || !status ? undefined : status,
    },
  });
  return {
    page: response.data.meta.currentPage,
    limit: response.data.meta.itemsPerPage,
    list: response.data.items,
    count: response.data.meta.totalItems,
  };
};

export const getCodeById = async (id: number) => {
  const response = await request.get<Code.Info>(`/admin/codes/${id}`);

  return response.data;
};

export const createCode = async (code: Code.Info) => {
  const response = await request.post<boolean>('/admin/codes', code);

  return response.data;
};

export const updateCode = async (code: Code.Info) => {
  const response = await request.patch<boolean>(
    `/admin/codes/${code.id}`,
    code,
  );

  return response.data;
};

export const codesPublic = async (code: string) => {
  const response = await request.get<boolean>(`/admin/codes/public`, {
    params: { code },
  });

  return response.data;
};
