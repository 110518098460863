import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalProps,
} from '@chakra-ui/react';
import confetti from 'canvas-confetti';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AppText } from 'src/components';
import hexToRgba from 'hex-to-rgba';

export const ModalClaimedSuccess = ({
  message,
  ...props
}: { message?: string } & Omit<ModalProps, 'children'>) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!props.isOpen) return;

    // Show confetti
    var count = 250;
    var defaults = {
      origin: { y: 0.8 },
    };

    function fire(particleRatio: number, opts: object) {
      confetti({
        ...defaults,
        ...opts,
        particleCount: Math.floor(count * particleRatio),
      });
    }

    fire(0.25, {
      spread: 26,
      startVelocity: 55,
    });
    fire(0.2, {
      spread: 60,
      scalar: 1.5,
    });
    fire(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 1.5,
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 55,
      decay: 0.92,
      scalar: 2,
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  }, [props.isOpen]);

  return (
    <Modal isCentered {...props}>
      <ModalOverlay bg={hexToRgba('#020B20', 0.6)} />
      <ModalContent
        sx={{
          background: 'Color Brand/Cetacean Blue',
          minW: ['100%', '560px'],
          maxW: ['100%', '560px'],
          minH: '264px',
          borderRadius: '20px',
        }}
      >
        <ModalCloseButton color={'white'} />
        <ModalBody
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            gap: '20px',
            p: '32px 20px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '140px',
            }}
          >
            <Box
              sx={{
                transform: 'scale(5)',
              }}
            >
              🎉
            </Box>
          </Box>
          <AppText color="white" variant="Text/Large/Bold">
            {message ? message : t('Promo code successfully claimed!')}
          </AppText>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
