import { Box, Flex, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { AppButton } from 'src/components';
import ConnectLoyaltyProgram from './popup/ConnectLoyaltyProgram';
import AppSelectWithSearch from 'src/components/AppSelectWithSearch';
// import { useLocation } from 'react-router-dom';
import { fetchLoyaltyPrograms, loginLoyaltyProgram } from 'src/api/voy-wallet';
import AwardWallet from 'src/utils/types/award-wallet';
import { IconError } from 'src/assets/icons';
import LoginSuccessfully from './popup/LoginSuccessfully';
import { toastSuccess } from 'src/utils/utils-notify';
import { useTranslation } from 'react-i18next';

interface AddLoyaltyProgramsProps {
  loyaltyPrograms: AwardWallet.Program[];
  setLoyaltyPrograms: React.Dispatch<
    React.SetStateAction<AwardWallet.Program[]>
  >;
  getLoyaltyBalances: () => void;
}

const AddLoyaltyPrograms: React.FC<AddLoyaltyProgramsProps> = ({
  loyaltyPrograms,
  setLoyaltyPrograms,
  getLoyaltyBalances,
}) => {
  // const location = useLocation();
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState<string>('');
  const [isOpenConnectPopup, setIsOpenConnectPopup] = useState(false);
  const [isUnselectedProgram, setIsUnselectedProgram] = useState(false);
  const [isOpenLoginSuccessfully, setIsOpenLoginSuccessfully] = useState(false);

  useEffect(() => {
    fetchLoyaltyPrograms().then((res) => {
      setLoyaltyPrograms(res);

      // () =>
      //   res.map((program) => ({
      //     value: program.id.toString(),
      //     label: program.name,
      //   })
    });
    // const query = new URLSearchParams(location.search);
    // const code = query.get('code');

    // if (!code) return;

    // userConnectAward(code).then(() => getLoyaltyBalances());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnConnect = () => {
    if (!selectedValue) {
      setIsUnselectedProgram(true);
      return;
    }
    const selectedProgram = loyaltyPrograms.find(
      (lp) => lp.id === +selectedValue,
    );
    if (!selectedProgram?.code) {
      loginLoyaltyProgram({
        loyaltyProgramId: selectedProgram!.id,
      }).then(() => {
        getLoyaltyBalances();
        toastSuccess({ message: t('Loyalty Program connected successfully!') });
      });
      return;
    }
    if (selectedValue) setIsOpenConnectPopup(true);
  };

  return (
    <Box
      borderRadius="16px"
      backgroundColor="#FFFFFF"
      boxShadow="0 4px 180px rgba(53, 9, 72, 0.1)"
      padding="32px"
    >
      <Text
        fontFamily="'Exo 2'"
        fontWeight={700}
        fontSize={24}
        lineHeight="30px"
      >
        {/* <Text fontFamily="Poppins" fontWeight={700} fontSize={24} lineHeight="30px"> */}
        {t('Add Your Loyalty Programs')}
      </Text>
      <Flex gap="16px" marginTop="24px">
        <Flex flexDirection="column">
          <AppSelectWithSearch
            value={selectedValue}
            size="small"
            options={loyaltyPrograms.map((program) => ({
              value: program.id.toString(),
              label: program.name,
            }))}
            onChange={(value) => {
              setIsUnselectedProgram(false);
              setSelectedValue(value);
            }}
            width="300px"
            emptyLabel={t('Entry Box for Loyalty Program')}
            fontWeight="400"
            className={`loyalty-program-select ${
              selectedValue === '' && 'empty'
            }`}
            searchAble
          />
          {isUnselectedProgram && (
            <Flex alignItems="center" gap="4px" marginTop="13px">
              <IconError />
              <Text
                fontFamily="Poppins"
                fontWeight="500"
                fontSize={14}
                lineHeight="20px"
                color="#FF4C3F"
              >
                {t('Please choose a Loyalty Program')}
              </Text>
            </Flex>
          )}
        </Flex>
        <AppButton
          textTransform="uppercase"
          fontSize="16px"
          lineHeight="20px"
          onClick={handleOnConnect}
        >
          {t('Connect for Balance Update')}
        </AppButton>
      </Flex>
      <ConnectLoyaltyProgram
        selectedProgram={loyaltyPrograms.find(
          (lp) => lp.id === parseInt(selectedValue),
        )}
        isOpen={isOpenConnectPopup}
        setIsOpen={setIsOpenConnectPopup}
        getLoyaltyBalances={getLoyaltyBalances}
        setIsOpenLoginSuccessfully={setIsOpenLoginSuccessfully}
      />
      <LoginSuccessfully
        isOpen={isOpenLoginSuccessfully}
        setIsOpen={setIsOpenLoginSuccessfully}
      />
    </Box>
  );
};

export default AddLoyaltyPrograms;
