import { Box, Button, Grid, GridItem } from '@chakra-ui/react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { IconInsta, IconLinkedIn, IconXapp } from 'src/assets/icons';
import { AppText } from 'src/components';

const HomeFooter: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Grid
      templateAreas={[
        `"what"
        "our"`,
        `"what our"`,
      ]}
      gridTemplateColumns={['1fr', '748px 1fr']}
      gap={['64px', '20px']}
      mb={'64px'}
    >
      <GridItem area={'what'}>
        <Box>
          <AppText
            m={['0 0 20px', '24px 0 32px']}
            sx={{
              fontFamily: '"Exo 2"',
              fontSize: '32px',
              lineHeight: '32px',
              fontWeight: 600,
            }}
            textTransform={'uppercase'}
          >
            {t(`What is Voy?`)}
          </AppText>
          <AppText variant={'Text/Medium/Normal'}>
            {t(
              'Voy is pioneering TravelFi, revolutionizing how you earn and use travel rewards.',
            )}
            <br />
            {t(
              'We unify your scattered loyalty points, allow you to amplify rewards through crypto staking, and offer exclusive discounts on amazing travel experiences.',
            )}
            <br />
            <br />
            {t(
              'With Voy, turn your idle points and crypto yields into unforgettable journeys.',
            )}
          </AppText>
        </Box>
      </GridItem>
      <GridItem area={'our'}>
        <Box
          sx={{
            background: ['none', 'Background color/tertiary'],
            p: ['0px', '24px'],
            borderRadius: '15px',
            display: 'flex',
            flexDirection: 'column',
            gap: ['48px', '32px'],
          }}
        >
          <AppText
            sx={{
              fontFamily: '"Exo 2"',
              fontSize: '32px',
              lineHeight: '32px',
              fontWeight: 600,
            }}
            textTransform={'uppercase'}
          >
            {t('Join our Community')}
          </AppText>
          <Box
            sx={{
              display: 'flex',
              flexDir: 'row',
              justifyContent: 'space-between',
            }}
          >
            {[
              {
                icon: <IconXapp />,
                link: 'https://twitter.com/intent/follow?screen_name=voytravelfi',
              },
              {
                icon: <IconLinkedIn />,
                link: 'https://www.linkedin.com/company/voytravelfi',
              },
              {
                icon: <IconInsta />,
                link: 'https://www.instagram.com/voytravelfi',
              },
            ].map((i) => (
              <Button
                key={i.link}
                p="20px"
                w="64px"
                h="64px"
                borderRadius={'100px'}
                background={'white'}
                onClick={() => {
                  window.open(i.link, '_blank');
                }}
              >
                {i.icon}
              </Button>
            ))}
          </Box>
        </Box>
      </GridItem>
    </Grid>
  );
};

export default HomeFooter;
