import { Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppButton } from 'src/components';
import 'src/styles/pages/NotFoundPage.scss';

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Flex className="container">
      <Text className="title">404</Text>
      <Text className="content">{t('This page is lost.')}</Text>
      <Text className="desc">
        {t(
          `We've explored deep and wide, but we can't find the page you were looking for.`,
        )}
      </Text>
      <AppButton onClick={() => navigate(-1)}>{t('Go back')}</AppButton>
    </Flex>
  );
};

export default NotFoundPage;
