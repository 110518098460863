import { Box, Flex, Grid, Spinner, Text } from '@chakra-ui/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getCodeById } from 'src/api/admin-codes';
import { AppButton } from 'src/components';
import { Paths, TYPE_CODE } from 'src/utils/common';
import Code from 'src/utils/types/codes';
import { formattedNumberWithDecimal } from 'src/utils/utils-format';

const AdminCodesDetailPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const query = new URLSearchParams(useLocation().search);
  const [code, setCode] = useState<Code.Info>();

  useEffect(() => {
    const codeId = query.get('id');
    if (!codeId || !Number(codeId)) return;
    getCodeById(Number(codeId)).then((res) => setCode(res));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box padding="24px" overflow="auto" fontFamily="Poppins" width="100%">
      <Flex
        flexDirection="row"
        alignItems="center"
        marginBottom="24px"
        gap="16px"
        width="fit-content"
      >
        <Box
          __css={{ 'svg path': { stroke: '#393D49' } }}
          cursor="pointer"
          onClick={() => navigate(Paths.ADMIN_CODES_MANAGEMENT)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="40"
            viewBox="0 0 16 40"
            fill="none"
          >
            <path
              d="M10 30L2.44444 22L10 14"
              stroke="#393D49"
              strokeWidth="2.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Box>
        <Text
          fontFamily="'Exo 2'"
          fontWeight="700"
          fontSize="32px"
          lineHeight="40px"
          color="#041336"
        >
          View Code
        </Text>
      </Flex>
      {!code ? (
        <Flex
          width="572px"
          height="500px"
          alignItems="center"
          justifyContent="center"
          gap="16px"
          padding="20px 24px"
          borderRadius="16px"
          backgroundColor="#FFFFFF"
        >
          <Spinner />
        </Flex>
      ) : (
        <Flex flexDirection="column" width="572px">
          <Grid
            gridTemplateColumns="250px 1fr"
            gap="24px"
            padding="20px 24px"
            borderRadius="16px"
            backgroundColor="#FFFFFF"
            fontSize="16px"
            lineHeight="22px"
          >
            <Flex flexDirection="column" gap="8px" gridColumn="1/span 2">
              <Text color="#041336" fontWeight="600">
                {t('Code Name')}
              </Text>
              <Text color="#222325" fontWeight="400">
                {code.name}
              </Text>
            </Flex>
            <Flex flexDirection="column" gap="8px" gridColumn="1/span 2">
              <Text color="#041336" fontWeight="600">
                {t('Promo Code')}
              </Text>
              <Text color="#222325" fontWeight="400">
                {code.code}
              </Text>
            </Flex>
            <Grid gridTemplateColumns="250px 1fr" gap="24px">
              <Flex flexDirection="column" gap="8px">
                <Text color="#222325" fontWeight="600">
                  {t('Start Date')} (UTC+0)
                </Text>
                <Text color="#222325" fontWeight="400" whiteSpace="nowrap">
                  {code.startTime
                    ? moment(code.startTime).format('MMM DD, YYYY HH:mm:ss')
                    : '-'}
                </Text>
              </Flex>
              <Flex flexDirection="column" gap="8px">
                <Text color="#041336" fontWeight="600">
                  {t('End Date')} (UTC+0)
                </Text>
                <Text color="#222325" fontWeight="400" whiteSpace="nowrap">
                  {code.endTime
                    ? moment(code.endTime).format('MMM DD, YYYY HH:mm:ss')
                    : '-'}
                </Text>
              </Flex>
            </Grid>
            <Flex flexDirection="column" gap="8px" gridColumn="1/span 2">
              <Text color="#041336" fontWeight="600">
                {t('Give Away Amount')}
              </Text>
              <Text color="#222325" fontWeight="400">
                {formattedNumberWithDecimal(code.amount, 18)}
              </Text>
            </Flex>
            <Flex flexDirection="column" gap="8px" gridColumn="1/span 2">
              <Text color="#041336" fontWeight="600">
                {t('Successful Redemption Message - EN')}
              </Text>
              <Text
                as="pre"
                whiteSpace="pre-wrap"
                fontFamily="Poppins"
                color="#222325"
                fontWeight="400"
              >
                {code.messageSuccess.find((ms) => ms.lang === 'en')?.message}
              </Text>
            </Flex>
            <Flex flexDirection="column" gap="8px" gridColumn="1/span 2">
              <Text color="#041336" fontWeight="600">
                {t('Successful Redemption Message - JP')}
              </Text>
              <Text
                as="pre"
                whiteSpace="pre-wrap"
                fontFamily="Poppins"
                color="#222325"
                fontWeight="400"
              >
                {code.messageSuccess.find((ms) => ms.lang === 'jp')?.message}
              </Text>
            </Flex>

            {/* Redeem Limit */}
            <Flex flexDirection="column" gap="8px" gridColumn="1/span 2">
              <Text color="#041336" fontWeight="600">
                {t('Redeem Limit')}
              </Text>
              <Text color="#222325" fontWeight="400">
                {code.redeemLimit}
              </Text>
            </Flex>
            {code.type === TYPE_CODE.QR_CODE && (
              <>
                <Flex flexDirection="column" gap="8px" gridColumn="1/span 2">
                  <Text color="#041336" fontWeight="600">
                    Landing Page URL
                  </Text>
                  <Link
                    to={`${code.redirectUrl?.includes('http') ? '' : '//'}${
                      code.redirectUrl || ''
                    }`}
                  >
                    <Text color="#3182FF" fontWeight="400">
                      {code.redirectUrl}
                    </Text>
                  </Link>
                </Flex>
                <Flex flexDirection="column" gap="8px" gridColumn="1/span 2">
                  <Text color="#041336" fontWeight="600">
                    QR Code URL
                  </Text>
                  <Link
                    to={`${code.link?.includes('http') ? '' : '//'}${
                      code.link || ''
                    }`}
                  >
                    <Text color="#3182FF" fontWeight="400">
                      {code.link}
                    </Text>
                  </Link>
                </Flex>
              </>
            )}
            <Flex flexDirection="column" gap="8px">
              <Text color="#041336" fontWeight="600">
                {t('Code State')}
              </Text>
              <Text color="#222325" fontWeight="400">
                {code.status ? t('Disabled') : t('Enabled')}
              </Text>
            </Flex>
          </Grid>
          <Flex
            fontFamily="'Exo 2'"
            fontWeight="700"
            fontSize="16px"
            lineHeight="22px"
            justifyContent="flex-end"
            marginTop="24px"
          >
            <AppButton
              width="160px"
              textTransform="uppercase"
              onClick={() =>
                navigate(`${Paths.ADMIN_CODES_EDIT}?id=${code.id}`)
              }
            >
              {t('Edit')}
            </AppButton>
          </Flex>
        </Flex>
      )}
    </Box>
  );
};
export default AdminCodesDetailPage;
