import React from 'react';
import { TableCellProps, Th, Thead, Tr } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const CustomTh: React.FC<React.PropsWithChildren<{}> & TableCellProps> = ({
  children,
  ...rest
}) => {
  return (
    <Th
      fontFamily="Poppins"
      fontWeight="400"
      fontSize="14px"
      lineHeight="20px"
      textTransform="none"
      color="#7C7E82"
      borderColor="#D7DADC"
      padding="18px 24px"
      {...rest}
    >
      {children}
    </Th>
  );
};

const TableHeader: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Thead>
      <Tr>
        <CustomTh>{t('Loyalty Program')}</CustomTh>
        <CustomTh>{t('Match Amount')}</CustomTh>
        <CustomTh>{t('Travel Credits Received')}</CustomTh>
        <CustomTh>{t('Match Date')}</CustomTh>
      </Tr>
    </Thead>
  );
};

export default TableHeader;
