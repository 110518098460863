import { Box, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPaginate, { ReactPaginateProps } from 'react-paginate';
import 'src/styles/components/AppPaginationNew.scss';

const AppPaginationNew: FC<ReactPaginateProps> = (props) => {
  const { t } = useTranslation();
  return (
    <Box className="app-pagination-box" sx={{ mt: '32px' }}>
      <ReactPaginate
        breakLabel="..."
        pageRangeDisplayed={2}
        marginPagesDisplayed={2}
        previousLabel={
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="0.5" width="24" height="24" rx="12" fill="#D8F500" />
              <path
                d="M13.7929 6.29297L8.08594 12L13.7929 17.707L15.2069 16.293L10.9139 12L15.2069 7.70697L13.7929 6.29297Z"
                fill="#020B20"
              />
            </svg>
            <Text variant={'Text/Small/Normal'}>{t('Prev')}</Text>
          </Box>
        }
        nextLabel={
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
            <Text variant={'Text/Small/Normal'}>{t('Next')}</Text>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="24" height="24" rx="12" fill="#D8F500" />
              <path
                d="M9.70703 16.9496L15.414 11.2426L9.70703 5.53564L8.29303 6.94964L12.586 11.2426L8.29303 15.5356L9.70703 16.9496Z"
                fill="black"
              />
            </svg>
          </Box>
        }
        className="app-pagination-new"
        {...props}
      />
    </Box>
  );
};
export default AppPaginationNew;
