import { ButtonProps, VStack } from '@chakra-ui/react';
import React, { ReactNode, useMemo } from 'react';
import {
  IconBackpack,
  IconMetamaskNew,
  IconWalletConnect,
} from 'src/assets/icons';
import { AppButton } from 'src/components';
import { WALLET_TYPE } from 'src/utils/common';
import { useConnect } from 'wagmi';

interface ButtonConnectProps extends Omit<ButtonProps, 'type' | 'onClick'> {
  type: WALLET_TYPE;
  onClick: (type: WALLET_TYPE) => void;
}

const WalletInfo: {
  [key in WALLET_TYPE]: {
    icon: ReactNode;
    link: string;
  };
} = {
  MetaMask: { icon: <IconMetamaskNew />, link: 'https://metamask.io/download' },
  Backpack: {
    icon: <IconBackpack />,
    link: 'https://chromewebstore.google.com/detail/backpack/aflkmfhebedbjioipglgcbcmnbpgliof',
  },
  WalletConnect: {
    icon: <IconWalletConnect />,
    link: '',
  },
};

const ButtonConnect: React.FC<ButtonConnectProps> = ({
  type,
  onClick,
  variant,
  ...props
}) => {
  const { connectors } = useConnect();

  const isInstalledWallet = useMemo(
    () => connectors.find((c) => c.name === type),
    [connectors, type],
  );

  return (
    <VStack gap={4}>
      <AppButton
        p="12px"
        w={'48px'}
        h="48px"
        variant={'chartreuse'}
        onClick={() => {
          isInstalledWallet
            ? onClick?.(type)
            : window.open(WalletInfo[type].link, '_blank');
        }}
        {...props}
      >
        {WalletInfo[type].icon}
      </AppButton>
    </VStack>
  );
};

export default ButtonConnect;
