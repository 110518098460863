import axios from 'axios';
import { ILogin } from 'src/page/login';
import { request } from 'src/utils/request';
import { LoginResponse } from 'src/utils/types/auth';

export const loginWallet = async (body: ILogin) => {
  const response = await request.post<LoginResponse>(
    '/auth/login-external-wallet',
    body,
  );

  return response.data;
};

export const connectWallet = async (body: ILogin) => {
  const response = await request.post<LoginResponse>(
    '/auth/connect-wallet',
    body,
  );

  return response.data;
};

export const loginClerk = async (token: string) => {
  const response = await axios.post<any>(
    `${process.env.REACT_APP_API_BASE_URL}/auth/login-clerk`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response.data;
};
