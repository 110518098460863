import { useCallback, useMemo } from 'react';
import {
  UseDisconnectReturnType,
  useDisconnect as useDisconnectWagmi,
} from 'wagmi';

export function useDisconnect(): UseDisconnectReturnType {
  const { connectors, disconnect, disconnectAsync, ...rest } =
    useDisconnectWagmi();

  const disconnectAll = useCallback(() => {
    connectors.forEach((connector) => {
      disconnect({ connector });
    });
  }, [connectors, disconnect]);

  const disconnectAsyncAll = useCallback(async () => {
    await Promise.all(
      connectors.map((connector) => disconnectAsync({ connector })),
    );
  }, [connectors, disconnectAsync]);

  return useMemo(
    () => ({
      ...rest,
      disconnect: disconnectAll,
      disconnectAsync: disconnectAsyncAll,
      connectors,
    }),
    [rest, disconnectAll, disconnectAsyncAll, connectors],
  );
}
