import { Box, Flex, Spinner, Text } from '@chakra-ui/react';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Form from './Form';
import { useLocation, useNavigate } from 'react-router-dom';
import { Paths } from 'src/utils/common';
import Code from 'src/utils/types/codes';
import { getCodeById } from 'src/api/admin-codes';
import PopupConfirm from './PopupConfirm';

const AdminCodesNewPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const query = new URLSearchParams(useLocation().search);
  const [code, setCode] = useState<Code.Info>();
  const codeId = query.get('id');
  const [isOpenPopupBack, setIsOpenPopupBack] = useState(false);
  const isChanged = useRef(false);

  useEffect(() => {
    if (!codeId || !Number(codeId)) return;
    getCodeById(Number(codeId)).then((res) => {
      setCode(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box padding="24px" overflow="auto" fontFamily="Poppins" width="572px">
      <Flex
        flexDirection="row"
        alignItems="center"
        marginBottom="24px"
        gap="16px"
        width="fit-content"
      >
        <Box
          __css={{ 'svg path': { stroke: '#393D49' } }}
          cursor="pointer"
          onClick={() =>
            isChanged.current
              ? setIsOpenPopupBack(true)
              : navigate(`${Paths.ADMIN_CODES_DETAIL}?id=${codeId}`)
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="40"
            viewBox="0 0 16 40"
            fill="none"
          >
            <path
              d="M10 30L2.44444 22L10 14"
              stroke="#393D49"
              strokeWidth="2.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Box>
        <Text
          fontFamily="'Exo 2'"
          fontWeight="700"
          fontSize="32px"
          lineHeight="40px"
          color="#041336"
        >
          {t('Edit Code')}
        </Text>
      </Flex>
      {!code ? (
        <Flex
          width="100%"
          height="500px"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          gap="16px"
          padding="20px 24px"
          borderRadius="16px"
          backgroundColor="#FFFFFF"
        >
          <Spinner />
        </Flex>
      ) : (
        <Form code={code} isEdit isChanged={isChanged} />
      )}
      <PopupConfirm
        isOpen={isOpenPopupBack}
        isEdit
        codeId={codeId}
        setIsOpenPopupBack={setIsOpenPopupBack}
      />
    </Box>
  );
};
export default AdminCodesNewPage;
