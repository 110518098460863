import React from 'react';
import { Flex, TableCellProps, Tbody, Td, Tr } from '@chakra-ui/react';
import AwardWallet from 'src/utils/types/award-wallet';
import moment from 'moment';
import { useAtomValue } from 'jotai';
import { userAtom } from 'src/store/user';
import { formattedNumberWithDecimal } from 'src/utils/utils-format';
import { useTranslation } from 'react-i18next';

interface TableBodyProps {
  matches: AwardWallet.Match[];
}

const TableBody: React.FC<TableBodyProps> = ({ matches = [] }) => {
  const user = useAtomValue(userAtom);
  const { t } = useTranslation();

  return user && matches.length > 0 ? (
    <Tbody>
      {matches.map((match: AwardWallet.Match, idx: number) => {
        const bgColor = idx % 2 === 1 ? '#F4F4F4' : '#FFFFFF';

        return (
          <Tr key={match.id}>
            <CustomTd bgColor={bgColor} isFirst={true} fontWeight="500">
              {match.loyaltyProgramName}
            </CustomTd>
            <CustomTd bgColor={bgColor}>
              {formattedNumberWithDecimal(match.balance, 6)} {t('Mile/Point')}
            </CustomTd>
            <CustomTd bgColor={bgColor}>
              ${formattedNumberWithDecimal(match.amount, 6)}
            </CustomTd>
            <CustomTd bgColor={bgColor} isLast={true}>
              {moment(match.createdAt).format('MMM DD, YYYY')}
            </CustomTd>
          </Tr>
        );
      })}
    </Tbody>
  ) : (
    <Tbody>
      <Tr>
        <CustomTd>-</CustomTd>
        <CustomTd>0</CustomTd>
        <CustomTd>0</CustomTd>
        <CustomTd>-</CustomTd>
      </Tr>
    </Tbody>
  );
};

const CustomTd: React.FC<
  React.PropsWithChildren<{
    bgColor?: string;
    isLast?: boolean;
    isFirst?: boolean;
  }> &
    TableCellProps
> = ({ children, bgColor, color, isFirst, isLast, ...rest }) => {
  return (
    <Td
      fontFamily="Poppins"
      fontWeight="400"
      fontSize="16px"
      lineHeight="22px"
      textTransform="none"
      color={color ? color : '#041336'}
      border="none"
      padding={0}
      {...rest}
    >
      <Flex
        backgroundColor={bgColor}
        verticalAlign="bottom"
        padding="13px 24px"
        marginTop="16px"
        height="68px"
        alignItems="center"
        borderTopLeftRadius={isFirst ? '10px' : 'none'}
        borderBottomLeftRadius={isFirst ? '10px' : 'none'}
        borderTopRightRadius={isLast ? '10px' : 'none'}
        borderBottomRightRadius={isLast ? '10px' : 'none'}
      >
        {children}
      </Flex>
    </Td>
  );
};

export default TableBody;
