import {
  Flex,
  Grid,
  Input,
  InputGroup,
  InputRightElement,
  Switch,
  Text,
  Textarea,
} from '@chakra-ui/react';
import moment from 'moment';
import React, {
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { codesPublic, createCode, updateCode } from 'src/api/admin-codes';
import { IconCalendar } from 'src/assets/icons';
import { AppButton } from 'src/components';
import { Paths, TYPE_CODE } from 'src/utils/common';
import {
  numberRegexNoMaxDecimal,
  numeralAndAlphabeticalRegex,
  numeralAndAlphabeticalSpaceRegex,
  numeralRegex,
} from 'src/utils/helpers/etc';
import Code from 'src/utils/types/codes';
import { toastError, toastSuccess } from 'src/utils/utils-notify';

interface FormProps {
  code: Code.Info;
  isEdit?: boolean;
  isChanged: MutableRefObject<boolean>;
}

interface ErrorType {
  name?: string;
  startTime?: string;
  endTime?: string;
  amount?: string;
  redirectUrl?: string;
  'messageSuccess-en'?: string;
  'messageSuccess-jp'?: string;
  redeemLimit?: string;
  code?: string;
}

const Form: React.FC<FormProps> = ({ code, isEdit, isChanged }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [formValue, setFormValue] = useState<Code.Info>({
    ...code,
    amount: code.amount === '' ? '' : +code.amount,
  });
  const startDateRef = useRef<HTMLInputElement>(null);
  const endDateRef = useRef<HTMLInputElement>(null);
  const [errors, setErrors] = useState<ErrorType>({
    name: '',
    startTime: '',
    endTime: '',
    amount: '',
    redirectUrl: '',
    'messageSuccess-en': '',
    'messageSuccess-jp': '',
    redeemLimit: '',
  });

  const onChange = async (field: string, value: string) => {
    if (field === 'name') {
      if (!numeralAndAlphabeticalSpaceRegex.test(value) || value.length > 20)
        return;
    }

    if (field === 'code') {
      if (
        !numeralAndAlphabeticalRegex.test(value) ||
        value.length > 8 ||
        value.includes(' ')
      )
        return;
    }

    if (field === 'amount') {
      if (
        !numberRegexNoMaxDecimal.test(value) ||
        value.length > 20 ||
        (!!value && Number(value) === 0)
      )
        return;
    }

    if (field === 'redeemLimit') {
      if (!numeralRegex.test(value) || (!!value && Number(value) === 0)) return;
    }

    setErrors((prev) => ({
      ...prev,
      [field]:
        value.trim() === '' || !value
          ? t('This is a required field')
          : field === 'code' && value.length < 8
          ? t('Promo Code must be 8 characters')
          : '',
    }));

    if (field === 'code' && value.length === 8) {
      await codesPublic(value)
        // eslint-disable-next-line no-loop-func
        .then(() => {
          setErrors((prev) => ({
            ...prev,
            [field]: t('Promo Code already exists'),
          }));
        })
        // eslint-disable-next-line no-loop-func
        .catch((err) => {
          if (err.raw?.message === 'code_not_found') {
            setErrors((prev) => ({
              ...prev,
              [field]: '',
            }));
          }
        });
    }
    setFormValue((prev: any) => ({ ...prev, [field]: value }));
  };

  const onChangeMessage = (
    field: string,
    messages: Code.Message[],
    lang: string,
    value: string,
  ) => {
    if (value.length > 120) return;
    setErrors((prev) => ({
      ...prev,
      [`${field}-${lang}`]:
        value.trim() === '' || !value ? t('This is a required field') : '',
    }));
    const idx = messages.findIndex((message) => message.lang === lang);
    const newMessage = [
      ...messages.slice(0, idx),
      { lang, message: value },
      ...messages.slice(idx + 1),
    ];
    setFormValue((prev) => ({ ...prev, [field]: newMessage }));
  };

  const isDisabled = useMemo(() => {
    const submitValue = { ...formValue };
    if (submitValue.type === TYPE_CODE.PROMO_CODE) {
      delete submitValue.redirectUrl;
    }

    let valid = true;
    Object.entries(submitValue).forEach(([key, value]) => {
      if (key === 'messageSuccess') {
        const countEmpty = (value as Code.Message[])?.filter(
          (v) => v.message.trim() === '',
        )?.length;
        valid = valid && countEmpty === 0;
      } else if (key === 'landingPageMessage') {
      } else if (key === 'code') {
        valid =
          valid &&
          value.toString().trim() !== '' &&
          !!value &&
          (value as string).length === 8;
      } else if (key !== 'status') {
        valid = valid && value.toString().trim() !== '' && !!value;
      }
    });
    return !valid;
  }, [formValue]);

  const handleOnSubmit = () => {
    const submitValue = { ...formValue };
    if (submitValue.type === TYPE_CODE.PROMO_CODE) {
      delete submitValue.redirectUrl;
    }
    delete submitValue.link;

    if (isEdit) {
      updateCode({
        ...submitValue,
        type:
          submitValue.type === TYPE_CODE.QR_CODE
            ? TYPE_CODE.QR_CODE
            : TYPE_CODE.PROMO_CODE,
        startTime: moment(submitValue.startTime).format('YYYY-MM-DD'),
        endTime: moment(submitValue.endTime).format('YYYY-MM-DD'),
        redeemLimit: Number(submitValue.redeemLimit),
      })
        .then(() => {
          toastSuccess({ message: t('Changes saved!') });
          navigate(`${Paths.ADMIN_CODES_DETAIL}?id=${code.id}`);
        })
        .catch(() => {
          toastError({ message: t('Unable to save changes!') });
        });
    } else {
      createCode({
        ...submitValue,
        type:
          submitValue.type === TYPE_CODE.QR_CODE
            ? TYPE_CODE.QR_CODE
            : TYPE_CODE.PROMO_CODE,
        startTime: moment(submitValue.startTime).format('YYYY-MM-DD'),
        endTime: moment(submitValue.endTime).format('YYYY-MM-DD'),
        redeemLimit: Number(submitValue.redeemLimit),
      })
        .then(() => {
          toastSuccess({ message: t('Code generated successfully!') });
          navigate(Paths.ADMIN_CODES_MANAGEMENT);
        })
        .catch(() => {
          toastError({ message: t('Code generation failed!') });
        });
    }
  };

  const handleOnRandomCode = async () => {
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    let count = 0;
    const charactersLength = characters.length;

    while (result === '' && count < 3) {
      for (let i = 0; i < 8; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength),
        );
      }
      count++;
      await codesPublic(result)
        // eslint-disable-next-line no-loop-func
        .then(() => (result = ''))
        // eslint-disable-next-line no-loop-func
        .catch((err) => {
          if (err.raw?.message !== 'code_not_found') {
            result = '';
          }
        });
    }
    setFormValue((prev) => ({
      ...prev,
      code: result,
    }));
    setErrors((prev) => ({ ...prev, code: '' }));
  };

  useEffect(() => {
    const checkIsChanged = () => {
      isChanged.current = false;
      const objectForm = Object.values(formValue);
      Object.entries(code).forEach(([key, value], index) => {
        if (key === 'messageSuccess') {
          const countChange = (value as Code.Message[])?.filter(
            (v, idx) => v.message !== objectForm[index][idx].message,
          )?.length;
          if (countChange > 0) {
            isChanged.current = true;
            return;
          }
        } else if (value !== objectForm[index]) {
          isChanged.current = true;
          return;
        }
      });
    };

    checkIsChanged();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValue]);

  //   Start date
  const valueDateStart = formValue.startTime
    ? moment(formValue.startTime).format('YYYY-MM-DD')
    : '';

  const missMin_Start = moment(formValue.startTime).isBefore(moment());

  // End Date
  const valueDateEnd = formValue.endTime
    ? moment(formValue.endTime).format('YYYY-MM-DD')
    : '';

  return (
    <Flex flexDirection="column">
      <Flex
        flexDirection="column"
        gap="16px"
        padding="20px 24px"
        borderRadius="16px"
        backgroundColor="#FFFFFF"
        width="100%"
      >
        {/* Code Name */}
        <Flex
          flexDirection="column"
          gap="8px"
          fontSize="16px"
          lineHeight="22px"
        >
          <Flex fontWeight="600">
            <Text color="#041336">{t('Code Name')}</Text>
            <Text color="#FF574B" marginLeft="4px">
              *
            </Text>
          </Flex>
          <Flex fontWeight="400">
            <Input
              height="42px"
              borderRadius="10px"
              value={formValue.name}
              onChange={(e) => onChange('name', e.target.value)}
              placeholder={t('Enter code name')}
              _placeholder={{ color: '#A7A9AB' }}
              outline="none!important"
              boxShadow="none!important"
              border={
                errors.name
                  ? '1px solid #FF4C3F!important'
                  : '1px solid #D7DADC!important'
              }
            />
          </Flex>
          {!!errors.name && (
            <Flex
              fontFamily="Poppins"
              fontWeight={500}
              fontSize={12}
              lineHeight="16px"
              color="#FF4C3F"
            >
              {errors.name}
            </Flex>
          )}
        </Flex>

        <Flex width="100%">
          <Flex
            flexDirection="column"
            gap="8px"
            fontSize="16px"
            lineHeight="22px"
            width="100%"
          >
            <Flex fontWeight="600">
              <Text color="#041336">{t('Promo Code')}</Text>
              <Text color="#FF574B" marginLeft="4px">
                *
              </Text>
            </Flex>
            <Grid
              gridTemplateColumns={isEdit ? '1fr' : '2fr 1fr'}
              fontWeight="400"
              gap="10px"
              width="100%"
            >
              <Input
                height="42px"
                borderRadius="10px"
                value={formValue.code}
                onChange={(e) => onChange('code', e.target.value)}
                placeholder={t('Enter code')}
                _placeholder={{ color: '#A7A9AB' }}
                outline="none!important"
                boxShadow="none!important"
                border={
                  errors.code
                    ? '1px solid #FF4C3F!important'
                    : '1px solid #D7DADC!important'
                }
                disabled={isEdit}
                sx={{
                  _disabled: {
                    backgroundColor: '#E2E2E3',
                    pointerEvents: 'none',
                  },
                }}
              />

              {!isEdit && (
                <Flex
                  fontFamily="'Exo 2'"
                  fontWeight="700"
                  fontSize="16px"
                  lineHeight="20px"
                >
                  <AppButton
                    textTransform="uppercase"
                    onClick={handleOnRandomCode}
                    height="42px"
                    borderRadius="10px"
                  >
                    {t('randomize code')}
                  </AppButton>
                </Flex>
              )}
            </Grid>
            {!!errors.code && (
              <Flex
                fontFamily="Poppins"
                fontWeight={500}
                fontSize={12}
                lineHeight="16px"
                color="#FF4C3F"
              >
                {errors.code}
              </Flex>
            )}
          </Flex>
        </Flex>

        <Grid gridTemplateColumns="1fr 1fr" gap="16px">
          <Flex
            flexDirection="column"
            gap="8px"
            fontSize="16px"
            lineHeight="22px"
          >
            <Flex fontWeight="600">
              <Text color="#041336">{t('Start Date')}</Text>
              <Text color="#FF574B" marginLeft="4px">
                *
              </Text>
            </Flex>
            <Flex
              fontWeight="400"
              position="relative"
              flexDirection="column"
              gap="8px"
            >
              <InputGroup position="relative" zIndex={2}>
                <InputRightElement
                  cursor="pointer"
                  onClick={() => {
                    if (startDateRef.current) {
                      startDateRef.current.showPicker();
                    }
                  }}
                  right="6px"
                >
                  <IconCalendar />
                </InputRightElement>
                <Input
                  type="text"
                  height="42px"
                  borderRadius="10px"
                  value={
                    formValue.startTime &&
                    new Date(formValue.startTime).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                    })
                  }
                  placeholder={t('Start Date')}
                  _placeholder={{ color: '#A7A9AB' }}
                  outline="none!important"
                  boxShadow="none!important"
                  border={
                    errors.startTime
                      ? '1px solid #FF4C3F!important'
                      : '1px solid #D7DADC!important'
                  }
                  background="#FFFFFF"
                  onClick={() => {
                    if (startDateRef.current) {
                      startDateRef.current.showPicker();
                    }
                  }}
                  readOnly
                />
              </InputGroup>
              <Input
                ref={startDateRef}
                type="date"
                height="42px"
                borderRadius="10px"
                value={valueDateStart}
                onChange={(e) => onChange('startTime', e.target.value)}
                placeholder={t('Start Date')}
                _placeholder={{ color: '#A7A9AB' }}
                outline="none!important"
                boxShadow="none!important"
                border={
                  errors.startTime
                    ? '1px solid #FF4C3F!important'
                    : '1px solid #D7DADC!important'
                }
                min={moment().format('YYYY-MM-DD')}
                max={moment(formValue.endTime || '2222-12-12')
                  .subtract(1, 'days')
                  .format('YYYY-MM-DD')}
                position="absolute"
                zIndex={1}
              />
              {!!errors.startTime && (
                <Flex
                  fontFamily="Poppins"
                  fontWeight={500}
                  fontSize={12}
                  lineHeight="16px"
                  color="#FF4C3F"
                >
                  {errors.startTime}
                </Flex>
              )}
            </Flex>
          </Flex>
          <Flex
            flexDirection="column"
            gap="8px"
            fontSize="16px"
            lineHeight="22px"
          >
            <Flex fontWeight="600">
              <Text color="#041336">{t('End Date')}</Text>
              <Text color="#FF574B" marginLeft="4px">
                *
              </Text>
            </Flex>
            <Flex
              fontWeight="400"
              position="relative"
              flexDirection="column"
              gap="8px"
            >
              <InputGroup position="relative" zIndex={2}>
                <InputRightElement
                  cursor="pointer"
                  onClick={() => {
                    if (endDateRef.current) {
                      endDateRef.current.showPicker();
                    }
                  }}
                  right="6px"
                >
                  <IconCalendar />
                </InputRightElement>
                <Input
                  type="text"
                  height="42px"
                  borderRadius="10px"
                  value={
                    formValue.endTime &&
                    new Date(formValue.endTime).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                    })
                  }
                  placeholder={t('End Date')}
                  _placeholder={{ color: '#A7A9AB' }}
                  outline="none!important"
                  boxShadow="none!important"
                  border={
                    errors.endTime
                      ? '1px solid #FF4C3F!important'
                      : '1px solid #D7DADC!important'
                  }
                  background="#FFFFFF"
                  onClick={() => {
                    if (endDateRef.current) {
                      endDateRef.current.showPicker();
                    }
                  }}
                  readOnly
                />
              </InputGroup>
              <Input
                ref={endDateRef}
                type="date"
                height="42px"
                borderRadius="10px"
                value={valueDateEnd}
                onChange={(e) => onChange('endTime', e.target.value)}
                placeholder={t('End Date')}
                _placeholder={{ color: '#A7A9AB' }}
                outline="none!important"
                boxShadow="none!important"
                border={
                  errors.endTime
                    ? '1px solid #FF4C3F!important'
                    : '1px solid #D7DADC!important'
                }
                min={
                  formValue.startTime && !missMin_Start
                    ? moment(formValue.startTime)
                        .add(1, 'days')
                        .format('YYYY-MM-DD')
                    : moment().add(1, 'days').format('YYYY-MM-DD')
                }
                position="absolute"
                zIndex={1}
              />
              {!!errors.endTime && (
                <Flex
                  fontFamily="Poppins"
                  fontWeight={500}
                  fontSize={12}
                  lineHeight="16px"
                  color="#FF4C3F"
                >
                  {errors.endTime}
                </Flex>
              )}
            </Flex>
          </Flex>
        </Grid>

        {/* Give Away Amount */}
        <Flex
          flexDirection="column"
          gap="8px"
          fontSize="16px"
          lineHeight="22px"
        >
          <Flex fontWeight="600">
            <Text color="#041336">{t('Give Away Amount')}</Text>
            <Text color="#FF574B" marginLeft="4px">
              *
            </Text>
          </Flex>
          <Flex fontWeight="400">
            <Input
              height="42px"
              borderRadius="10px"
              value={formValue.amount === '' ? '' : formValue.amount}
              onChange={(e) => onChange('amount', e.target.value)}
              placeholder={t('Enter amount')}
              _placeholder={{ color: '#A7A9AB' }}
              outline="none!important"
              boxShadow="none!important"
              maxLength={10}
              border={
                errors.amount
                  ? '1px solid #FF4C3F!important'
                  : '1px solid #D7DADC!important'
              }
            />
          </Flex>
          {!!errors.amount && (
            <Flex
              fontFamily="Poppins"
              fontWeight={500}
              fontSize={12}
              lineHeight="16px"
              color="#FF4C3F"
            >
              {errors.amount}
            </Flex>
          )}
        </Flex>

        {/* Successful Redemption Message - EN */}
        <Flex
          flexDirection="column"
          gap="8px"
          fontSize="16px"
          lineHeight="22px"
        >
          <Flex fontWeight="600">
            <Text color="#041336">
              {t('Successful Redemption Message - EN')}
            </Text>
            <Text color="#FF574B" marginLeft="4px">
              *
            </Text>
          </Flex>
          <Flex fontWeight="400">
            <Textarea
              value={
                formValue.messageSuccess.find((lpm) => lpm.lang === 'en')
                  ?.message || ''
              }
              onChange={(e) =>
                onChangeMessage(
                  'messageSuccess',
                  formValue.messageSuccess,
                  'en',
                  e.target.value,
                )
              }
              onPaste={(e) => {
                e.preventDefault();

                onChangeMessage(
                  'messageSuccess',
                  formValue.messageSuccess,
                  'en',
                  e.clipboardData.getData('text')?.slice(0, 120),
                );
              }}
              placeholder={t('Enter description....')}
              _placeholder={{ color: '#A7A9AB' }}
              outline="none!important"
              boxShadow="none!important"
              border={
                errors['messageSuccess-en']
                  ? '1px solid #FF4C3F!important'
                  : '1px solid #D7DADC!important'
              }
              height="100px"
              padding="10px 16px"
              resize="none"
            />
          </Flex>
          {!!errors['messageSuccess-en'] && (
            <Flex
              fontFamily="Poppins"
              fontWeight={500}
              fontSize={12}
              lineHeight="16px"
              color="#FF4C3F"
            >
              {errors['messageSuccess-en']}
            </Flex>
          )}
        </Flex>
        {/* Successful Redemption Message - JP */}
        <Flex
          flexDirection="column"
          gap="8px"
          fontSize="16px"
          lineHeight="22px"
        >
          <Flex fontWeight="600">
            <Text color="#041336">
              {t('Successful Redemption Message - JP')}
            </Text>
            <Text color="#FF574B" marginLeft="4px">
              *
            </Text>
          </Flex>
          <Flex fontWeight="400">
            <Textarea
              borderRadius="10px"
              value={
                formValue.messageSuccess.find((lpm) => lpm.lang === 'jp')
                  ?.message || ''
              }
              onChange={(e) =>
                onChangeMessage(
                  'messageSuccess',
                  formValue.messageSuccess,
                  'jp',
                  e.target.value,
                )
              }
              onPaste={(e) => {
                e.preventDefault();

                onChangeMessage(
                  'messageSuccess',
                  formValue.messageSuccess,
                  'jp',
                  e.clipboardData.getData('text')?.slice(0, 120),
                );
              }}
              placeholder={t('Enter description....')}
              _placeholder={{ color: '#A7A9AB' }}
              outline="none!important"
              boxShadow="none!important"
              border={
                errors['messageSuccess-jp']
                  ? '1px solid #FF4C3F!important'
                  : '1px solid #D7DADC!important'
              }
              height="100px"
              padding="10px 16px"
              resize="none"
            />
          </Flex>
          {!!errors['messageSuccess-jp'] && (
            <Flex
              fontFamily="Poppins"
              fontWeight={500}
              fontSize={12}
              lineHeight="16px"
              color="#FF4C3F"
            >
              {errors['messageSuccess-jp']}
            </Flex>
          )}
        </Flex>
        {/* Redeem Limit */}
        <Flex
          flexDirection="column"
          gap="8px"
          fontSize="16px"
          lineHeight="22px"
        >
          <Flex fontWeight="600">
            <Text color="#041336">{t('Redeem Limit')}</Text>
            <Text color="#FF574B" marginLeft="4px">
              *
            </Text>
          </Flex>
          <Flex fontWeight="400">
            <Input
              height="42px"
              borderRadius="10px"
              value={formValue.redeemLimit}
              onChange={(e) => onChange('redeemLimit', e.target.value)}
              placeholder={t('Redeem limit')}
              _placeholder={{ color: '#A7A9AB' }}
              outline="none!important"
              boxShadow="none!important"
              maxLength={10}
              border={
                errors.redeemLimit
                  ? '1px solid #FF4C3F!important'
                  : '1px solid #D7DADC!important'
              }
            />
          </Flex>
          {!!errors.redeemLimit && (
            <Flex
              fontFamily="Poppins"
              fontWeight={500}
              fontSize={12}
              lineHeight="16px"
              color="#FF4C3F"
            >
              {errors.redeemLimit}
            </Flex>
          )}
        </Flex>
        {!isEdit && (
          <Flex flexDirection="row" alignItems="center" gap="12px">
            <Switch
              isChecked={formValue.type === TYPE_CODE.QR_CODE}
              onChange={() =>
                setFormValue((prev: any) => ({
                  ...prev,
                  type:
                    TYPE_CODE.PROMO_CODE === prev.type
                      ? TYPE_CODE.QR_CODE
                      : TYPE_CODE.PROMO_CODE,
                }))
              }
            />
            <Text
              fontWeight="500"
              fontSize="16px"
              lineHeight="22px"
              color="#041336"
            >
              {t('Generate as QR Code')}
            </Text>
          </Flex>
        )}
        {isEdit && formValue.type === TYPE_CODE.PROMO_CODE ? null : (
          <Flex
            flexDirection="column"
            gap="8px"
            fontSize="16px"
            lineHeight="22px"
          >
            <Flex fontWeight="600">
              <Text color="#041336">Landing Page URL</Text>
              <Text color="#FF574B" marginLeft="4px">
                *
              </Text>
            </Flex>
            <Flex fontWeight="400">
              <Input
                height="42px"
                borderRadius="10px"
                value={
                  formValue.type === TYPE_CODE.QR_CODE
                    ? formValue.redirectUrl
                    : ''
                }
                onChange={(e) => onChange('redirectUrl', e.target.value)}
                placeholder={isEdit ? '' : t('Enter URL')}
                _placeholder={{ color: '#A7A9AB' }}
                outline="none!important"
                boxShadow="none!important"
                border={
                  errors.redirectUrl
                    ? '1px solid #FF4C3F!important'
                    : '1px solid #D7DADC!important'
                }
                disabled={formValue.type === TYPE_CODE.PROMO_CODE}
                sx={{
                  _disabled: {
                    backgroundColor: '#E2E2E3',
                    pointerEvents: 'none',
                  },
                }}
              />
            </Flex>
            {!!errors.redirectUrl && (
              <Flex
                fontFamily="Poppins"
                fontWeight={500}
                fontSize={12}
                lineHeight="16px"
                color="#FF4C3F"
              >
                {errors.redirectUrl}
              </Flex>
            )}
          </Flex>
        )}
        <Flex
          flexDirection="row"
          alignItems="center"
          marginBottom="24px"
          gap="12px"
        >
          <Switch
            isChecked={!formValue.status}
            onChange={() =>
              setFormValue((prev: any) => ({
                ...prev,
                status: !prev.status,
              }))
            }
          />
          <Text
            fontWeight="500"
            fontSize="16px"
            lineHeight="22px"
            color="#041336"
          >
            {t('Enable Code')}
          </Text>
        </Flex>
      </Flex>

      <Flex
        fontFamily="'Exo 2'"
        fontWeight="700"
        fontSize="16px"
        lineHeight="22px"
        justifyContent="flex-end"
        marginTop="24px"
      >
        <AppButton
          width="160px"
          textTransform="uppercase"
          onClick={handleOnSubmit}
          isDisabled={isDisabled}
        >
          {isEdit ? t('Save') : t('Generate')}
        </AppButton>
      </Flex>
    </Flex>
  );
};

export default Form;
