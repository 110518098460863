import { Text, TextProps } from '@chakra-ui/react';
import { forwardRef } from 'react';

export interface AppTextProps extends TextProps {
  variant?: keyof typeof appTextStyles.variants;
}

const AppText = forwardRef<HTMLButtonElement, AppTextProps>((props, ref) => {
  const { variant = 'brand', children, ...rest } = props;

  return (
    <Text {...rest} variant={variant} ref={ref}>
      {children}
    </Text>
  );
});

export default AppText;

export const appTextStyles = {
  baseStyle: {},
  variants: {
    //#region New theme
    'Heading/Desktop/H1': {
      fontFamily: '"Exo 2"',
      fontSize: '80px',
      lineHeight: '80px',
      fontWeight: 300,
    },
    'Heading/Desktop/H2': {
      fontFamily: '"Exo 2"',
      fontSize: '48px',
      lineHeight: '57.6px',
      fontWeight: 300,
    },
    'Heading/Desktop/H3': {
      fontFamily: '"Exo 2"',
      fontSize: '40px',
      lineHeight: '48px',
      fontWeight: 300,
    },
    'Heading/Desktop/H4': {
      fontFamily: '"Exo 2"',
      fontSize: '40px',
      lineHeight: '52px',
      fontWeight: 400,
    },
    'Heading/Mobile/H6': {
      fontFamily: '"Exo 2"',
      fontSize: '12px',
      lineHeight: '16.8px',
      fontWeight: 700,
    },
    'Heading/Mobile/H4': {
      fontFamily: '"Exo 2"',
      fontSize: '24px',
      lineHeight: '24px',
      fontWeight: 600,
    },
    'Text/Large/Normal': {
      fontFamily: '"Poppins"',
      fontSize: '20px',
      lineHeight: '30px',
      fontWeight: 400,
    },
    'Text/Large/Bold': {
      fontFamily: '"Poppins"',
      fontSize: '20px',
      lineHeight: '30px',
      fontWeight: 700,
    },
    'Text/Large/Semi Bold': {
      fontFamily: '"Poppins"',
      fontSize: '48px',
      lineHeight: '48px',
      fontWeight: 600,
    },
    'Text/Medium/Normal': {
      fontFamily: '"Poppins"',
      fontSize: '18px',
      lineHeight: '27px',
      fontWeight: 400,
    },
    'Text/Medium/Bold': {
      fontFamily: '"Poppins"',
      fontSize: '18px',
      lineHeight: '27px',
      fontWeight: 700,
    },
    'Text/Regular/Semi Bold': {
      fontFamily: '"Poppins"',
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 600,
    },
    'Text/Regular/Normal': {
      fontFamily: '"Poppins"',
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 400,
    },
    'Text/Regular/Bold': {
      fontFamily: '"Poppins"',
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 700,
    },
    'Text/Small/Medium': {
      fontFamily: '"Poppins"',
      fontSize: '14px',
      lineHeight: '21px',
      fontWeight: 500,
    },
    'Text/Small/Normal': {
      fontFamily: '"Poppins"',
      fontSize: '12px',
      lineHeight: '18px',
      fontWeight: 400,
    },
    'Text/Tiny/Semi Bold': {
      fontFamily: '"Poppins"',
      fontSize: '12px',
      lineHeight: '18px',
      fontWeight: 600,
    },
    //#endregion New theme
  },
};
