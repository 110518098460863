import { Box, Image, Spinner, Text } from '@chakra-ui/react';
import { useUser } from '@clerk/clerk-react';
import { useTranslation } from 'react-i18next';
import AppTooltip from 'src/components/AppTooltip';
import { supportedLngs } from 'src/i18n';
import { formattedNumber, number2KMBT } from 'src/utils/utils-format';

interface UserInfoProps {
  user: ReturnType<typeof useUser>;
  pointBalance?: string;
}

export const UserInfo = ({ user, pointBalance }: UserInfoProps) => {
  const { t, i18n } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '32px 8px',
        //
        borderTop: '2px',
        borderBottom: '1px',
        borderStyle: 'solid',
        borderColor: 'Color Brand/Cetacean Blue',
        //
        mb: '24px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          border: '1px solid #FFFFFF1A',
          borderRadius: '100px',
          padding: '4px',
        }}
      >
        <Image
          src="/images/default-avatar.png"
          width="36px"
          minWidth="36px"
          height="36px"
          borderRadius="50%"
        />
      </Box>

      <Text
        sx={{
          margin: '8px 0 16px 0',
          minH: '24px',
        }}
        variant={'Text/Regular/Semi Bold'}
      >
        {i18n.language === supportedLngs[0]
          ? `${user.user?.firstName} ${user.user?.lastName}`
          : `${user.user?.lastName}　${user.user?.firstName}`}
      </Text>
      <Text
        sx={{
          marginBottom: '4px',
        }}
        variant={'Heading/Mobile/H6'}
        color={'Text Secondary'}
      >
        {t('TRAVEL CREDITS')}
      </Text>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
          gap: '4px',
        }}
      >
        {pointBalance === undefined ? (
          <Spinner height="24px" marginRight="4px" />
        ) : (
          <AppTooltip
            variant={'chartreuse'}
            label={formattedNumber(pointBalance, 0, 20)}
          >
            <Text variant={'Text/Regular/Semi Bold'}>
              ${number2KMBT(pointBalance)}
            </Text>
          </AppTooltip>
        )}
        <Text
          variant={'Text/Tiny/Semi Bold'}
          color={'Text Secondary'}
          lineHeight="22px"
        >
          USD
        </Text>
      </Box>
    </Box>
  );
};

UserInfo.Mobile = ({ user, pointBalance }: UserInfoProps) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t, i18n } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        padding: '16px 20px',
        gap: '16px',
        //
        borderTop: '2px',
        borderBottom: '1px',
        borderStyle: 'solid',
        borderColor: 'Color Brand/Cetacean Blue',
        //
        mb: '32px',
      }}
    >
      <Box>
        <Box
          sx={{
            display: 'flex',
            border: '1px solid #FFFFFF1A',
            borderRadius: '100px',
            padding: '4px',
          }}
        >
          <Image
            src="/images/default-avatar.png"
            width="36px"
            minWidth="36px"
            height="36px"
            borderRadius="50%"
          />
        </Box>
      </Box>

      <Box>
        <Text
          sx={{
            margin: '0 0 4px 0',
          }}
          variant={'Text/Regular/Semi Bold'}
        >
          {i18n.language === supportedLngs[0]
            ? `${user.user?.firstName} ${user.user?.lastName}`
            : `${user.user?.lastName}　${user.user?.firstName}`}
        </Text>
        <Text
          sx={{
            marginBottom: '4px',
          }}
          variant={'Heading/Mobile/H6'}
          color={'Text Secondary'}
        >
          {t('TRAVEL CREDITS')}
        </Text>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            gap: '4px',
          }}
        >
          {pointBalance === undefined ? (
            <Spinner height="24px" marginRight="4px" />
          ) : (
            <AppTooltip
              variant={'chartreuse'}
              label={formattedNumber(pointBalance, 0, 20)}
            >
              <Text variant={'Text/Regular/Semi Bold'}>
                ${number2KMBT(pointBalance)}
              </Text>
            </AppTooltip>
          )}

          <Text variant={'Text/Tiny/Semi Bold'} color={'Text Secondary'}>
            USD
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
