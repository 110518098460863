import { CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
} from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import AwardWallet from 'src/utils/types/award-wallet';
import Body from './Body';

interface MatchLoyaltyPointsProps {
  isOpen: boolean;
  onClose: () => void;
  loyaltyBalance?: AwardWallet.ProgramBalance;
  loyaltyPrograms: AwardWallet.Program[];
  getLoyaltyBalances: () => void;
}

const MatchLoyaltyPoints: React.FC<MatchLoyaltyPointsProps> = ({
  isOpen,
  onClose,
  loyaltyBalance,
  loyaltyPrograms,
  getLoyaltyBalances,
}) => {
  const { t } = useTranslation();
  const loyaltyProgram = useMemo(() => {
    if (!loyaltyPrograms || !loyaltyBalance) return undefined;

    const tempLoyaltyProgram = loyaltyPrograms.find(
      (lp: AwardWallet.Program) => lp.id === loyaltyBalance.loyaltyProgramId,
    );

    return tempLoyaltyProgram;
  }, [loyaltyBalance, loyaltyPrograms]);

  if (!loyaltyBalance) return null;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered={true}
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent
        backgroundColor="#FFFFFF"
        borderRadius="16px"
        padding="32px"
        gap="24px"
        position="relative"
        maxWidth="580px"
        className="match-loyalty-points"
      >
        <ModalHeader padding={0}>
          <Text
            fontFamily="'Exo 2'"
            fontWeight="700"
            fontSize={24}
            lineHeight="32px"
            padding="0"
            color="#222325"
          >
            {t('Match Loyalty Points')}
          </Text>
          <Flex
            fontFamily="Poppins"
            fontWeight="400"
            fontSize={14}
            lineHeight="20px"
            padding="0"
            marginTop="8px"
            color="#041336"
            gap="6px"
          >
            <Trans
              values={{
                rate: loyaltyProgram?.exchangeRate,
              }}
              components={{
                sprint: loyaltyProgram?.exchangeRate ? (
                  <></>
                ) : (
                  <Spinner height="20px" width="20px" />
                ),
              }}
            >
              {`Rate: 100 Miles/Point = $<sprint>{{rate}}</sprint> Travel Credits`}
            </Trans>
          </Flex>
        </ModalHeader>
        <Box
          className={'modal__btn-close'}
          onClick={onClose}
          position="absolute"
          top="37px"
          right="25px"
        >
          <CloseIcon width={'14px'} />
        </Box>
        <ModalBody>
          <Body
            loyaltyBalance={loyaltyBalance}
            loyaltyProgram={loyaltyProgram}
            getLoyaltyBalances={getLoyaltyBalances}
            onClose={onClose}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default MatchLoyaltyPoints;
