import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { IconExternal } from 'src/assets/icons';
import { AppLink } from 'src/components';
import { useLocation } from 'react-router-dom';
import { Paths } from 'src/utils/common';
import { useTranslation } from 'react-i18next';

const Footer: React.FC = () => {
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <Flex
      width="100vw"
      justifyContent="center"
      backgroundColor="rgba(255, 255, 255, 0.1)"
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        maxWidth="1280px"
        padding="14px 20px 16px"
      >
        {location.pathname === Paths.VOY_POINTS ||
        location.pathname === Paths.MATCH_HISTORIES ? (
          <AppLink to="/term-and-conditions">
            <Text
              fontFamily="Poppins"
              fontSize={14}
              fontWeight={500}
              lineHeight="20px"
              color="#FFFFFF"
            >
              {t('VOY Points Terms & Conditions')}
            </Text>
          </AppLink>
        ) : (
          <Box />
        )}
        <AppLink
          to="/term-and-conditions"
          display="flex"
          alignItems="center"
          gap="8px"
        >
          <Text
            fontFamily="Poppins"
            fontSize={14}
            fontWeight={500}
            lineHeight="20px"
            color="#FFFFFF"
          >
            {t('FAQ Links')}
          </Text>
          <IconExternal />
        </AppLink>
      </Flex>
    </Flex>
  );
};

export default Footer;
