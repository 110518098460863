import { Button, Flex, Input, Text, Tooltip } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat, OnValueChange } from 'react-number-format';
import { numberRegexNoMaxDecimal } from 'src/utils/helpers/etc';
import AwardWallet from 'src/utils/types/award-wallet';
import { formattedNumberWithDecimal } from 'src/utils/utils-format';

interface InputFromProps {
  value: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  loyaltyBalance: AwardWallet.ProgramBalance;
  setIsOver100k: React.Dispatch<React.SetStateAction<boolean>>;
  error: string;
  setError: React.Dispatch<React.SetStateAction<string>>;
}

const InputFrom: React.FC<InputFromProps> = ({
  value,
  setInputValue,
  loyaltyBalance,
  setIsOver100k,
  error,
  setError,
}) => {
  const { t } = useTranslation();

  const onChange: OnValueChange = (values) => {
    const value = values.value;

    if (numberRegexNoMaxDecimal.test(value)) {
      setInputValue(value);
      setIsOver100k(false);
    }
  };

  const setMax = () =>
    setInputValue(() => Number(loyaltyBalance.balance).toString());

  useEffect(() => {
    setError('');
    if (value === '' || +value === 0) {
      setError(t('Please enter a number greater than 0.'));
      return;
    }

    if (loyaltyBalance.code && Number(value) > Number(loyaltyBalance.balance)) {
      setError(
        t('MAXIMUM ALLOWED {{amount}}', {
          amount: formattedNumberWithDecimal(loyaltyBalance.balance, 6),
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    setError('');
  }, [loyaltyBalance]);

  return (
    <Flex
      borderRadius="15px"
      padding="18px 16px 18px 16px"
      backgroundColor="#F4F4F4"
      width="100%"
    >
      <Flex alignItems="flex-start" flexDirection="column">
        <Flex
          marginBottom="8px"
          flexDirection="column"
          justifyContent="flex-start"
        >
          <Text
            fontFamily="Poppins"
            fontWeight={500}
            fontSize={18}
            lineHeight="24px"
            color="#7C7E82"
            width="fit-content"
          >
            {t('From')}
          </Text>
        </Flex>
        <Flex gap="10px">
          <Flex position="relative">
            <NumericFormat
              thousandSeparator
              customInput={Input}
              value={value}
              onValueChange={onChange}
              width="100%"
              minWidth="248px"
              height="52px"
              placeholder="0"
              border={error ? '1px solid #FF4C3F!important' : 'none'}
              outline="none!important"
              boxShadow="none!important"
              backgroundColor="#FFFFFF"
              fontFamily="'Exo 2'"
              fontWeight={500}
              fontSize={32}
              lineHeight="40px"
              color="#041336"
              paddingRight={!!loyaltyBalance.code ? '72px' : '16px'}
            />
            {!!loyaltyBalance.code && (
              <Button
                fontFamily="'Exo 2'"
                fontWeight={400}
                fontSize={14}
                lineHeight="20px"
                color="#000000"
                onClick={setMax}
                height="36px"
                borderRadius="12px"
                padding="8px"
                backgroundColor="#D7DADC"
                position="absolute"
                right="16px"
                top="50%"
                transform="translateY(-50%)"
                zIndex={1}
                isDisabled={
                  !loyaltyBalance.balance ||
                  Number(loyaltyBalance.balance) === 0
                }
              >
                {t('MAX')}
              </Button>
            )}
          </Flex>
          <Flex flexDirection="column" alignItems="flex-end">
            <Flex
              fontFamily="Poppins"
              fontWeight={500}
              fontSize={14}
              lineHeight="20px"
              color="#041336"
              justifyContent="flex-end"
              borderRadius="22px"
              backgroundColor="#FFFFFF"
              padding="3px 6px 3px 8px"
              width="fit-content"
              marginBottom="6px"
            >
              {t('Mile/Point')}
            </Flex>
            <Flex>
              <Flex
                fontFamily="Poppins"
                fontWeight={400}
                fontSize={14}
                lineHeight="20px"
                color="#7C7E82"
                marginRight="4px"
                gap="4px"
              >
                <Text>{t('Balance')}:</Text>
                <Tooltip
                  label={formattedNumberWithDecimal(loyaltyBalance.balance, 18)}
                >
                  <Text maxWidth="70px" className="text-ellipsis">
                    {formattedNumberWithDecimal(loyaltyBalance.balance, 6)}
                  </Text>
                </Tooltip>
              </Flex>
              <Text
                fontFamily="Poppins"
                fontWeight={500}
                fontSize={14}
                lineHeight="20px"
                color="#222325"
              >
                {t('Mile/Point')}
              </Text>
            </Flex>
          </Flex>
        </Flex>
        {error && (
          <Flex
            fontFamily="Poppins"
            fontWeight={500}
            fontSize={12}
            lineHeight="16px"
            color="#FF4C3F"
            marginTop="8px"
          >
            {error}
          </Flex>
        )}
      </Flex>
      {/* {isErrorZero && (
        <Text className="errBalance">
          Please enter a number greater than 0.
        </Text>
      )}
      {isErrBalance && (
        <Text className="errBalance">
          Please enter an amount less than or equal to your available balance.
        </Text>
      )} */}
    </Flex>
  );
};

export default InputFrom;
