import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Box,
  Text,
  Flex,
  Input,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { AppButton } from 'src/components';
import { IconError, IconEyeClose, IconEyeOpen } from 'src/assets/icons';
import AwardWallet from 'src/utils/types/award-wallet';
import { loginLoyaltyProgram } from 'src/api/voy-wallet';
import { toastError } from 'src/utils/utils-notify';
import { COMMON_ERROR_MESSAGE } from 'src/utils/constants/constants';
import { useTranslation } from 'react-i18next';

interface ConnectLoyaltyProgramProps {
  selectedProgram?: AwardWallet.Program;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  getLoyaltyBalances: () => void;
  setIsOpenLoginSuccessfully: React.Dispatch<React.SetStateAction<boolean>>;
}

const ConnectLoyaltyProgram: React.FC<ConnectLoyaltyProgramProps> = ({
  selectedProgram,
  isOpen,
  setIsOpen,
  getLoyaltyBalances,
  setIsOpenLoginSuccessfully,
}) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [errors, setErrors] = useState({
    email: '',
    password: '',
    all: '',
  });

  const onClose = () => setIsOpen(false);

  const isValid = () => {
    let valid = true;
    if (!email) {
      setErrors((prev) => ({ ...prev, email: t('Email is required') }));
      valid = false;
    }
    if (!password) {
      setErrors((prev) => ({ ...prev, password: t('Password is required') }));
      valid = false;
    }

    return valid;
  };

  const handleConnect = () => {
    if (!isValid()) return;

    setIsLoading(true);

    loginLoyaltyProgram({
      loyaltyProgramId: selectedProgram!.id,
      username: email,
      password: password,
    })
      .then(() => {
        getLoyaltyBalances();
        setIsOpenLoginSuccessfully(true);
        onClose();
      })
      .catch((error) => {
        if (
          error.raw?.message ===
          'loyalty_program_already_connected_to_other_account'
        ) {
          setErrors((prev) => ({
            ...prev,
            all: t(
              `We've detected that this account is already in use. To proceed, please connect a different account.`,
            ),
          }));
        } else if (
          error.raw?.message === 'loyalty_program_already_connecting'
        ) {
          setErrors((prev) => ({
            ...prev,
            all: t(
              `There's currently a pending request for this account. Please try connecting a different account.`,
            ),
          }));
        } else if (error.raw?.message === 'loyalty_program_already_connected') {
          setErrors((prev) => ({
            ...prev,
            all: t(
              `We've noticed you have connected with {{programName}} before. Please update your {{programName}} account or connect with a different Loyalty Program.`,
              {
                programName: selectedProgram?.name,
              },
            ),
          }));
        } else if (error.raw?.message === 'loyalty_program_already_matched') {
          setErrors((prev) => ({
            ...prev,
            all: t(
              `There's currently a pending request for this account. Please try connecting a different account.`,
            ),
          }));
        } else toastError({ message: t(COMMON_ERROR_MESSAGE) });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setErrors((prev) => ({
      ...prev,
      email: e.target.value === '' ? t('Email is required') : '',
    }));
    setEmail(e.target.value);
  };

  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setErrors((prev) => ({
      ...prev,
      password: e.target.value === '' ? t('Password is required') : '',
    }));
    setPassword(e.target.value);
  };

  useEffect(() => {
    setErrors({ email: '', password: '', all: '' });
    setEmail('');
    setPassword('');
    setIsShowPassword(false);
  }, [isOpen]);

  if (!selectedProgram) return null;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered={true}
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent
        backgroundColor="#FFFFFF"
        borderRadius="16px"
        padding="32px"
        gap="24px"
        position="relative"
      >
        <ModalHeader
          fontFamily="'Exo 2'"
          fontWeight="700"
          fontSize={24}
          lineHeight="32px"
          padding="0"
          color="#222325"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          {t('Connect with {{programName}}', {
            programName: selectedProgram.name,
          })}
          <Box className={'modal__btn-close'} onClick={onClose}>
            <CloseIcon width={'14px'} />
          </Box>
        </ModalHeader>
        <ModalBody>
          <Flex flexDirection="column" paddingBottom="16px">
            <Text
              fontFamily="Poppins"
              fontWeight="600"
              fontSize={16}
              lineHeight="22px"
              paddingBottom="8px"
            >
              {t('Email or User ID')}
            </Text>
            <Flex flexDirection="column">
              <Input
                value={email}
                onChange={handleChangeEmail}
                placeholder={t('Email or User ID')}
                height="42px"
                borderRadius="16px"
                borderColor={
                  !!errors.email || !!errors.all ? '#FF4C3F' : '#E6E8EC'
                }
                backgroundColor={
                  !!errors.email || !!errors.all ? '#FFEDE8' : '#FFFFFF'
                }
                padding="0 16px"
                fontFamily="Poppins"
                fontWeight="400"
                fontSize={16}
                lineHeight="22px"
                color="#041336"
                _placeholder={{ color: '#A7A9AB' }}
              />
              {!!errors.email && (
                <Flex alignItems="center" gap="4px" marginTop="16px">
                  <IconError />
                  <Text
                    fontFamily="Poppins"
                    fontWeight="500"
                    fontSize={14}
                    lineHeight="20px"
                    color="#FF4C3F"
                  >
                    {errors.email}
                  </Text>
                </Flex>
              )}
            </Flex>
          </Flex>
          <Flex flexDirection="column">
            <Text
              fontFamily="Poppins"
              fontWeight="600"
              fontSize={16}
              lineHeight="22px"
              paddingBottom="8px"
            >
              {t('Password')}
            </Text>
            <Flex position="relative">
              <Input
                value={password}
                onChange={handleChangePassword}
                placeholder={t('Password')}
                type={isShowPassword ? 'text' : 'password'}
                height="42px"
                borderRadius="16px"
                borderColor={
                  !!errors.password || !!errors.all ? '#FF4C3F' : '#E6E8EC'
                }
                backgroundColor={
                  !!errors.password || !!errors.all ? '#FFEDE8' : '#FFFFFF'
                }
                fontFamily="Poppins"
                fontWeight="400"
                fontSize={16}
                lineHeight="22px"
                color="#041336"
                _placeholder={{ color: '#A7A9AB' }}
              />
              <Box
                position="absolute"
                right="16px"
                transform="translateY(-50%)"
                top="50%"
                cursor="pointer"
                onClick={() => setIsShowPassword((prev) => !prev)}
                zIndex={1}
              >
                {isShowPassword ? <IconEyeOpen /> : <IconEyeClose />}
              </Box>
            </Flex>

            {!!errors.password && (
              <Flex alignItems="center" gap="4px" marginTop="16px">
                <IconError />
                <Text
                  fontFamily="Poppins"
                  fontWeight="500"
                  fontSize={14}
                  lineHeight="20px"
                  color="#FF4C3F"
                >
                  {errors.password}
                </Text>
              </Flex>
            )}
          </Flex>
          {!!errors.all && (
            <Flex gap="6px" marginTop="16px">
              <Box minWidth="14px" marginTop="4px">
                <IconError />
              </Box>
              <Text
                fontFamily="Poppins"
                fontWeight="500"
                fontSize={14}
                lineHeight="20px"
                color="#FF4C3F"
              >
                {errors.all}
              </Text>
            </Flex>
          )}
        </ModalBody>
        <ModalFooter padding={0}>
          <AppButton
            height="48px"
            width="100%"
            onClick={handleConnect}
            isDisabled={!email || !password}
            isLoading={isLoading}
          >
            <Text
              textAlign="center"
              fontFamily="'Exo 2'"
              fontWeight="700"
              fontSize={18}
              lineHeight="22px"
              textTransform="uppercase"
            >
              {t('Connect')}
            </Text>
          </AppButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConnectLoyaltyProgram;
