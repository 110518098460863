import { atom } from 'jotai';
import { focusAtom } from 'jotai-optics';
import { getUserInformation } from 'src/api/user';
import User from 'src/utils/types/user';

export interface UserAtomType {
  user?: User.Info;
  coinBalance: number | string;
}

const userInit = {
  user: undefined,
  coinBalance: '',
};
export const initUserAtom = atom<UserAtomType>(userInit);

const _userAtom = focusAtom(initUserAtom, (opt) => opt.prop('user'));
const _coinBalanceAtom = focusAtom(initUserAtom, (opt) =>
  opt.prop('coinBalance'),
);

export const userAtom = atom(
  (get) => get(_userAtom),
  (_, set, user: User.Info) => {
    set(_userAtom, user);
  },
);

export const getUserAtom = atom(null, async (_, set) => {
  const user = await getUserInformation();

  set(userAtom, user);
});

export const coinBalanceAtom = atom(
  (get) => get(_coinBalanceAtom),
  (_, set, balance: number | string) => {
    set(_coinBalanceAtom, balance);
  },
);
