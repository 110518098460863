import { request } from 'src/utils/request';
import { RequestParams } from 'src/components/AppDataTable';
import User from 'src/utils/types/user';
import Code from 'src/utils/types/codes';

export const getUserInformation = async () => {
  const response = await request.get<User.Info>(`/users/user-address`);
  return response.data;
};

export const listRewards = async (params: RequestParams) => {
  const { sort_by, page, limit } = params;
  const response = await request.get<User.ListRewardsResponse>(
    '/users/credit-claimed',
    {
      params: {
        ...params,
        sort_by: sort_by ? sort_by : undefined,
      },
    },
  );
  return {
    page,
    limit,
    list: response.data?.items,
    count: response.data?.meta?.totalItems,
  };
};

export const claimReward = async (code: string) => {
  const response = await request.post<Code.Info>('/users/reward-claim', {
    code,
  });
  return response.data;
};
