import {
  createBrowserRouter,
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import AdminPage from 'src/page/admin';
import AdminInvestors from 'src/page/admin/AdminInvestors';
import AdminTokenomicsPage from 'src/page/admin/AdminTokenomics';
import HomePage from 'src/routers/home';
import AdminLayout from 'src/page/layouts/AdminLayout';
import NotFoundPage from 'src/page/not-found';
import { Paths } from 'src/utils/common';
import { isAdmin } from 'src/utils/helpers/etc';
import AdminRoute from './admin';
import OnlyStake from './only-stake';
import OnlyUser from './only-user';
import OnlyVesting from './only-vesting';
import OnlyPurchase from './only-purchase';
import AdminStakeManagementPage from 'src/page/admin/AdminStakeManagement';
import AdminDetailStakeManagementPage from 'src/page/admin/AdminDetailStakeManagement';
import VoyPointsPage from './voy-points-page';
import TermAndConditional from './term-and-conditional';
import FQA from './FQA';
import MatchHistories from 'src/page/match-histories';
import UI from 'src/page/ui';
import AdminCodesManagementPage from 'src/page/admin/codes';
import AdminCodesNewPage from 'src/page/admin/codes/New';
import AdminCodesEditPage from 'src/page/admin/codes/Edit';
import AdminCodesDetailPage from 'src/page/admin/codes/Details';
import AdminGiftAwayHistoryPage from 'src/page/admin/AdminGiftAwayHistory';
import SignInPage from 'src/page/sign-in';
import SignUpPage from 'src/page/sign-up';
import Vesting from 'src/page/vesting';
import Conversion from 'src/page/conversion';
import StakingPage from 'src/page/stake';
import { LayoutV2 } from 'src/page/layouts/layout-v2';
import { ClerkProvider } from '@clerk/clerk-react';
import i18n from 'src/i18n';
import { jaJP, enUS } from '@clerk/localizations';
import { useEffect } from 'react';
import { setI18nForMoment } from 'src/utils/utils-i18';

function Clerk() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    setI18nForMoment(pathname);
  }, [pathname]);

  return (
    <ClerkProvider
      routerPush={(to) => navigate(to)}
      routerReplace={(to) => navigate(to, { replace: true })}
      publishableKey={process.env.REACT_APP_CLERK_PUBLISHABLE_KEY!}
      localization={i18n.language === 'ja' ? jaJP : enUS}
    >
      <Outlet />
    </ClerkProvider>
  );
}

/**
 * Main App routes.
 */

const RouterCustom = () => {
  // const token = useAtomValue(accessTokenAtom);

  return createBrowserRouter([
    {
      element: <Clerk />,
      children: [
        {
          path: Paths.HOME,
          element: (
            <LayoutV2>
              <HomePage />
            </LayoutV2>
          ),
        },
        // {
        //   path: Paths.VESTING,
        //   element: <OnlyUser />,
        //   children: [
        //     {
        //       index: true,
        //       element: <OnlyVesting />,
        //     },
        //   ],
        // },
        // {
        //   path: Paths.VESTING,
        //   element: <Vesting />,
        // },
        // {
        //   path: Paths.CONVERSION,
        //   element: <Conversion />,
        // },
        // {
        //   path: Paths.STAKING,
        //   element: <StakingPage />,
        // },
        // {
        //   path: Paths.VOY_POINTS,
        //   element: <VoyPointsPage />,
        // },
        // {
        //   path: Paths.TERM_AND_CONDITIONAL,
        //   element: <TermAndConditional />,
        // },
        // {
        //   path: Paths.FQA,
        //   element: <FQA />,
        // },
        {
          path: Paths.VOY_POINTS,
          element: <AdminRoute />,
          children: [
            {
              index: true,
              element: <VoyPointsPage />,
            },
          ],
        },
        {
          path: Paths.MATCH_HISTORIES,
          element: <AdminRoute />,
          children: [
            {
              index: true,
              element: <MatchHistories />,
            },
          ],
        },
        // {
        //   path: Paths.UI,
        //   element: <UI />,
        // },
        {
          path: Paths.SIGN_IN,
          element: <SignInPage />,
        },
        {
          path: Paths.SIGN_UP,
          element: <SignUpPage />,
        },
        // {
        //   path: Paths.VERIFY_EMAIL,
        //   element: <SignUpPage />,
        // },
        // {
        //   path: Paths.SSO_CALLBACK,
        //   element: <SignInPage />,
        // },
        // {
        //   path: Paths.CONTINUE,
        //   element: <SignInPage />,
        // },
        // {
        //   path: Paths.ADMIN,
        //   element: <AdminPage />,
        // },
        // {
        //   path: Paths.ADMIN_INVESTOR,
        //   element: <AdminRoute />,
        //   children: [
        //     {
        //       index: true,
        //       element: (
        //         <AdminLayout>
        //           <AdminInvestors />
        //         </AdminLayout>
        //       ),
        //     },
        //   ],
        // },
        // {
        //   path: Paths.ADMIN_TOKENOMIC,
        //   element: <AdminRoute />,
        //   children: [
        //     {
        //       index: true,
        //       element: (
        //         <AdminLayout>
        //           <AdminTokenomicsPage />
        //         </AdminLayout>
        //       ),
        //     },
        //   ],
        // },
        // {
        //   path: Paths.ADMIN_STAKE_MANAGEMENT,
        //   element: <AdminRoute />,
        //   children: [
        //     {
        //       index: true,
        //       element: (
        //         <AdminLayout>
        //           <AdminStakeManagementPage />
        //         </AdminLayout>
        //       ),
        //     },
        //   ],
        // },
        // {
        //   path: Paths.ADMIN_STAKE_MANAGEMENT_DETAIL,
        //   element: <AdminRoute />,
        //   children: [
        //     {
        //       index: true,
        //       element: (
        //         <AdminLayout>
        //           <AdminDetailStakeManagementPage />
        //         </AdminLayout>
        //       ),
        //     },
        //   ],
        // },
        {
          path: Paths.ADMIN_CODES_MANAGEMENT,
          element: <AdminRoute />,
          children: [
            {
              index: true,
              element: (
                <AdminLayout>
                  <AdminCodesManagementPage />
                </AdminLayout>
              ),
            },
          ],
        },
        {
          path: Paths.ADMIN_CODES_NEW,
          element: <AdminRoute />,
          children: [
            {
              index: true,
              element: (
                <AdminLayout>
                  <AdminCodesNewPage />
                </AdminLayout>
              ),
            },
          ],
        },
        {
          path: Paths.ADMIN_CODES_EDIT,
          element: (
            <AdminLayout>
              <AdminCodesEditPage />
            </AdminLayout>
          ),
        },
        {
          path: Paths.ADMIN_CODES_DETAIL,
          element: <AdminRoute />,
          children: [
            {
              index: true,
              element: (
                <AdminLayout>
                  <AdminCodesDetailPage />
                </AdminLayout>
              ),
            },
          ],
        },
        {
          path: Paths.ADMIN_GIFT_AWAY_HISTORY,
          element: <AdminRoute />,
          children: [
            {
              index: true,
              element: (
                <AdminLayout>
                  <AdminGiftAwayHistoryPage />
                </AdminLayout>
              ),
            },
          ],
        },
        {
          path: Paths.SIGN_IN,
          element: isAdmin ? (
            <Navigate to={Paths.ADMIN} replace />
          ) : (
            <Navigate to={Paths.VESTING} replace />
          ),
        },
        {
          path: '*',
          element: <NotFoundPage />,
        },
      ],
    },
  ]);
};

export default RouterCustom;
