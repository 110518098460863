import { request } from 'src/utils/request';

export const getUserZealy = async () => {
  const response = await request.get<{
    point?: string;
  }>(`/user-zealy`);
  return response.data;
};

export const postUserZealy = async () => {
  const response = await request.post<true>(`/user-zealy`);
  return response.data;
};
