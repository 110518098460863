import {
  Box,
  Flex,
  GridItem,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useAtomValue } from 'jotai';
import _ from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { listRewards } from 'src/api/user';
import { IconArrowDownward } from 'src/assets/icons';
import { AppText } from 'src/components';
import AppDataTableNew, { RequestParams } from 'src/components/AppDataTableNew';
import { accessTokenAtom } from 'src/store/auth';
import { TYPE_CODE } from 'src/utils/common';
import { getLinkPromoCode } from 'src/utils/helpers/etc';
import User from 'src/utils/types/user';
import { formattedNumberWithDecimal } from 'src/utils/utils-format';

const LIMIT = 10;

interface TableRewardsProps {
  params: RequestParams;
  setParams: React.Dispatch<React.SetStateAction<RequestParams>>;
}

const TableRewards: React.FC<TableRewardsProps> = ({ params, setParams }) => {
  const { t } = useTranslation();
  const [hasData, setHasData] = useState<boolean>(false);
  const accessToken = useAtomValue(accessTokenAtom);

  const fetchListRewards: any = useCallback(async (params: any) => {
    try {
      return await listRewards(params).then((res) => {
        setHasData(res.list.length > 0);
        return res;
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (accessToken) fetchListRewards(params);
  }, [params, accessToken, fetchListRewards]);

  const _renderHeader = () => {
    return (
      <Thead>
        <Tr>
          {[
            { title: t('Voy Rewards'), sort: false },
            { title: t('Travel Credits'), sort: false },
            {
              title: t('Claimed On'),
              sort: true,
              onclick: () =>
                setParams((prev) => ({
                  ...prev,
                  orderBy: prev.orderBy === 'ASC' ? 'DESC' : 'ASC',
                })),
            },
          ].map((i) => (
            <Th
              key={i.title}
              width={'33%'}
              textTransform="none"
              padding="8px 24px"
              borderBottomColor="Text Secondary"
            >
              <Flex alignItems="center">
                <AppText
                  color="Text Secondary"
                  variant={'Text/Small/Normal'}
                  textAlign="left"
                >
                  {i.title}
                </AppText>
                {i.sort && (
                  <Box
                    onClick={() => i.onclick?.()}
                    sx={{
                      transform:
                        params.orderBy === 'DESC'
                          ? 'rotate(0)'
                          : 'rotate(180deg)',
                      cursor: 'pointer',
                    }}
                  >
                    <IconArrowDownward />
                  </Box>
                )}
              </Flex>
            </Th>
          ))}
        </Tr>
      </Thead>
    );
  };

  const _renderBody = (data?: User.Reward[]) => {
    return (
      <Tbody>
        {_.map(data, (item: User.Reward, index) => {
          const qrLink = `${item.infoDetail.redirectUrl}?promoCode=${item.infoDetail.code}`;
          return (
            <Tr
              padding={0}
              key={index}
              color="white"
              borderBottom={'1px solid'}
              borderBottomColor="Text Secondary"
            >
              <Td
                padding="8px 24px"
                border="none"
                whiteSpace="wrap"
                wordBreak="break-all"
              >
                <AppText variant={'Text/Regular/Semi Bold'}>
                  {item.infoDetail.type === TYPE_CODE.PROMO_CODE ? (
                    item.infoDetail.code
                  ) : item.infoDetail.type === TYPE_CODE.QR_CODE ? (
                    <a href={getLinkPromoCode(qrLink)}>
                      {`${qrLink?.slice(0, 3)}...${qrLink?.slice(
                        qrLink?.length - 10,
                      )}`}
                    </a>
                  ) : item.type === TYPE_CODE.HISTORY ? (
                    t('Connect Wallet')
                  ) : item.type === TYPE_CODE.ZEALY_POINT ? (
                    t('Zealy Quest')
                  ) : null}
                </AppText>
              </Td>
              <Td padding="8px 24px" border="none">
                <AppText
                  color={'Text Secondary'}
                  variant={'Text/Regular/Normal'}
                >
                  ${formattedNumberWithDecimal(item.amount, 2)}
                </AppText>
              </Td>
              <Td padding="8px 24px" border="none" whiteSpace="nowrap">
                <AppText
                  color={'Text Secondary'}
                  variant={'Text/Regular/Normal'}
                >
                  {item.createdAt
                    ? moment(item.createdAt).format('MMM DD, YYYY')
                    : '-'}
                </AppText>
              </Td>
            </Tr>
          );
        })}
      </Tbody>
    );
  };

  const _renderLoading = () => {
    return (
      <Tbody>
        <Tr>
          <Td padding={0} borderBottom={0} colSpan={3}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minH: '300px',
              }}
            >
              <AppText>{t('Loading...')}</AppText>
            </Box>
          </Td>
        </Tr>
      </Tbody>
    );
  };

  return (
    <GridItem area={'table'}>
      <Box
        sx={{
          background: 'Background color/tertiary',
          p: '24px',
          borderRadius: '15px',
        }}
      >
        <AppText
          variant={'Heading/Mobile/H4'}
          textTransform={'uppercase'}
          mb="32px"
        >
          {t('Voy Rewards Claimed')}
        </AppText>
        {hasData ? (
          <AppDataTableNew
            requestParams={params}
            fetchData={fetchListRewards}
            renderBody={_renderBody}
            renderLoading={_renderLoading}
            renderHeader={_renderHeader}
            limit={LIMIT}
          />
        ) : (
          <AppText variant="Text/Small/Normal" color="Text Secondary">
            {t('No travel credits have been claimed yet.')}
          </AppText>
        )}
      </Box>
    </GridItem>
  );
};

export default TableRewards;
