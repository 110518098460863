import { Box, Flex, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { listCodes } from 'src/api/admin-codes';
import { AppButton, AppInput, AppSelect } from 'src/components';
import AppDataTable, { RequestParams } from 'src/components/AppDataTable';
import { CODE_STATE, CODE_STATUS, Paths, TYPE_CODE } from 'src/utils/common';
import Code from 'src/utils/types/codes';
import { formattedNumberWithDecimal } from 'src/utils/utils-format';

import 'src/styles/pages/AdminTokenomics.scss';
import { getLinkPromoCode } from 'src/utils/helpers/etc';

const LIMIT = 10;

type Option = {
  value: string;
  label: string;
};

const AdminCodesManagementPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const listStatus: Option[] = [
    {
      label: t('All'),
      value: 'all',
    },
    {
      label: t('Valid'),
      value: CODE_STATUS.AVAILABLE.toString(),
    },
    {
      label: t('Expired'),
      value: CODE_STATUS.EXPIRED.toString(),
    },
    {
      label: t('Unavailable'),
      value: CODE_STATUS.UN_AVAILABLE.toString(),
    },
  ];
  const listState: Option[] = [
    {
      label: t('All'),
      value: 'all',
    },
    {
      label: t('Enabled'),
      value: CODE_STATE.ENABLE.toString(),
    },
    {
      label: t('Disabled'),
      value: CODE_STATE.DISABLE.toString(),
    },
  ];

  const [params, setParams] = useState<RequestParams>({
    page: 1,
    limit: LIMIT,
    search: '',
    status: '',
    state: '',
  });

  const now = moment();

  const fetchListCodes: any = useCallback(async (params: any) => {
    try {
      return await listCodes(params);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const _renderHeader = () => {
    return (
      <Thead>
        <Tr>
          <Th
            textTransform="none"
            color="#7C7E82"
            fontFamily="Poppins"
            fontWeight="400"
            fontSize="14px"
            lineHeight="20px"
            padding="18px 24px"
            borderBottomColor="#D7DADC"
          >
            {t('Code Name')}
          </Th>
          <Th
            textTransform="none"
            color="#7C7E82"
            fontFamily="Poppins"
            fontWeight="400"
            fontSize="14px"
            lineHeight="20px"
            padding="18px 24px"
            borderBottomColor="#D7DADC"
          >
            <Text textAlign="left">{t('Code')}</Text>
          </Th>
          <Th
            textTransform="none"
            color="#7C7E82"
            fontFamily="Poppins"
            fontWeight="400"
            fontSize="14px"
            lineHeight="20px"
            padding="18px 24px"
            borderBottomColor="#D7DADC"
          >
            <Text textAlign="left">{t('Give Away Amount')}</Text>
          </Th>
          <Th
            textTransform="none"
            color="#7C7E82"
            fontFamily="Poppins"
            fontWeight="400"
            fontSize="14px"
            lineHeight="20px"
            padding="18px 24px"
            borderBottomColor="#D7DADC"
          >
            <Text textAlign="left">{t('Start Date')}</Text>
          </Th>
          <Th
            textTransform="none"
            color="#7C7E82"
            fontFamily="Poppins"
            fontWeight="400"
            fontSize="14px"
            lineHeight="20px"
            padding="18px 24px"
            borderBottomColor="#D7DADC"
          >
            <Text textAlign="left">{t('End Date')}</Text>
          </Th>
          <Th
            textTransform="none"
            color="#7C7E82"
            fontFamily="Poppins"
            fontWeight="400"
            fontSize="14px"
            lineHeight="20px"
            padding="18px 24px"
            borderBottomColor="#D7DADC"
          >
            <Text textAlign="left">{t('Status')}</Text>
          </Th>
          <Th
            textTransform="none"
            color="#7C7E82"
            fontFamily="Poppins"
            fontWeight="400"
            fontSize="14px"
            lineHeight="20px"
            padding="18px 24px"
            borderBottomColor="#D7DADC"
          >
            <Text textAlign="left">{t('State')}</Text>
          </Th>
          <Th
            textTransform="none"
            color="#7C7E82"
            fontFamily="Poppins"
            fontWeight="400"
            fontSize="14px"
            lineHeight="20px"
            padding="18px 24px"
            borderBottomColor="#D7DADC"
          >
            <Text textAlign="left"></Text>
          </Th>
        </Tr>
      </Thead>
    );
  };

  const _renderLoading = () => {
    return (
      <Tbody>
        <Tr>
          <Td padding={0} borderBottom={0} colSpan={8}>
            <Flex className="loading-container">
              <Text>{t('Loading...')}</Text>
            </Flex>
          </Td>
        </Tr>
      </Tbody>
    );
  };

  const _renderBody = (data?: Code.Info[]) => {
    return (
      <Tbody>
        {_.map(data, (item: Code.Info) => {
          const startTime = moment(item.startTime);
          const endTime = moment(item.endTime);
          const isValid =
            startTime.valueOf() <= now.valueOf() &&
            now.valueOf() <= endTime.valueOf();
          const isExpired = now.valueOf() > endTime.valueOf();
          console.log(item);

          return (
            <Tr
              padding={0}
              borderBottom={0}
              key={item.id}
              fontWeight="400!important"
              lineHeight="22px!important"
              color="#041336!important"
              gap="28px"
            >
              <Td
                padding="18px 24px"
                border="none"
                whiteSpace="wrap"
                wordBreak="break-all"
              >
                {item.name}
              </Td>
              <Td padding="18px 24px" border="none">
                {item.type === TYPE_CODE.PROMO_CODE ? (
                  item.code
                ) : (
                  <a href={getLinkPromoCode(item.code)}>
                    <Text color="#3182FF!important">{`${item.code?.slice(
                      0,
                      3,
                    )}...${item.code?.slice(item.code?.length - 10)}`}</Text>
                  </a>
                )}
              </Td>
              <Td padding="18px 24px" border="none">
                {formattedNumberWithDecimal(item.amount, 18)} {t('Points')}
              </Td>
              <Td padding="18px 24px" border="none" whiteSpace="nowrap">
                {item.startTime
                  ? moment(item.startTime).format('MMM DD, YYYY')
                  : '-'}
              </Td>
              <Td padding="18px 24px" border="none" whiteSpace="nowrap">
                {item.endTime
                  ? moment(item.endTime).format('MMM DD, YYYY')
                  : '-'}
              </Td>
              <Td padding="18px 24px" border="none">
                <Text
                  color={isExpired ? '#FF4C3F!important' : '#041336!important'}
                >
                  {isValid
                    ? t('Valid')
                    : isExpired
                    ? t('Expired')
                    : t('Unavailable')}
                </Text>
              </Td>
              <Td padding="18px 24px" border="none">
                <Text>{item.status ? t('Disabled') : t('Enabled')}</Text>
              </Td>
              <Td padding="18px 24px" border="none">
                <Link to={`${Paths.ADMIN_CODES_DETAIL}?id=${item.id}`}>
                  <Text color="#3182FF!important">{t('View')}</Text>
                </Link>
              </Td>
            </Tr>
          );
        })}
      </Tbody>
    );
  };

  return (
    <Box className="admin-tokenomics-page">
      <Flex justify="space-between" alignItems="center" marginBottom="20px">
        <Text className="title">{t('Code Management')}</Text>
        <AppButton
          onClick={() => navigate(Paths.ADMIN_CODES_NEW)}
          textTransform="uppercase"
        >
          {t('Create')}
        </AppButton>
      </Flex>
      <Flex justify="space-between" alignItems="center" marginBottom="20px">
        <Flex>
          <AppInput
            variant="search"
            placeholder={t('Search')}
            className="input-search"
            isSearch
            onChange={(e) => setParams({ ...params, search: e.target.value })}
          />
        </Flex>
        <Flex
          __css={{
            display: 'flex',
            fontFamily: 'Poppins!important',
            gap: '20px',
            '.selected p': {
              color: '#FFFFFF!important',
            },
            '.app-select__menu-item:hover p': {
              color: '#FFFFFF!important',
            },
            '.app-select__menu-item:hover, .app-select__menu-item.selected': {
              background: '#3182FF!important',
            },
            p: { color: '#222325!important', _hover: { color: '#FFFFFF' } },
          }}
        >
          <Flex alignItems="center" gap="10px">
            <Text color="#222325!important" fontWeight={500}>
              {t('Status')}
            </Text>
            <AppSelect
              value={params.status?.toString() || 'all'}
              size="medium"
              onChange={(value) => setParams({ ...params, status: value })}
              options={listStatus}
            />
          </Flex>
          <Flex alignItems="center" gap="10px">
            <Text color="#222325!important" fontWeight={500}>
              {t('State')}
            </Text>
            <AppSelect
              value={params.state || 'all'}
              size="medium"
              onChange={(value) => setParams({ ...params, state: value })}
              options={listState}
            />
          </Flex>
        </Flex>
      </Flex>

      <Flex className="table">
        <AppDataTable
          requestParams={params}
          fetchData={fetchListCodes}
          renderBody={_renderBody}
          renderLoading={_renderLoading}
          renderHeader={_renderHeader}
          limit={LIMIT}
        />
      </Flex>
    </Box>
  );
};
export default AdminCodesManagementPage;
