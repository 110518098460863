import React, { useCallback, useEffect, useState } from 'react';
import BasePage from '../layouts/BasePage';
import { Box, Grid, Image } from '@chakra-ui/react';
import VestingBalance from '../vesting/VestingBalance';
import AddLoyaltyPrograms from './AddLoyaltyPrograms';
import LoyaltyProgramTable, { PER_PAGE } from './loyalty-program-table';
import { fetchLoyaltyBalances } from 'src/api/voy-wallet';
import AwardWallet from 'src/utils/types/award-wallet';
import { useSocketEventFn } from 'src/hooks/socket';
import { SocketEvent } from 'src/utils/constants/socket-type';
import { toastError, toastSuccess } from 'src/utils/utils-notify';

import 'src/styles/pages/VoyPointsPage.scss';
import { useTranslation } from 'react-i18next';

const VoyPoints: React.FC = () => {
  const [loyaltyBalances, setLoyaltyBalances] = useState<
    AwardWallet.ProgramBalance[]
  >([]);
  const [loyaltyPrograms, setLoyaltyPrograms] = useState<AwardWallet.Program[]>(
    [],
  );
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  // const [connectedProgram, setConnectedProgram] =
  //   useState<AwardWallet.Program>();

  const getLoyaltyBalances = useCallback(() => {
    setIsLoading(true);
    fetchLoyaltyBalances(page, PER_PAGE)
      .then((res) => {
        setLoyaltyBalances(res.list);
        setCount(res.count);
      })
      .finally(() => setIsLoading(false));
  }, [page]);

  useEffect(() => {
    getLoyaltyBalances();
  }, [getLoyaltyBalances]);

  useSocketEventFn(
    SocketEvent.LOYALTY_CONNECT_SUCCESS,
    (msg: AwardWallet.ConnectMSG) => {
      // setConnectedProgram(() =>
      //   loyaltyPrograms.find((lp) => lp.id === msg.loyaltyProgramId),
      // );
      // setIsOpenLoginSuccessfully(true);
      getLoyaltyBalances();
      const program = loyaltyPrograms.find(
        (lp) => lp.id === msg.loyaltyProgramId,
      );
      toastSuccess({
        message: t('{{programName}} connected successfully!', {
          programName: program?.name,
        }),
      });
    },
  );

  useSocketEventFn(
    SocketEvent.LOYALTY_TIMEOUT_ERROR,
    (msg: AwardWallet.ConnectMSG) => {
      const program = loyaltyPrograms.find(
        (lp) => lp.id === msg.loyaltyProgramId,
      );
      getLoyaltyBalances();
      toastError({
        message: t('Unable to connect to {{programName}}!', {
          programName: program?.name,
        }),
      });
    },
  );

  useSocketEventFn(
    SocketEvent.LOYALTY_UNKNOWN_ERROR,
    (msg: AwardWallet.ConnectMSG) => {
      const program = loyaltyPrograms.find(
        (lp) => lp.id === msg.loyaltyProgramId,
      );
      getLoyaltyBalances();
      toastError({
        message: t('Unable to connect to {{programName}}!', {
          programName: program?.name,
        }),
      });
    },
  );

  useSocketEventFn(
    SocketEvent.LOYALTY_EMAIL_OR_PASSWORD_WRONG,
    (msg: AwardWallet.ConnectMSG) => {
      const program = loyaltyPrograms.find(
        (lp) => lp.id === msg.loyaltyProgramId,
      );
      getLoyaltyBalances();
      toastError({
        message: t('Unable to connect to {{programName}}!', {
          programName: program?.name,
        }),
      });
    },
  );

  useSocketEventFn(
    SocketEvent.LOYALTY_PROVIDER_DISABLED,
    (msg: AwardWallet.ConnectMSG) => {
      const program = loyaltyPrograms.find(
        (lp) => lp.id === msg.loyaltyProgramId,
      );
      getLoyaltyBalances();
      toastError({
        message: t('Unable to connect to {{programName}}!', {
          programName: program?.name,
        }),
      });
    },
  );

  useSocketEventFn(
    SocketEvent.LOYALTY_LOCKED_DUE_TO_INVALID_LOGIN_ATTEMPS,
    (msg: AwardWallet.ConnectMSG) => {
      const program = loyaltyPrograms.find(
        (lp) => lp.id === msg.loyaltyProgramId,
      );
      getLoyaltyBalances();
      toastError({
        message: t('Unable to connect to {{programName}}!', {
          programName: program?.name,
        }),
      });
    },
  );

  useSocketEventFn(
    SocketEvent.LOYALTY_REQUIRE_ADDITIONAL_USER_ACTION,
    (msg: AwardWallet.ConnectMSG) => {
      const program = loyaltyPrograms.find(
        (lp) => lp.id === msg.loyaltyProgramId,
      );
      getLoyaltyBalances();
      toastError({
        message: t('Unable to connect to {{programName}}!', {
          programName: program?.name,
        }),
      });
    },
  );

  useSocketEventFn(
    SocketEvent.LOYALTY_EMAIL_OR_PASSWORD_WRONG,
    (msg: AwardWallet.ConnectMSG) => {
      getLoyaltyBalances();
      const program = loyaltyPrograms.find(
        (lp) => lp.id === msg.loyaltyProgramId,
      );
      toastError({
        message: t('Unable to connect to {{programName}}!', {
          programName: program?.name,
        }),
      });
    },
  );

  useSocketEventFn(
    SocketEvent.LOYALTY_START_UPDATING,
    (msg: AwardWallet.ConnectMSG) => {
      getLoyaltyBalances();
    },
  );

  useSocketEventFn(
    SocketEvent.LOYALTY_UPDATE_SUCCESS,
    (msg: AwardWallet.ConnectMSG) => {
      getLoyaltyBalances();
    },
  );

  useSocketEventFn(
    SocketEvent.LOYALTY_UPDATE_FALURE,
    (msg: AwardWallet.ConnectMSG) => {
      getLoyaltyBalances();
    },
  );

  return (
    <BasePage>
      <Box
        maxWidth="1280px"
        width="100vw"
        padding="60px 20px 36px 20px"
        className="voy-points-page"
      >
        <Grid
          templateColumns="448px 1fr"
          width="100%"
          gap="30px"
          marginBottom="30px"
        >
          <VestingBalance />
          <Image
            src="/images/merchandising-content.png"
            alt=""
            width="100%"
            height="100%"
            objectFit="cover"
            borderRadius="16px"
          />
        </Grid>
        <AddLoyaltyPrograms
          loyaltyPrograms={loyaltyPrograms}
          setLoyaltyPrograms={setLoyaltyPrograms}
          getLoyaltyBalances={getLoyaltyBalances}
        />
        <LoyaltyProgramTable
          getLoyaltyBalances={getLoyaltyBalances}
          loyaltyBalances={loyaltyBalances}
          loyaltyPrograms={loyaltyPrograms}
          count={count}
          page={page}
          setPage={setPage}
          isLoading={isLoading}
        />
        {/* <LoginAW /> */}
      </Box>
    </BasePage>
  );
};

export default VoyPoints;
