import {
  Box,
  Button,
  GridItem,
  HStack,
  Input,
  Link,
  Spinner,
  StackDivider,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { claimReward } from 'src/api/user';
import { IconNext2 } from 'src/assets/icons';
import { AppButton, AppText } from 'src/components';
import { RequestParams } from 'src/components/AppDataTableNew';
import AppTooltip from 'src/components/AppTooltip';
import usePointsBalance from 'src/hooks/usePointsBalance';
import i18n from 'src/i18n';
import Code from 'src/utils/types/codes';
import { formattedNumber, number2KMBT } from 'src/utils/utils-format';
import { ModalClaimedSuccess } from './ModalClaimedSuccess';

interface YourTravelCreditsProps {
  setParams: React.Dispatch<React.SetStateAction<RequestParams>>;
}

const YourTravelCredits: React.FC<YourTravelCreditsProps> = ({ setParams }) => {
  const { t } = useTranslation();
  const [code, setCode] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const [message, setMessage] = useState<Code.Message[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { balancePoint, getUser } = usePointsBalance();

  const handleClaimReward = () => {
    claimReward(code)
      .then((res) => {
        const message = res.messageSuccess;
        setMessage(message);
        // Re-fresh
        setCode('');
        setParams((prev) => ({ ...prev }));
        getUser();
        onOpen();
      })
      .catch((err) => {
        if (err?.raw?.message === 'code_claimed') {
          setErrorCode('Promo Code Already Redeemed');
        } else setErrorCode('Invalid Promo Code');
      });
  };

  return (
    <GridItem area={'nav'}>
      <ModalClaimedSuccess
        message={
          message?.find(
            (lpm) => lpm.lang === (i18n.language === 'en' ? 'en' : 'jp'),
          )?.message
        }
        onClose={onClose}
        isOpen={isOpen}
      />
      <VStack
        divider={<StackDivider borderColor="Mid-Dark Blue" />}
        spacing={'32px'}
        align="stretch"
        sx={{
          background: 'Background color/tertiary',
          p: ['28px', '24px'],
          borderRadius: '15px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* 1 */}
        <Box>
          <AppText
            mb="32px"
            variant={'Heading/Mobile/H4'}
            textTransform={'uppercase'}
          >
            {t('Your Travel Credits')}
          </AppText>
          {balancePoint === undefined ? (
            <Spinner height="46px" width="46px" />
          ) : (
            <Box mb="4px">
              <AppTooltip
                variant={'chartreuse'}
                label={formattedNumber(balancePoint, 2)}
              >
                <AppText
                  variant={'Text/Large/Semi Bold'}
                  textTransform={'uppercase'}
                  color="Color Brand/Chartreuse"
                >
                  ${number2KMBT(balancePoint)}
                </AppText>
              </AppTooltip>
            </Box>
          )}
          <AppText variant={'Text/Regular/Semi Bold'} color="Text Secondary">
            {t('Travel Credits Claimed')}
          </AppText>
        </Box>
        {/* 2 */}
        <Box>
          <AppText mb="16px" variant={'Text/Medium/Bold'}>
            {t('Got a Promo Code?')}
          </AppText>
          <HStack spacing={'16px'} justifyContent={'space-between'}>
            <Input
              sx={{
                fontFamily: 'Poppins',
                p: '12px 0',
                borderRadius: '0',
                borderWidth: '0!important',
                borderBottomWidth: '1px!important',
                borderColor: 'white!important',
                boxShadow: 'none!important',
                _placeholder: {
                  color: 'Color Neutral/neutral lighter',
                },
                w: '100%',
                h: '48px',
              }}
              placeholder={t('Enter Promo Code here')}
              maxLength={8}
              value={code}
              onChange={(e) => {
                setCode(e.target.value);
                setErrorCode('');
              }}
            />
            <AppButton
              sx={{
                minW: '48px',
                minH: '48px',
                p: '16px',
              }}
              variant={'chartreuseL'}
              onClick={handleClaimReward}
            >
              <IconNext2 />
            </AppButton>
          </HStack>
          {errorCode && (
            <AppText mt="8px" variant={'Text/Small/Normal'} color="#B42318">
              {t(errorCode)}
            </AppText>
          )}
        </Box>
        {/* 3 */}
        <Box>
          <AppText mb="16px" variant={'Text/Medium/Bold'}>
            <Trans values={{ amount: '$250' }}>
              Complete Quests to Earn up to
              <AppText
                as={'b'}
                sx={{
                  color: 'Color Brand/Chartreuse',
                }}
              >
                {'{{amount}} in Travel Credits'}
              </AppText>
            </Trans>
          </AppText>
          <Link
            href="https://zealy.io/cw/voytravelfi/questboard"
            target="_blank"
          >
            <Button
              w="100%"
              h={['42px', 'auto']}
              variant="chartreuse"
              textTransform={'uppercase'}
            >
              {t('Earn More Credits')}
            </Button>
          </Link>
        </Box>
      </VStack>
    </GridItem>
  );
};

export default YourTravelCredits;
