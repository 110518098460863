import { Flex, Spinner, Text, Tooltip } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconCoin, IconPoint } from 'src/assets/icons';
import useCoinBalance from 'src/hooks/useCoinBalance';
import usePointsBalance from 'src/hooks/usePointsBalance';
import { formattedNumberWithDecimal } from 'src/utils/utils-format';

const VestingBalance: React.FC = () => {
  const { balanceCoin, isLoading } = useCoinBalance();
  const { balancePoint } = usePointsBalance();
  const { t } = useTranslation();

  const textBalanceCoin = useMemo(
    () => formattedNumberWithDecimal(balanceCoin, 6),
    [balanceCoin],
  );

  const textBalancePoint = useMemo(
    () => formattedNumberWithDecimal(balancePoint, 6),
    [balancePoint],
  );

  return (
    <Flex
      overflow="hidden"
      borderRadius="16px"
      position="relative"
      width="100%"
      backgroundImage="/images/vesting-balance-bg.png"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
    >
      <Flex flexDirection="column" padding="20px 24px">
        <Flex
          flexDirection="column"
          marginBottom="24px"
          position="relative"
          zIndex={1}
        >
          <Flex alignItems="center" gap={1.5} paddingBottom="4px">
            <IconCoin width="24px" height="24px" />
            <Text
              fontFamily="Poppins"
              fontWeight={500}
              fontSize={14}
              lineHeight="20px"
            >
              {t('My VOY Coin Balance')}
            </Text>
          </Flex>
          <Flex alignItems="flex-end">
            {isLoading ? (
              <Spinner height="30px" width="30px" mr={2} />
            ) : (
              <React.Fragment>
                <Tooltip label={formattedNumberWithDecimal(balanceCoin, 18)}>
                  <Text
                    fontFamily="'Exo 2'"
                    fontWeight={700}
                    fontSize={24}
                    lineHeight="30px"
                    color="#222325"
                  >
                    {textBalanceCoin.length > 20
                      ? `${textBalanceCoin.substring(0, 20)}...`
                      : textBalanceCoin}
                  </Text>
                </Tooltip>
              </React.Fragment>
            )}
            <Text
              fontFamily="Poppins"
              fontWeight={400}
              fontSize={16}
              lineHeight="22px"
              marginLeft="6px"
            >
              {t('Coins')}
            </Text>
          </Flex>
        </Flex>
        <Flex flexDirection="column" position="relative" zIndex={1}>
          <Flex alignItems="center" gap={1.5} marginBottom="4px">
            <IconPoint width="30px" height="24px" />
            <Text
              fontFamily="Poppins"
              fontWeight={500}
              fontSize={14}
              lineHeight="20px"
            >
              {t('My Travel Credits Balance')}
            </Text>
          </Flex>
          <Flex alignItems="flex-end">
            <Text
              fontFamily="'Exo 2'"
              fontWeight={700}
              fontSize={24}
              lineHeight="30px"
              color="#222325"
              display="flex"
              gap="2px"
            >
              $
              {balancePoint !== undefined ? (
                <Tooltip label={formattedNumberWithDecimal(balancePoint, 18)}>
                  {textBalancePoint.length > 20
                    ? `${textBalancePoint.substring(0, 20)}...`
                    : textBalancePoint}
                </Tooltip>
              ) : (
                <Spinner height="30px" width="30px" />
              )}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default VestingBalance;
