import { useAtomValue } from 'jotai';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { accessTokenAtom, roleAccountAtom } from 'src/store/auth';
import { Paths, ROLE } from 'src/utils/common';

const AdminRoute: React.FC = () => {
  const roleAccount = useAtomValue(roleAccountAtom);
  const token = useAtomValue(accessTokenAtom);
  const isNotAdmin = roleAccount !== ROLE.ADMIN;

  return token ? (
    isNotAdmin ? (
      <Navigate to={Paths.HOME} replace />
    ) : (
      <Outlet />
    )
  ) : (
    <Navigate to={Paths.LOGIN} replace />
  );
};

export default AdminRoute;
