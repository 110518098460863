import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalProps,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { AppText } from 'src/components';
import hexToRgba from 'hex-to-rgba';
import { IconError2 } from 'src/assets/icons';

export const ModalClaimedInvalid = ({
  message,
  ...props
}: { message?: string } & Omit<ModalProps, 'children'>) => {
  const { t } = useTranslation();

  return (
    <Modal isCentered {...props}>
      <ModalOverlay bg={hexToRgba('#020B20', 0.6)} />
      <ModalContent
        sx={{
          background: 'Color Brand/Cetacean Blue',
          minW: ['100%', '560px'],
          maxW: ['100%', '560px'],
          minH: '264px',
          borderRadius: '20px',
        }}
      >
        <ModalCloseButton color={'white'} sx={{ _focusWithin: 'none' }} />
        <ModalBody
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            gap: '20px',
            p: '32px 20px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '140px',
            }}
          >
            <IconError2 />
          </Box>
          <AppText color="white" variant="Text/Large/Bold">
            {message}
          </AppText>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
