import {
  Box,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import {
  SignOutButton,
  SignUpButton,
  useClerk,
  UserProfile,
  useUser,
} from '@clerk/clerk-react';
import { getAddress } from 'ethers';
import { useAtomValue, useSetAtom } from 'jotai';
import _ from 'lodash';
import React, { FC, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Jazzicon from 'react-jazzicon';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ArrowDown,
  IconArrowRight,
  IconLogout,
  VoyLogo,
  VoyLogoAdmin,
} from 'src/assets/icons';
import { AppButton, AppLink } from 'src/components';
import { languages } from 'src/i18n';
import { ProviderContext } from 'src/WalletProvider';
import {
  addressAtom,
  logoutAtom,
  msgErrorAtom,
  permissionAtom,
  roleAccountAtom,
} from 'src/store/auth';
import 'src/styles/layout/Header.scss';
import { Paths, PERMISSION_USER, ROLE } from 'src/utils/common';
import { formatAddress } from 'src/utils/utils-format';
import { generateAvatarFromId } from 'src/utils/utils-helper';
import { useAccount, useDisconnect } from 'wagmi';

interface IHeader {
  isAdmin?: boolean;
  isChainSupported: boolean;
  chainId: number;
}

interface HeaderItem {
  name: string;
  path: string;
  subMenu?: HeaderItem[];
  isHidden?: boolean;
}

const menus: HeaderItem[] = [
  // {
  //   name: 'VOY Wallet',
  //   path: '#',
  //   subMenu: [
  //     {
  //       name: 'My Wallet',
  //       path: Paths.HOME,
  //     },
  //     {
  //       name: 'My VOY Coin',
  //       path: '#',
  //       subMenu: [
  //         {
  //           name: 'Vesting',
  //           path: Paths.VESTING,
  //         },
  //         {
  //           name: 'Staking',
  //           path: Paths.STAKING,
  //         },
  //         {
  //           name: 'Exchange',
  //           path: Paths.CONVERSION,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'My VOY Points',
  //       path: Paths.VOY_POINTS,
  //     },
  //     {
  //       name: 'Match Histories',
  //       path: Paths.MATCH_HISTORIES,
  //       isHidden: true,
  //     },
  //   ],
  // },
  // {
  //   name: 'Bridge',
  //   path: Paths.BRIDGE,
  // },
  {
    name: 'Home',
    path: Paths.HOME,
  },
];

const adminMenu = [
  {
    name: 'Admin',
    path: '#',
    subMenu: [
      // {
      //   name: 'Investors',
      //   path: Paths.ADMIN_INVESTOR,
      // },
      // {
      //   name: 'Tokenomics',
      //   path: Paths.ADMIN_TOKENOMIC,
      // },
      // {
      //   name: 'Stake Management',
      //   path: Paths.ADMIN_STAKE_MANAGEMENT,
      // },
      {
        name: 'Give Away History',
        path: Paths.ADMIN_GIFT_AWAY_HISTORY,
      },
      {
        name: 'Code Management',
        path: Paths.ADMIN_CODES_MANAGEMENT,
      },
    ],
  },
];

const Header: FC<IHeader> = ({ isAdmin, isChainSupported, chainId }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { address } = useAccount();
  const { t, i18n } = useTranslation();
  const user = useUser();
  const { signOut } = useClerk();
  const { disconnectAsync } = useDisconnect();

  const networkDefault = process.env.REACT_APP_NETWORK_DEFAULT || '';

  const msgError = useSetAtom(msgErrorAtom);
  const account = useAtomValue(addressAtom);
  const doLogout = useSetAtom(logoutAtom);
  const role = useAtomValue(roleAccountAtom);
  const { metamaskProvider: provider } = useContext(ProviderContext);
  const permissions = useAtomValue(permissionAtom);

  const isOnlyStake =
    permissions === PERMISSION_USER.ONLY_STAKE && !(role === ROLE.ADMIN);
  const isOnlyVesting =
    permissions === PERMISSION_USER.ONLY_VESTING && !(role === ROLE.ADMIN);

  const navBar = role === ROLE.ADMIN ? _.concat(menus, ...adminMenu) : menus;

  const disconnectListener = async () => {
    doLogout();
    // msgError(false);
    // navigate(Paths.LOGIN);
  };

  const onSwitchNetwork = async () => {
    return await provider?.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: networkDefault }],
    });
  };

  useEffect(() => {
    // const isConnected = async () => {
    //   const address = await provider?.request({ method: 'eth_accounts' });
    //   if (!_.size(address)) {
    //     disconnectListener();
    //   }
    //   const acc = getAddress(_.first(address) || '');
    //   if (acc && account && acc !== account) {
    //     disconnectListener();
    //   }
    // };
    // isConnected();
  }, [account]);

  const isActiveMenu = (item: HeaderItem) => {
    return pathsOfItem(item).some((path) => location.pathname === path);
  };

  const pathsOfItem = (menuItem: HeaderItem): string[] => {
    const paths = [menuItem.path];
    if (!menuItem.subMenu) return paths;
    const subPaths = menuItem.subMenu
      .map((item: HeaderItem) => {
        return pathsOfItem(item);
      })
      .flat();

    return [...paths, ...subPaths];
  };

  const _renderMenu = (menus: HeaderItem[], isSubMenu?: boolean) => {
    return (
      <Flex className={isSubMenu ? '' : 'menu'}>
        {_.map(menus, (item, index) => {
          const { path, name, subMenu } = item;

          if (item.isHidden) return null;
          if (isOnlyStake && path === Paths.VESTING) return null;
          if (
            isOnlyVesting &&
            (path === Paths.STAKING || path === Paths.CONVERSION)
          )
            return null;

          return !!subMenu ? (
            <Box
              key={index}
              className={`menu-item ${isActiveMenu(item) ? 'active' : ''}`}
              cursor="pointer"
            >
              <Text whiteSpace="nowrap">{t(name)}</Text>

              {subMenu && (
                <React.Fragment>
                  <IconArrowRight />
                  <Flex className="sub-menu">{_renderMenu(subMenu, true)}</Flex>
                </React.Fragment>
              )}
            </Box>
          ) : (
            <AppLink
              to={path}
              key={index}
              className={`menu-item ${isActiveMenu(item) ? 'active' : ''}`}
            >
              <Text whiteSpace="nowrap">{t(name)}</Text>
            </AppLink>
          );
        })}
      </Flex>
    );
  };

  const changeLanguage = (lng: keyof typeof languages) => {
    i18n.changeLanguage(lng);
  };

  const _renderBtnLanguage = () => {
    return (
      <Flex className="menu" marginLeft="45px">
        <Box className="menu-item" cursor="pointer">
          <Text whiteSpace="nowrap">
            {t(languages[i18n.language as keyof typeof languages])}
          </Text>

          <IconArrowRight />
          <Flex className="sub-menu">
            <Flex>
              {_.map(Object.keys(languages), (key) => {
                const languageName = languages[key as keyof typeof languages];
                const currentLang =
                  languages[i18n.language as keyof typeof languages];
                return (
                  <AppLink
                    to=""
                    key={key}
                    onClick={() =>
                      changeLanguage(key as keyof typeof languages)
                    }
                    className={`menu-item ${
                      currentLang === languageName ? 'active' : ''
                    }`}
                  >
                    <Text whiteSpace="nowrap">{t(languageName)}</Text>
                  </AppLink>
                );
              })}
            </Flex>
          </Flex>
        </Box>
      </Flex>
    );
  };

  const handleLogout = async () => {
    signOut();
    doLogout();
    await disconnectAsync();
    navigate('/sign-in');
  };

  const _renderAvatar = () => {
    return (
      <Box className="menu-avatar">
        <Menu>
          <MenuButton>
            <Flex className="avatar-container">
              <Image
                src="/images/default-avatar.png"
                width="24px"
                height="24px"
                minWidth="24px"
                borderRadius="50%"
              />
              {/* <Flex
                flexDirection={'row'}
                alignItems={'center'}
                className="user-name"
              >
                {formatAddress(address || account)}
                <ArrowDown />
              </Flex> */}
            </Flex>
          </MenuButton>
          <MenuList className="menu-header">
            <MenuItem className="user-info" onClick={handleLogout}>
              <div className="user-logout">
                <span>{t('Log Out')}</span>
                <IconLogout />
              </div>
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>
    );
  };

  const _renderWrongNetwork = () => {
    if (isChainSupported || !chainId) {
      return null;
    }
    return (
      <Flex className="wrong-network">
        {t(
          'The configured network on your wallet does not match with the network of the system.',
        )}{' '}
        <span className="switch-network" onClick={onSwitchNetwork}>
          {t('Click here to switch')}
        </span>
      </Flex>
    );
  };

  return (
    <Flex flexDirection={'column'} className="header-container">
      <Box className={` ${isAdmin ? 'header-admin' : 'header'}`}>
        <Flex className="content-header">
          <Flex justifyContent={'start'}>
            {isAdmin ? (
              <VoyLogoAdmin
                height={32}
                width={70}
                cursor={'pointer'}
                onClick={() => navigate(Paths.HOME)}
              />
            ) : (
              <VoyLogo
                height={32}
                width={70}
                cursor={'pointer'}
                onClick={() => navigate(Paths.HOME)}
              />
            )}
          </Flex>
          <Flex alignItems={'center'}>
            {_renderMenu(navBar)}
            {/* {_renderBtnLanguage()} */}
          </Flex>
          <Box>{_renderAvatar()}</Box>
        </Flex>
      </Box>
      {_renderWrongNetwork()}
    </Flex>
  );
};

export default Header;
