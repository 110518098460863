import { Box } from '@chakra-ui/react';
import React from 'react';
import { AppText } from 'src/components';

type VoyCardProps = {
  icon: React.ReactNode;
  menuLeft?: React.ReactNode;
} & (
  | {
      title: string;
      description: React.ReactNode;
      footer?: React.ReactNode;
    }
  | {
      body: React.ReactNode;
    }
);

const VoyCard: React.FC<VoyCardProps> = ({ icon, menuLeft, ...props }) => {
  return (
    <Box
      sx={{
        background: 'Background color/tertiary',
        p: ['28px', '24px'],
        borderRadius: '15px',
        display: 'flex',
        flexDirection: 'column',
        gap: ['20px', '16px'],
        minH: '281px',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            w: ['48px', '32px'],
            h: ['48px', '32px'],
            color: 'Deep Blue',
            background: 'Color Brand/Chartreuse',
            borderRadius: '100px',

            svg: {
              fill: 'Deep Blue',
            },
            path: {
              fill: 'Deep Blue',
            },
          }}
        >
          {icon}
        </Box>
        {menuLeft}
      </Box>
      {'body' in props ? (
        props.body
      ) : (
        <>
          <AppText
            sx={{
              fontSize: ['24px', '16px'],
              lineHeight: '24px',
              fontWeight: 600,
              color: 'Color Brand/Chartreuse',
            }}
            textTransform={'uppercase'}
          >
            {props.title}
          </AppText>
          <AppText
            sx={{
              fontFamily: 'Poppins',
              fontSize: ['16px', '14px'],
              lineHeight: '21px',
              fontWeight: 400,
              flexGrow: 1,
            }}
          >
            {props.description}
          </AppText>
          {props.footer}
        </>
      )}
    </Box>
  );
};

export default VoyCard;
