import { Flex, Spinner, Text, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconPoint } from 'src/assets/icons';
import usePointsBalance from 'src/hooks/usePointsBalance';
import { formattedNumberWithDecimal } from 'src/utils/utils-format';

interface InputToProps {
  value: string;
  isOver100k: boolean;
}

const InputTo: React.FC<InputToProps> = ({ value, isOver100k }) => {
  const { balancePoint } = usePointsBalance();
  const { t } = useTranslation();

  return (
    <Flex flexDirection="column" width="100%">
      <Flex
        borderRadius="15px"
        padding="18px 16px 18px 16px"
        backgroundColor="#F4F4F4"
        width="100%"
      >
        <Flex
          gap="10px"
          width="100%"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Flex gap="8px" flexDirection="column" justifyContent="flex-start">
            <Text
              fontFamily="Poppins"
              fontWeight={500}
              fontSize={18}
              lineHeight="24px"
              color="#7C7E82"
              width="fit-content"
            >
              {t('To')}
            </Text>
            <Tooltip label={formattedNumberWithDecimal(value || 0, 18)}>
              <Text
                fontFamily="'Exo 2'"
                fontWeight={500}
                fontSize={32}
                lineHeight="40px"
                color="#041336"
                maxWidth="248px"
                className="text-ellipsis"
              >
                {formattedNumberWithDecimal(value || 0, 6)}
              </Text>
            </Tooltip>
          </Flex>
          <Flex flexDirection="column" alignItems="flex-end">
            <Flex
              justifyContent="flex-end"
              borderRadius="22px"
              backgroundColor="#FFFFFF"
              padding="3px 6px 3px 8px"
              width="fit-content"
              marginBottom="6px"
              gap="6px"
            >
              <IconPoint />
              <Text
                fontFamily="Poppins"
                fontWeight={500}
                fontSize={14}
                lineHeight="20px"
                color="#041336"
              >
                {t('Travel Credits')}
              </Text>
            </Flex>
            <Flex>
              <Flex
                fontFamily="Poppins"
                fontWeight={400}
                fontSize={14}
                lineHeight="20px"
                color="#7C7E82"
                marginRight="4px"
                whiteSpace="break-spaces"
              >
                <Text display="contents">
                  {t('Balance')}
                  {': '}
                  <Text
                    fontFamily="Poppins"
                    fontWeight={500}
                    fontSize={14}
                    lineHeight="20px"
                    color="#222325"
                  >
                    $
                  </Text>
                </Text>
                {balancePoint === undefined ? (
                  <Spinner width="20px" height="20px" />
                ) : (
                  <Tooltip label={formattedNumberWithDecimal(balancePoint, 18)}>
                    <Text maxWidth="70px" className="text-ellipsis">
                      {formattedNumberWithDecimal(balancePoint, 6)}
                    </Text>
                  </Tooltip>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex>
        {isOver100k && (
          <Flex
            fontFamily="Poppins"
            fontWeight={500}
            fontSize={12}
            lineHeight="16px"
            color="#FF4C3F"
            marginTop="8px"
          >
            {t('You can only match for 100,000 Voy Points')}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default InputTo;
