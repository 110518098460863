import { useEffect } from 'react';
import { useStableFn } from '../use-stable-fn';
import { useSocket } from './use-socket';
import { SocketEvent } from 'src/utils/constants/socket-type';

export function useSocketEventFn(
  forEvent: SocketEvent,
  callback: (...arg: any[]) => any,
  enabled = true,
) {
  const { registerListener } = useSocket();

  const handler = useStableFn(callback);

  useEffect(() => {
    if (!enabled) return;
    const cleanup = registerListener(forEvent, handler);

    return () => {
      cleanup && cleanup();
    };
  }, [registerListener, forEvent, enabled, handler]);
}
