import { Flex } from '@chakra-ui/react';
import { SignIn } from '@clerk/clerk-react';
import { useTermsAndPrivacy } from 'src/hooks/use-term';

import 'src/styles/pages/SignIn.scss';

const SignInPage: React.FC = () => {
  useTermsAndPrivacy({});

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      bgColor="#020B20"
      className="container container-sign-up"
    >
      <SignIn
        path="/sign-in"
        signUpUrl="/sign-up"
        appearance={{
          variables: {
            colorPrimary: '#dbf616',
            colorText: '#FFFFFF',
            colorBackground: '#041336',
            fontFamily: 'Poppins',
            borderRadius: '8px',
          },
          elements: {
            card: 'custom-card',
            socialButtons: 'custom-social-buttons',
            input: 'custom-input',
            footer: 'custom-footer',
            footerActionLink: 'custom-link',
            formButtonPrimary: 'custom-form-button',
            formFieldInput: {
              autoComplete: 'off',
            },
            formFieldInputPassword: {
              autoComplete: 'new-password',
            },
          },
        }}
      />
    </Flex>
  );
};
export default SignInPage;
