import { Box, Flex, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import _ from 'lodash';
import moment from 'moment';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { listGiftAwayHistory } from 'src/api/admin-gift-away';
import { AppInput } from 'src/components';
import AppDataTable, { RequestParams } from 'src/components/AppDataTable';
import GiftAway from 'src/utils/types/gift-away';
import { formattedNumberWithDecimal } from 'src/utils/utils-format';

import 'src/styles/pages/AdminTokenomics.scss';
import { TYPE_CODE } from 'src/utils/common';
import { getLinkPromoCode } from 'src/utils/helpers/etc';

const LIMIT = 10;

const AdminGiftAwayHistoryPage: React.FC = () => {
  const { t } = useTranslation();

  const [params, setParams] = useState<RequestParams>({
    page: 1,
    limit: LIMIT,
    search: '',
  });

  const fetchListCodes: any = useCallback(async (params: any) => {
    try {
      return await listGiftAwayHistory(params);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const _renderHeader = () => {
    return (
      <Thead>
        <Tr>
          <Th
            textTransform="none"
            color="#7C7E82"
            fontFamily="Poppins"
            fontWeight="400"
            fontSize="14px"
            lineHeight="20px"
            padding="18px 24px"
            borderBottomColor="#D7DADC"
          >
            <Text textAlign="left">{t('Email')}</Text>
          </Th>
          <Th
            textTransform="none"
            color="#7C7E82"
            fontFamily="Poppins"
            fontWeight="400"
            fontSize="14px"
            lineHeight="20px"
            padding="18px 24px"
            borderBottomColor="#D7DADC"
          >
            <Text textAlign="left">{t('Code Name')}</Text>
          </Th>
          <Th
            textTransform="none"
            color="#7C7E82"
            fontFamily="Poppins"
            fontWeight="400"
            fontSize="14px"
            lineHeight="20px"
            padding="18px 24px"
            borderBottomColor="#D7DADC"
          >
            <Text textAlign="left">{t('Code')}</Text>
          </Th>
          <Th
            textTransform="none"
            color="#7C7E82"
            fontFamily="Poppins"
            fontWeight="400"
            fontSize="14px"
            lineHeight="20px"
            padding="18px 24px"
            borderBottomColor="#D7DADC"
          >
            <Text textAlign="left">{t('Redeem Amount')}</Text>
          </Th>
          <Th
            textTransform="none"
            color="#7C7E82"
            fontFamily="Poppins"
            fontWeight="400"
            fontSize="14px"
            lineHeight="20px"
            padding="18px 24px"
            borderBottomColor="#D7DADC"
          >
            <Text textAlign="left">{t('Redeem Date')}</Text>
          </Th>
        </Tr>
      </Thead>
    );
  };

  const _renderLoading = () => {
    return (
      <Tbody>
        <Tr>
          <Td padding={0} borderBottom={0} colSpan={5}>
            <Flex className="loading-container">
              <Text>{t('Loading...')}</Text>
            </Flex>
          </Td>
        </Tr>
      </Tbody>
    );
  };

  const _renderBody = (data?: GiftAway.Info[]) => {
    return (
      <Tbody>
        {_.map(data, (item: GiftAway.Info) => {
          const qrLink = `${item.infoDetail.redirectUrl}?promoCode=${item.infoDetail.code}`;
          return (
            <Tr
              padding={0}
              borderBottom={0}
              key={item.id}
              fontWeight="400!important"
              lineHeight="22px!important"
              color="#041336!important"
              gap="28px"
            >
              <Td padding="18px 24px" border="none">
                {item.email}
              </Td>
              <Td padding="18px 24px" border="none" whiteSpace="nowrap">
                {item.infoDetail.name}
              </Td>
              <Td padding="18px 24px" border="none" whiteSpace="nowrap">
                {item.infoDetail.type === TYPE_CODE.PROMO_CODE ? (
                  item.infoDetail.code
                ) : (
                  <a href={getLinkPromoCode(qrLink)}>
                    <Text color="#3182FF!important">{`${qrLink?.slice(
                      0,
                      3,
                    )}...${qrLink?.slice(qrLink?.length - 10)}`}</Text>
                  </a>
                )}
              </Td>
              <Td padding="18px 24px" border="none" whiteSpace="nowrap">
                {formattedNumberWithDecimal(item.infoDetail.amount, 18)}
              </Td>
              <Td padding="18px 24px" border="none">
                <Text>{moment(item.createdAt).format('MMM DD, YYYY')}</Text>
              </Td>
            </Tr>
          );
        })}
      </Tbody>
    );
  };

  return (
    <Box className="admin-tokenomics-page">
      <Flex justify="space-between" alignItems="center" marginBottom="20px">
        <Text className="title">{t('Give Away History')}</Text>
      </Flex>
      <Flex justify="space-between" alignItems="center" marginBottom="20px">
        <Flex>
          <AppInput
            variant="search"
            placeholder={t('Search by Email')}
            className="input-search"
            isSearch
            onChange={(e) => setParams({ ...params, search: e.target.value })}
          />
        </Flex>
      </Flex>

      <Flex className="table">
        <AppDataTable
          requestParams={params}
          fetchData={fetchListCodes}
          renderBody={_renderBody}
          renderLoading={_renderLoading}
          renderHeader={_renderHeader}
          limit={LIMIT}
        />
      </Flex>
    </Box>
  );
};
export default AdminGiftAwayHistoryPage;
